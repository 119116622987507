import User from '@sharedModels/user/user';
import ActionMenu from '@sharedModels/action-menu';
import { Injectable } from '@angular/core';
import { AuthService } from '@authServices/auth.service';
import { Observable } from 'rxjs';
import { LogService } from '../log/log.service';
import { map, catchError } from 'rxjs/operators';
import { TimeReportApiClient } from '@shared/clients/timereport-api-client';
import { of } from 'rxjs';
import { GlobalCacheService } from '../cache/global-cache.service';
import { TimePeriodPipe } from '@sharedPipes/time-period.pipe';

export interface IUserService {
  getUser(): User;
  getUserActionMenu(): Observable<ActionMenu | {}>;
  setAnotherCountryKey(value: string): void;
  getAnotherCountryKey(): string;

}

@Injectable({
  providedIn: 'root'
})
export class UserService implements IUserService {
  public anotherCountryKey: string;
  constructor(
    private authService: AuthService,
    private logService: LogService,
    private timeReportApi: TimeReportApiClient,
    private globalCacheService: GlobalCacheService,
  ) { }

  public getUser(): User {
    return new User(this.authService.getUserProfile());
  }

  public getUserActionMenu(): Observable<ActionMenu> {
    return this.timeReportApi
      .getUserActionMenu(this.getUser().enterpriseId, this.globalCacheService.getPeriodEnd() ?? new Date((new TimePeriodPipe).transform(new Date())), undefined, undefined)
      .pipe(
        map(res => {
          const actionInfo = new ActionMenu();
          actionInfo.record = res.record;
          actionInfo.review = res.review;
          actionInfo.approve = res.approve;
          actionInfo.report = res.report;
          actionInfo.represent = res.represent;
          actionInfo.audit = res.audit;
          actionInfo.authorize = res.authorize;
          actionInfo.massPPA = res.massPPA;
          actionInfo.admin = res.admin;
          actionInfo.userRole = res.userRole;
          actionInfo.request = res.request;
          actionInfo.sdl = res.sdl;
          actionInfo.consentReviewer = res.consentReviewer;
          actionInfo.isAllowanceApprover = res.isAllowanceApprover;
          actionInfo.isOvertimeApprover = res.isOvertimeApprover;
          actionInfo.isAmexSender = res.isAmexToolUser;
          actionInfo.isAuditPilotUser = res.isAuditPilotUser;
          actionInfo.isAuthorizePilotUser = res.isAuthorizePilotUser;
          actionInfo.isMassPPAPilotUser = res.isMassPPAPilotUser;
          actionInfo.isOnCallAndSWTimeApprover = res.isOnCallAndSWTimeApprover;
          actionInfo.isAllowanceApproverForIN = res.isAllowanceApproverForIN;
          actionInfo.userCountry = res.userCountry;
          actionInfo.isReportPilotUser = res.isReportPilotUser;
          actionInfo.isHolidayHoursRequestor = res.isHolidayHoursRequestor;
          actionInfo.isHolidayHoursApprover = res.isHolidayHoursApprover;
          actionInfo.isHolidayHoursEmployee = res.isHolidayHoursEmployee;
          actionInfo.isGlobalApprover = res.isGlobalApprover

          return actionInfo;
        })
      )
      .pipe(
        catchError(err => {
          this.logService.logError(err, false, "check tab permission fail");
          return of(null);
        })
      );
  }

  public setAnotherCountryKey(value: string): void {
    this.anotherCountryKey = value;
  }

  public getAnotherCountryKey(): string {
    return this.anotherCountryKey;
  }
}
