import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { PeoplePicker } from '../../../../shared/models/controls/people-picker/people-picker';
import { PeoplePickerService } from '../../../shared/services/people-picker/people-picker.service';
import { GlobalCacheService } from '../../../../shared/services/cache/global-cache.service';
import { SubordinatesMode } from '../../../../shared/models/subordinates/subordinate-mode';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

@Component({
  selector: 'myte-people-picker',
  templateUrl: './people-picker.component.html',
  styleUrls: ['./people-picker.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeoplePickerComponent implements OnInit {
  @ViewChild('typeaheadInstance')  private typeaheadInstance: NgbTypeahead;
  model: PeoplePicker;
  searching = false;
  searchFailed = false;
  isMultipleSelection = false;

  placement: PlacementArray = ['bottom-left', 'bottom-right', 'top-left', 'top-right'];
  @Input() timeCategoryCode: string = '';
  @Input() isSimplify = false;
  @Input() forSubordinates = false;
  @Input() forApprovee = false;
  @Input() forRequestors = false;
  @Input() forOvertimeApprover = false;
  @Input() forAllowanceApprover = false;
  @Input() forTimeCategoryRequestApprover = false;
  @Input() subordinateMode: SubordinatesMode;
  @Input() public readOnly: boolean;
  @Input() public required: boolean = false;
  @Input() public isAttendees: boolean = false;
  @Input() placementPreferences: PlacementArray;
  @Input() public specialApprover: string = '';  
  @Output() selectedPeopleEvent: EventEmitter<PeoplePicker> = new EventEmitter<PeoplePicker>();
  @Output() selectedMultipleEvent: EventEmitter<string> = new EventEmitter<string>();
  

  constructor(
      private peoplePickerService: PeoplePickerService,
      private globalCacheService: GlobalCacheService,
      private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.placementPreferences) {
      this.placement = this.placementPreferences;
    }
  }

  formatter = (x: PeoplePicker) => x.enterpriseId;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap(term => {
        let userList = [];
        this.changeDetector.markForCheck();
        this.isMultipleSelection = false;
        this.searchFailed = false;
        if (term.length < 3) return of([]);
        if (term.includes(';')) {
          this.isMultipleSelection = true;
          return of([]);
        }
        if (this.forSubordinates) {
          const isDelegate = this.globalCacheService.getSubordinateMode() === SubordinatesMode.Delegate;
          const isSubordinatesMode = this.subordinateMode != undefined || this.subordinateMode != null;
          if (isDelegate && isSubordinatesMode && (this.subordinateMode === SubordinatesMode.AllowanceApprover || this.subordinateMode === SubordinatesMode.OvertimeApprover || this.subordinateMode === SubordinatesMode.TimeCategoryRequestApprover || this.subordinateMode === SubordinatesMode.WorkShiftApprover)) {
            return this.peoplePickerService.getSuggestionsForDelegateSubordinates(term, this.subordinateMode, this.globalCacheService.getCurrentSubordinate().enterpriseId, this.timeCategoryCode).pipe(
              tap((results) => {
                userList =results.map(p => p.enterpriseId);
                if(this.isSimplify){
                  return results;
                }else{
                  return this.peoplePickerService.getProfilePictureList(userList)
                  .subscribe(profilePicture => {
                    results.forEach( r => {
                      r.profilePicture = profilePicture.find( p => r.enterpriseId == p.enterpriseId);
                      this.changeDetector.markForCheck();
                    })
                    this.searchFailed = false;
                  })
                }
              }),
              catchError(() => {
                this.changeDetector.markForCheck();
                this.searchFailed = true;
                return of([]);
              })
            );
          }
          else if(this.forOvertimeApprover||this.forAllowanceApprover || this.forTimeCategoryRequestApprover){
            return this.peoplePickerService.getSuggestionsForApprover(term,this.subordinateMode, this.timeCategoryCode).pipe(
              tap((results) => {
                userList =results.map(p => p.enterpriseId);
                if(this.isSimplify){
                  return results;
                }else{
                  return this.peoplePickerService.getProfilePictureList(userList)
                  .subscribe(profilePicture => {
                    results.forEach( r => {
                      r.profilePicture = profilePicture.find( p => r.enterpriseId == p.enterpriseId);
                      this.changeDetector.markForCheck();
                    })
                    this.searchFailed = false;
                  })
                }
              }),
              catchError(() => {
                this.changeDetector.markForCheck();
                this.searchFailed = true;
                return of([]);
              })
            );
          }  else {
            if (this.subordinateMode != undefined || this.subordinateMode != null) {
              this.globalCacheService.setSubordinateMode(this.subordinateMode);
            }
            return this.peoplePickerService.getSuggestionsForSubordinates(term).pipe(
              tap((results) => {
                userList =results.map(p => p.enterpriseId);
                if(this.isSimplify){
                  return results;
                }else{
                  return this.peoplePickerService.getProfilePictureList(userList)
                  .subscribe(profilePicture => {
                    results.forEach( r => {
                      r.profilePicture = profilePicture.find( p => r.enterpriseId == p.enterpriseId);
                      this.changeDetector.markForCheck();
                    })
                    this.searchFailed = false;
                  })
                }
              }),
              catchError(() => {
                this.changeDetector.markForCheck();
                this.searchFailed = true;
                return of([]);
              })
            );
          }
        }
        else if (this.forApprovee) {
          let isExpenseViewMode = this.subordinateMode === SubordinatesMode.AssignedExpenseApprover; 
          if ((this.subordinateMode != undefined || this.subordinateMode != null) && !isExpenseViewMode) {
            this.globalCacheService.setSubordinateMode(this.subordinateMode);
          }
          let expenseViewMode = isExpenseViewMode ? this.subordinateMode : null;
          return this.peoplePickerService.getSuggestionsForApprovee(term, expenseViewMode, this.specialApprover).pipe(
            tap((results) => {
              userList =results.map(p => p.enterpriseId);
              if(this.isSimplify){
                return results;
              }else{
                return this.peoplePickerService.getProfilePictureList(userList)
                .subscribe(profilePicture => {
                  results.forEach( r => {
                    r.profilePicture = profilePicture.find( p => r.enterpriseId == p.enterpriseId);
                    this.changeDetector.markForCheck();
                  })
                  this.searchFailed = false;
                })
              }
            }),
            catchError(() => {
              this.changeDetector.markForCheck();
              this.searchFailed = true;
              return of([]);
            })
          );
        }
        else if(this.forRequestors){
          if (this.subordinateMode != undefined || this.subordinateMode != null) {
            this.globalCacheService.setSubordinateMode(this.subordinateMode);
          }
          return this.peoplePickerService.getSuggestionsForSubordinates(term).pipe(
            tap((results) => {
              userList =results.map(p => p.enterpriseId);
              if(this.isSimplify){
                return results;
              }else{
                return this.peoplePickerService.getProfilePictureList(userList)
                .subscribe(profilePicture => {
                  results.forEach( r => {
                    r.profilePicture = profilePicture.find( p => r.enterpriseId == p.enterpriseId);
                    this.changeDetector.markForCheck();
                  })
                  this.searchFailed = false;
                })
              }
            }),
            catchError(() => {
              this.changeDetector.markForCheck();
              this.searchFailed = true;
              return of([]);
            })
          );
        } 
        else {
          return this.peoplePickerService.getSuggestions(term).pipe(
            tap((results) => {
              userList =results.map(p => p.enterpriseId);
              if(this.isSimplify){
                return results;
              }else{
                return this.peoplePickerService.getProfilePictureList(userList)
                .subscribe(profilePicture => {
                  results.forEach( r => {
                    r.profilePicture = profilePicture.find( p => r.enterpriseId == p.enterpriseId);
                    this.changeDetector.markForCheck();
                  })
                  this.searchFailed = false;
                })
              }
            }),
            catchError(() => {
              this.changeDetector.markForCheck();
              this.searchFailed = true;
              return of([]);
            })
          );
        }
      }),
      tap(() => (this.searching = false))
    )

  typeaheadKeydown($event: KeyboardEvent) {
    if ($event.key !== 'Escape') {
      if (this.typeaheadInstance.isPopupOpen()) {
        setTimeout(() => {
          const popup = document.getElementById(this.typeaheadInstance.popupId);
          const activeElements = popup.getElementsByClassName('active');
          if (activeElements.length === 1) {
            const elem = (activeElements[0] as any);
            if (typeof elem.scrollIntoViewIfNeeded === 'function') {
              elem.scrollIntoViewIfNeeded();
            } else {
              this.scrollIntoViewIfNeededPolyfill(elem as HTMLElement);
            }
          }
        });
      }
    }
  }

  private scrollIntoViewIfNeededPolyfill(elem: HTMLElement, centerIfNeeded = true) {
    var parent = elem.parentElement,
        parentComputedStyle = window.getComputedStyle(parent, null),
        parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width')),
        parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width')),
        overTop = elem.offsetTop - parent.offsetTop < parent.scrollTop,
        overBottom = (elem.offsetTop - parent.offsetTop + elem.clientHeight - parentBorderTopWidth) > (parent.scrollTop + parent.clientHeight),
        overLeft = elem.offsetLeft - parent.offsetLeft < parent.scrollLeft,
        overRight = (elem.offsetLeft - parent.offsetLeft + elem.clientWidth - parentBorderLeftWidth) > (parent.scrollLeft + parent.clientWidth),
        alignWithTop = overTop && !overBottom;

    if ((overTop || overBottom) && centerIfNeeded) {
      parent.scrollTop = elem.offsetTop - parent.offsetTop - parent.clientHeight / 2 - parentBorderTopWidth + elem.clientHeight / 2;
    }

    if ((overLeft || overRight) && centerIfNeeded) {
      parent.scrollLeft = elem.offsetLeft - parent.offsetLeft - parent.clientWidth / 2 - parentBorderLeftWidth + elem.clientWidth / 2;
    }

    if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
      elem.scrollIntoView(alignWithTop);
    }
  };

  selectPeople = (event, input) => {
    this.selectedPeopleEvent.emit(event.item as PeoplePicker);
    setTimeout(() => input.value = '', 200);
  }

  selectMultiple = (input) => {
    this.selectedMultipleEvent.emit(input.value);
    this.isMultipleSelection = false;
    input.value = '';
  }
}
