import { Component, Input, ViewChild } from '@angular/core';
import { DateformatPipe } from '@shared/pipes/dateformat.pipe';
import { dateFilterExceptions, FormatDateType } from '@shared/utils/constants';
import { IFilterAngularComp } from 'ag-grid-angular';
import { GridApi, IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { DropdownComponent } from '../../../myte-controls/components/dropdown/dropdown.component';
import { DatePickerComponent } from '../../../myte-controls/components/date-picker/date-picker.component';
import DropdownItem from '../../../shared/models/controls/dropdown/dropdown-item';

@Component({
    selector: 'myte-dateFilter',
    templateUrl: './dateFilter.component.html',
    styleUrls: ['./dateFilter.component.sass']
})
export class DateFilterComponent implements IFilterAngularComp {
    public selectedOption: any;
    public dropdownOptions: DropdownItem[] = [];
    public date: Date;
    public isInExceptions = dateFilterExceptions.includes(navigator.language);
    private params: IFilterParams;
    private filterChangedCallback: () => void;
    @Input() gridApi: GridApi;
    @ViewChild('startDate', { static: true }) text: string | DatePickerComponent;
    @ViewChild('filterOptions', { static: true }) filterOptions: DropdownComponent;

    public agInit(params: IFilterParams): void {
        this.dropdownOptions = [
            new DropdownItem('Equals', 'Equals'),
            new DropdownItem('GreaterThan', 'Greater or equal than'),
            new DropdownItem('LessThan', 'Less or equal than'),
            new DropdownItem('NotEqual', 'Not equal')
        ];
        this.params = params;
        this.filterChangedCallback = params.filterChangedCallback;
        this.filterOptions.selectedItem = new DropdownItem('Equals', 'Equals');
    }

    public isFilterActive(): boolean {
        return this.text !== null && this.text !== undefined && this.text !== '';
    }

    public doesFilterPass(params?: IDoesFilterPassParams): boolean {
        // For some reason, sometimes text is of type DatePickerComponent and should be string type
        this.text = this.text instanceof DatePickerComponent && this.text?.placeholder ? this.text.value ? new DateformatPipe().transform(this.text.value.toLocaleString(), FormatDateType.GetStandardDateForFilter) : undefined : this.text;
        return this.compare(params);
    }

    public compare(params: IDoesFilterPassParams): boolean {
        const { node } = params;
        const dateField = node.data ? node.data[this.params.colDef.field] : null;
        if (this.isInExceptions) {
            var date = new DateformatPipe().transform(new Date(this.text as string), FormatDateType.GetStandardDateForFilter)
            var nodeDate = dateField ? new DateformatPipe().transform(dateField, FormatDateType.GetStandardDateForFilter) : null
        }
        else {
            date = new DateformatPipe().transform(new Date(this.text as string).toLocaleString(), FormatDateType.GetStandardDateForFilter)
            var nodeDate = dateField ? new DateformatPipe().transform(dateField.toLocaleString(), FormatDateType.GetStandardDateForFilter) : null
        }

        switch (this.selectedOption?.key) {
            case 'Equals':
                return date === nodeDate;
            case 'GreaterThan':
                return date <= nodeDate;
            case 'LessThan':
                return date >= nodeDate;
            case 'NotEqual':
                return date !== nodeDate;
            default:
                return date === nodeDate;
        }
    }

    public getModel(): any {
        return { value: this.text };
    }

    public setModel(model: any): void {
        this.text = model ? model.value : '';
    }

    public ngAfterViewInit(params: IAfterGuiAttachedParams): void {
    }

    public onChange(newValue): void {
        if (this.text !== newValue) {
            this.text = newValue;
            this.date = new Date(Date.parse(newValue));
            this.params.filterChangedCallback();
        }
    }

    public onDateSelectedFromDropdown($event): void {
        this.date = null;
        this.text = null;
        this.selectedOption = $event;
        this.dropdownOptions.find(option => {
            if (option.key === $event) {
                this.selectedOption = option;
            }
        })
    }

    public clearFilter(): void {
        this.selectedOption = null;
        this.date = null;
        this.text = null;
        this.filterOptions.selectedItem = new DropdownItem('Equals', 'Equals');
        this.filterChangedCallback();
    }
}
