import { Injectable } from '@angular/core';
import { ExpenseMultipleTaxiGrid } from '@shared/models/expense/multipleTaxi/expense-multiple-grid';
import ExpenseMultipleInfo from '@shared/models/expense/multipleTaxi/expense-multiple-info';
import { DateformatPipe } from '@shared/pipes/dateformat.pipe';
import { FormatDateType } from '@shared/utils/constants';
import { ColDef, GridOptions, SuppressHeaderKeyboardEventParams, SuppressKeyboardEventParams, ValueGetterParams, ValueSetterParams } from 'ag-grid-community';
import { TaxiAmountEditorComponent } from '../../../components/myte-expense-popups/expense-taxi-editor/taxi-amount-editor/taxi-amount-editor.component';
import { ExpenseGridHeaderComponent } from '../../../../myte-expenses/components/myte-expense-grid/expense-grid-header/expense-grid-header.component';
import { ExpenseGridOverlayLoadingComponent } from '../../../../myte-expenses/components/myte-expense-grid/expense-grid-overlay-loading/expense-grid-overlay-loading.component';
import { TaxiAssignmentEditorComponent } from '../../../../myte-expenses/components/myte-expense-popups/expense-taxi-editor/taxi-assignment-editor/taxi-assignment-editor.component';
import { TaxiReasonEditorComponent } from '../../../../myte-expenses/components/myte-expense-popups/expense-taxi-editor/taxi-reason-editor/taxi-reason-editor.component';
import { TaxiAmountRenderComponent } from '../../../../myte-expenses/components/myte-expense-popups/expense-taxi-render/taxi-amount-render/taxi-amount-render.component';
import { TaxiAssignmentRenderComponent } from '../../../../myte-expenses/components/myte-expense-popups/expense-taxi-render/taxi-assignment-render/taxi-assignment-render.component';
import { TaxiDateRenderComponent } from '../../../../myte-expenses/components/myte-expense-popups/expense-taxi-render/taxi-date-render/taxi-date-render.component';
import { TaxiReasonRenderComponent } from '../../../../myte-expenses/components/myte-expense-popups/expense-taxi-render/taxi-reason-render/taxi-reason-render.component';

@Injectable({
  providedIn: 'root'
})
export class ExpenseTaxiGridService {
  public expenseAssignmentArray: string[];
  public expenseReasonArray: string[];
  public currentBrowserLanguage: string;
  public currency: string;

  constructor() { }

  public getGridOptions(expenseMultipleInfo: ExpenseMultipleInfo): GridOptions<ExpenseMultipleTaxiGrid> {
    return {
      rowHeight: 40,
      headerHeight: 45,
      domLayout: 'autoHeight',
      ensureDomOrder: true,
      suppressMenuHide: true,
      suppressChangeDetection: true,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      //stopEditingWhenCellsLoseFocus: true,
      suppressAnimationFrame: true,
      defaultColDef: this.getDefaultColumnDefinitions(),
      columnDefs: this.getColumnDefinitions(expenseMultipleInfo),
      components: { agColumnHeader: ExpenseGridHeaderComponent },
      loadingOverlayComponent: ExpenseGridOverlayLoadingComponent,
      loadingOverlayComponentParams: { loadingMessage: 'Loading Travel - Taxi - Local - Multiple Entry' },
    };
  }

  private getDefaultColumnDefinitions(): ColDef<ExpenseMultipleTaxiGrid> {
    return {
      suppressHeaderKeyboardEvent: (event: SuppressHeaderKeyboardEventParams<ExpenseMultipleTaxiGrid>) => true,
      sortable: true,
      resizable: false,
      filter: false,
      suppressMovable: true,
      width: 100,
      minWidth: 100
    };
  }

  private getColumnDefinitions(expenseMultipleInfo: ExpenseMultipleInfo): ColDef<ExpenseMultipleTaxiGrid>[] {
    this.currentBrowserLanguage = navigator.language;
    this.currency = expenseMultipleInfo.currency;
    const dateFormat = new DateformatPipe().transform(null, FormatDateType.GetDateMaskByLanguage);
    const isMac: boolean = /Macintosh/i.test(navigator.userAgent.toLowerCase());
    const columnDefs: ColDef<ExpenseMultipleTaxiGrid>[] = [
      {
        headerName: 'Date(' + dateFormat + ')',
        field: 'dateFormat',
        sortable: false,
        width: isMac ? 400 / window.devicePixelRatio : 200 / window.devicePixelRatio,
        minWidth: isMac ? 400 / window.devicePixelRatio : 200 / window.devicePixelRatio,
        editable: false,
        singleClickEdit: false,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams<ExpenseMultipleTaxiGrid>) => {
          const key = params.event.key;
          return (document.querySelector('ngb-datepicker-month') && key === 'Tab')
        },
        cellRenderer: TaxiDateRenderComponent
      },
      {
        headerName: 'Charge Code',
        cellClass: ['cursor-pointer'],
        sortable: false,
        width: isMac ? 600 / window.devicePixelRatio : 450 / window.devicePixelRatio,
        minWidth: isMac ? 600 / window.devicePixelRatio : 450 / window.devicePixelRatio,
        editable: true,
        singleClickEdit: true,
        cellEditorPopup: true,
        cellEditorPopupPosition: 'under',
        cellRenderer: TaxiAssignmentRenderComponent,
        cellRendererParams: { assignments: expenseMultipleInfo.assignments },
        cellEditor: TaxiAssignmentEditorComponent,
        cellEditorParams: { assignments: expenseMultipleInfo.assignments },
        valueGetter: (params: ValueGetterParams<ExpenseMultipleTaxiGrid>) => {
          return params.data.assignments
        },
        valueSetter: (params: ValueSetterParams<ExpenseMultipleTaxiGrid>) => {
          if (params.newValue == null) return false;
          if (params.newValue == params.oldValue) return false;
          params.data.assignments = params.newValue;
          return true;
        },
      },
      {
        headerName: 'Amount(' + expenseMultipleInfo.currency + ')',
        field: 'amount',
        sortable: true,
        width: isMac ? 360 / window.devicePixelRatio : 250 / window.devicePixelRatio,
        minWidth: isMac ? 360 / window.devicePixelRatio : 250 / window.devicePixelRatio,
        editable: true,
        singleClickEdit: true,
        cellRenderer: TaxiAmountRenderComponent,
        cellEditor: TaxiAmountEditorComponent,
        valueGetter: (params: ValueGetterParams<ExpenseMultipleTaxiGrid>) => params.data.amount,
        valueSetter: (params: ValueSetterParams<ExpenseMultipleTaxiGrid>) => {
          if (params.newValue == null) return false;
          if (params.newValue == params.oldValue) return false;
          params.data.amount = params.newValue;
          return true;
        }
      },
      {
        headerName: 'Reason',
        cellClass: ['cursor-pointer'],
        sortable: false,
        width: isMac ? 400 / window.devicePixelRatio : 260 / window.devicePixelRatio,
        minWidth: isMac ? 400 / window.devicePixelRatio : 260 / window.devicePixelRatio,
        editable: true,
        singleClickEdit: true,
        cellEditorPopup: true,
        cellEditorPopupPosition: 'under',
        cellEditor: TaxiReasonEditorComponent,
        cellEditorParams: { assignments: expenseMultipleInfo.reasons },
        cellRenderer: TaxiReasonRenderComponent,
        cellRendererParams: { assignments: expenseMultipleInfo.reasons },
        valueGetter: (params: ValueGetterParams<ExpenseMultipleTaxiGrid>) => params.data.reasons,
        valueSetter: (params: ValueSetterParams<ExpenseMultipleTaxiGrid>) => {
          if (params.newValue == null) return false;
          if (params.newValue == params.oldValue) return false;
          params.data.reasons = params.newValue;
          return true;
        }
      }
    ]
    return columnDefs;
  }
}