import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OverwriteUniversalHeaderHyperlinksService } from '@shared/services/overwrite-uh-hyperlinks/overwrite-uh-hyperlinks.service';

@Component({
  selector: 'myte-page-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  @Output() onLoadingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public userRole: any;
  constructor(private overwriteUHService: OverwriteUniversalHeaderHyperlinksService) { }

  public ngOnInit(): void {
    const intervalId = setInterval(() => {
      let personElements = document.getElementsByClassName('acn-header-profile');
      if (this.isElementExist(personElements)) {
        this.overwriteUHService.overwriteUH();
        clearInterval(intervalId);
      }
    }, 3000);

    setTimeout(() => {
      clearInterval(intervalId);
    }, 60000);
  }

  private isElementExist(ele) {
    return typeof ele !== 'undefined' &&
      ele &&
      ele.length > 0;
  }
}
