export enum SourceSystem {
  Web = 'Web',
  Mobile = 'Mobile',
  AmexWeb = 'AmexWeb',
  TripDetails = 'TripDetails',
  AmexConsole = 'AmexConsole',
  AmexMobile = 'AmexMobile',
  CopyWeb = 'CopyWeb',
  FrequentTripWeb = 'FrequentTripWeb',
  Uber = 'Uber',
  CopyMobile = 'CopyMobile',
  IQN = 'IQN',
  Octopod = 'Octopod',
  HotelFolioWeb = 'HotelFolioWeb',
  HotelFolioMobile = 'HotelFolioMobile',
  Lyft = 'Lyft',
  Workbook = 'Workbook',
  MyteNext = 'MyteNext',
  PunchClockManual = 'PunchClockManual',
  PunchClockStartStop = 'PunchClockStartStop',
  MyTim = 'MyTim',
  JPBPO = 'JPBPO',
  MobileNext = 'MobileNext',
  AmexMobileNext = 'AmexMobileNext',
  HotelFolioMobileNext = 'HotelFolioMobileNext',
  CopyMobileNext = 'CopyMobileNext',
  PunchClockMobileNext = 'PunchClockMobileNext',
  PMG = 'PMG',
}

export enum AppicationName {
  MyTEWeb = 'MyTEWeb',
  MyTEMobile = 'MyTEMobile',
  MyReceipts = 'MyReceipts',
  MyAprovals = 'MyAprovals',
  TBAEE = 'TBAEE'
}

export enum WebServiceSourceSystem {
  Lamsystems = 'Lamsystems',
  ServiceNow = 'ServiceNow',
  PESH = 'PESH',
  PMTI = 'PMTI',
  Workbook = 'Workbook',
  MSPS = 'MSPS',
}