import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import {
  TimeLegacyApiClientService,
  Timesheet
} from '@sharedClients/time-legacy-api-client';
import { TimeReportApiClient, TimesheetLogInput, ValidationErrorOutPut, SubmissionGroupInput, ValidationGroupErrorsOutput, UserSettingsRequest, UserSettings, SubmitTimeReportOutput, ChargeabilityImpactReviewInfo, ElectronicReceiptValidExtension, EncashmentRequest, EncashmentRequestOutput, TimeOffInLieuHoursAccrualOutput, TimeOffInLieuHoursAccrualInput } from '@sharedClients/timereport-api-client';
import TimeReportSubmitQueue from '@sharedModels/time-report/time-report-submit-queue';
import { LogService } from '../log/log.service';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import TimeReport, { TimeReportStatusModel } from '@sharedModels/time-report/time-report';
import { UserService } from '../user/user.service';
import { GlobalCacheService } from '../cache/global-cache.service';
import { TimeReportLog } from '@sharedModels/time-report/time-report-log';
import { ActionDescription, TimeReportLogAction } from '@sharedModels/time-report/time-report-log-action';
import SaveTimesheetLogAction from '@sharedModels/time-report/time-report-save-actions';
import { ExpenseApiClient, InpatriationOutput } from '@sharedClients/expense-api-client';
import TimeReportSettings from '@sharedModels/time-report/time-report-settings';
import ExpenseServiceMapper from '../../../myte-expenses/shared/services/expense/expense.service.mapper';
import TravelDiarySettings from '@sharedModels/traveldiary-settings';
import { SubmissionInput } from '@sharedClients/timereport-api-client';
import { AppConfigService } from '@authServices/app-config.service';
import { TimeApiClient, TimesheetLogActionOutput, TimesheetLogOutput } from '@sharedClients/time-api-client';
import { SubordinatesMode } from '@sharedModels/subordinates/subordinate-mode';
import { MyteBaseService } from '../myte-base.service';
import { GlobalEventsService } from '../events/global-events.service';
import Subordinate from '@sharedModels/subordinates/subordinate';
import UserEthicMessage from '@sharedModels/user/user-ethic-message';
import DropdownItem from '@sharedModels/controls/dropdown/dropdown-item';
import { LocationsApiClient } from '@sharedClients/locations-api-client';
import { PeriodEndService } from '../periodend/periodend.service';
import { SubordinatesService } from '../subordinates/subordinates.service';
import { ReverseAllPpaResult } from '@sharedModels/adjustments/reversed-periods-adjustments';
import { TimeReportStatusCode } from '@sharedModels/time-report/time-report-status-code';
import { TimeReportUtils } from '@sharedUtils/timeReport.utils';
import { DateformatPipe } from '@shared/pipes/dateformat.pipe';
import { FormatDateType } from '@shared/utils/constants';
import { TimeReportHistory } from '@shared/models/time-report/time-report-history.model';
import { TimeReportHistoryLog, TimeReportHistoryLogEID } from '@shared/models/time-report/time-report-history-log';
import { SourceSystem, WebServiceSourceSystem } from '@shared/models/source-system.model';
import { TimeReportLogInput } from '@shared/models/time-report/time-report-log-input.model';
import moment from 'moment';
import { ESTravelPunchClockInfo } from '@shared/myte-static-message';

@Injectable({
  providedIn: 'root'
})
export class TimeReportService extends MyteBaseService {
  public shouldTriggerPoplicy818Validation: boolean;
  private appConfigData: any;
  public isContinueProcess:boolean;
  constructor(private legacyTimeApi: TimeLegacyApiClientService,
              private timeApi: TimeApiClient,
              private timeReportApi: TimeReportApiClient,
              private expenseApi: ExpenseApiClient,
              private locationsApi: LocationsApiClient,
              private periodEndService: PeriodEndService,
              userService: UserService,
              logService: LogService,
              globalCacheService: GlobalCacheService,
              globalEventsService: GlobalEventsService,
              private subordinatesService: SubordinatesService,
              private appConfigService: AppConfigService) {
    super(userService, globalCacheService, globalEventsService, logService);
    this.appConfigData = this.appConfigService.getAppConfig;
  }

  public refreshCurrentSubordinateList() {
    let periodEnd = this.periodEndService.getActivePeriod();
    let subordinateMode = this.globalCacheService.getSubordinateMode();
    this.globalEventsService.dispatchShowGlobalSpinnerEvent(true);
    this.globalCacheService.resetSubordinatesList();
    this.subordinatesService.getSubordinatesList(subordinateMode, periodEnd, true).subscribe(subordinateList => {
    this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
    this.globalEventsService.dispatchSubmitAllEvent({ subordinates: subordinateList, isReview: true, shouldRefreshSubordinateList: true});
    });

}

  public submitTimeReports(subordinates: Subordinate[], periodEnd:Date): Observable<any>{
    let submitTimeReportRequest;
    if(this.appConfigData.AdditionalConfig.enableSubmission){
      submitTimeReportRequest= forkJoin(
        subordinates.map(sub => this.timeReportApi
          .submitTimeReport(
            this.supervisorEid,
            this.viewMode,
            this.createSubmissionInput(periodEnd, 1, sub.enterpriseId, null))
            .pipe(catchError(() => of(null))))
      )
      .pipe(catchError(() => {
        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
        return of(null)
      }))
      return submitTimeReportRequest;
    } else {
      submitTimeReportRequest= forkJoin(
        subordinates.map(sub => this.legacyTimeApi
          .submitTimeReportNext(sub.enterpriseId, periodEnd, true, this.supervisorEid, this.viewMode == SubordinatesMode.Approver, false)
          .pipe(catchError(() => of(null))))
      )
      .pipe(catchError(() => {
        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
        return of(null)
      }))
    }
    return submitTimeReportRequest;
  }


  public submitTimeReportNext(periodEndDate: Date, IsSendForApproval: boolean, isTimeTab: boolean = false): Observable<any> {
    let supervisorEid = this.supervisorEid;
    let userEid = this.userEid;
    return this.timeReportApi.validateTimeReport(
      supervisorEid,
      this.viewMode,
      this.createSubmissionInput(periodEndDate, IsSendForApproval ? 2 : 1, userEid, null)
    )
    .pipe(
      map(timeReportValidationResult => {
        if (timeReportValidationResult != null)
        {
          let errors = timeReportValidationResult.timesheetValidationErrors.errors;
          if(errors.length>0){
            this.globalCacheService.getTabs(periodEndDate).subscribe(tabs => {if(tabs!= null) {tabs.set('Time', true)}});
            this.setLBDASpecialSWbsCache(errors, userEid, periodEndDate);
          }else{
            this.globalCacheService.getTabs(periodEndDate).subscribe(tabs => {if(tabs!= null) {tabs.set('Time', false)}});
          }
          if (timeReportValidationResult.carInformationValidationErrors.errors.length > 0)
            timeReportValidationResult.carInformationValidationErrors.errors.forEach(e => errors.push(e));
          if (timeReportValidationResult.locationQuestionnaireValidationErrors.errors.length > 0)
            timeReportValidationResult.locationQuestionnaireValidationErrors.errors.forEach(e => errors.push(e));
            if (timeReportValidationResult.validationRuleErrors.errors.length > 0)
            timeReportValidationResult.validationRuleErrors.errors.forEach(e => errors.push(e));
            if (timeReportValidationResult.activityTotalErrors.errors.length > 0){
              timeReportValidationResult.activityTotalErrors.errors.forEach(e => errors.push(e)); 
              this.globalCacheService.getTabs(periodEndDate).subscribe(tabs => {if(tabs!= null) {tabs.set('Activity', true)}});
            }else{
              this.globalCacheService.getTabs(periodEndDate).subscribe(tabs => {if(tabs!= null) {tabs.set('Activity', false)}});
            }
          if(timeReportValidationResult.timesheetValidationErrors.errors.find(e => e.errorType.includes('ActivityDataNotFoundForOvertime')))
          {
            this.globalCacheService.getTabs(periodEndDate).subscribe(tabs => {if(tabs!= null) {tabs.set('Activity', true)}});
          }
          if (timeReportValidationResult.expenseTotalErrors.errors.length > 0)
            timeReportValidationResult.expenseTotalErrors.errors.forEach(e => errors.push(e));   
          if (timeReportValidationResult?.expenseValidationErrors) {
            Object.values(timeReportValidationResult?.expenseValidationErrors).forEach(validationErrorsOutput => {
              if (validationErrorsOutput?.errors) {
                validationErrorsOutput.errors.forEach(e => {if(e.isBlocker)errors.push(e)});
              }});
          }
          if (errors instanceof Array) {
            if (errors) {
              errors.forEach(e => {
                let va = new ValidationErrorOutPut();
                va.errorMsg = e.errorMsg;
                va.errorSource = e.errorSource;
                va.errorType = e.errorType;
              })
            }
            return {
              errors: errors,
              lbdReason: timeReportValidationResult.lbdChangeReason,
              ppaReason: timeReportValidationResult.ppaReason,
              expenseHasFederalWBSInd: timeReportValidationResult.expenseHasFederalWBSInd,
              federalWBSList: timeReportValidationResult.federalWBSList,
              summaryCards: timeReportValidationResult.summaryCards,
              activityErrors: timeReportValidationResult.activityTotalErrors
            };
          }

          return {errors: errors, lbdReason: timeReportValidationResult.lbdChangeReason, ppaReason: timeReportValidationResult.ppaReason, summaryCards: timeReportValidationResult.summaryCards,activityErrors: timeReportValidationResult.activityTotalErrors};
          }
        })
    )
    .pipe(
      catchError(err => {
        this.logService.logError(
          err,
          true,
          'Error on Submitting Time Report'
        );
        throw err;
      })
    );
  }

  public requestTimeReportUpdates(periodEnd: Date): Observable<SubmitTimeReportOutput> {
    if (this.appConfigData.AdditionalConfig.enableSubmission) {
      let supervisorEid = this.supervisorEid;
      let userEid = this.userEid;
      return this.timeReportApi
      .submitTimeReport(
        supervisorEid,
        this.viewMode,
        this.createSubmissionInput(periodEnd, 3, userEid, null)).pipe(map(res => {
          if (res.submitTimeReportStatus) {
            this.logService.showSuccessMessage('Requested Updates', `The time report status of ${this.userEid} is now Requested Updates`);
            let submitTimeReportOutput = new SubmitTimeReportOutput();
            submitTimeReportOutput.submitTimeReportStatus=res.submitTimeReportStatus;
            submitTimeReportOutput.timeReportStatus = res.timeReportStatus;
            return submitTimeReportOutput;
          } else {
             this.logService.showSuccessMessage('Request Updates', `Unable to request updates of Time Report`);
             let submitTimeReportOutput = new SubmitTimeReportOutput();
             submitTimeReportOutput.submitTimeReportStatus=res.submitTimeReportStatus;
             submitTimeReportOutput.timeReportStatus = res.timeReportStatus;
             return submitTimeReportOutput;
            }
        }))
        .pipe(catchError(() => {
          this.logService.showSuccessMessage('Request Updates', `Unable to request updates of Time Report`);
          let submitTimeReportOutput = new SubmitTimeReportOutput();
          submitTimeReportOutput.submitTimeReportStatus=false;
          submitTimeReportOutput.timeReportStatus = '';
          return of(submitTimeReportOutput);
        }));
    } else {
      return this.legacyTimeApi.submitTimeReportNext(this.userEid, periodEnd, true, this.supervisorEid, this.viewMode == SubordinatesMode.Approver, true)
      .pipe(map(res => {
        if (res) {
          this.logService.showSuccessMessage('Requested Updates', `The time report status of ${this.userEid} is now Requested Updates`);
          let submitTimeReportOutput = new SubmitTimeReportOutput();
          submitTimeReportOutput.submitTimeReportStatus=true;
          submitTimeReportOutput.timeReportStatus = '';
          return submitTimeReportOutput;
        } else {
          this.logService.showSuccessMessage('Request Updates', `Unable to request updates of Time Report`);
          let submitTimeReportOutput = new SubmitTimeReportOutput();
          submitTimeReportOutput.submitTimeReportStatus=false;
          submitTimeReportOutput.timeReportStatus ='';
          return submitTimeReportOutput;
        }
      }))
      .pipe(catchError(() => {
        this.logService.showSuccessMessage('Request Updates', `Unable to request updates of Time Report`);
        let submitTimeReportOutput = new SubmitTimeReportOutput();
        submitTimeReportOutput.submitTimeReportStatus=false;
        submitTimeReportOutput.timeReportStatus ='';
        return of(submitTimeReportOutput);
      }));
    }
  }

  public submitTimeReport(period: Date, ppaReason: string = null): Observable<SubmitTimeReportOutput> {
    let showPolicy818Warning = sessionStorage.getItem('showPolicy818Warning') ? JSON.parse(sessionStorage.getItem('showPolicy818Warning')) : false;
    let supervisorEid = this.supervisorEid;
    let userEid = this.userEid;

    if (this.appConfigData.AdditionalConfig.enableSubmission) {
      let submitTimeReportRequest = this.timeReportApi
      .submitTimeReport(
        supervisorEid,
        this.viewMode,
        this.createSubmissionInput(period, 1, userEid, ppaReason))
      .pipe(
        map(response => {
          if (response) {
            this.logService.showSuccessMessage(
              'Submit Successfully.',
              'Period ' + new DateformatPipe().transform(period, FormatDateType.FormatDate) + ' is now Submitted.'
            );
            if(showPolicy818Warning){
              this.logService.logInfo(
                'Your Time Report was processed. However, you are using a charge code that is not aligned to your work location reported. Please Note: Continuous use of an incorrect charge code will be subject to further escalation and restriction of future time report submission.',
                true,
                'Tips'
              );
            }
            this.globalCacheService.getSWAfterSubmission(this.globalCacheService.getPeriodEnd()).subscribe(softWarningMessages =>{
              if(softWarningMessages && softWarningMessages.length > 0)
              {
                softWarningMessages.forEach(
                  message =>
                  this.logService.logInfo(
                    message,
                    true,
                    'Tips'
                  )
                )
              }
            });
            let submitTimeReportOutput = new SubmitTimeReportOutput();
            submitTimeReportOutput.submitTimeReportStatus=response.submitTimeReportStatus;
            submitTimeReportOutput.timeReportStatus = response.timeReportStatus;
            return submitTimeReportOutput;
          } else {
              this.logService.logErrorWithMsgInfo('Error on Submitting Time Report');
            }
      }))
      .pipe(
        catchError(err => {
          this.logService.logError(
            err,
            true,
            'Error on Submitting Time Report'
          );
          throw err;
        })
      );

    return submitTimeReportRequest;
    }else{
      let submitTimeReportRequest = this.legacyTimeApi
      .submitTimeReportNext(this.userEid, period, true, this.supervisorEid, this.viewMode == SubordinatesMode.Approver, false)
      .pipe(
        map(response => {
          if (response) {
            if (response == 'Submit Successfully.') {
              this.logService.showSuccessMessage(
                response,
                'Period ' + new DateformatPipe().transform(period, FormatDateType.FormatDate) + ' is now Submitted.'
              );
              if(showPolicy818Warning){
                this.logService.logInfo(
                  'Your Time Report was processed. However, you are using a charge code that is not aligned to your work location reported. Please Note: Continuous use of an incorrect charge code will be subject to further escalation and restriction of future time report submission.',
                  true,
                  'Tips'
                );
              }
              this.globalCacheService.getSWAfterSubmission(this.globalCacheService.getPeriodEnd()).subscribe(softWarningMessages =>{
                if(softWarningMessages && softWarningMessages.length > 0)
                {
                  softWarningMessages.forEach(
                    message =>
                    this.logService.logInfo(
                      message,
                      true,
                      'Tips'
                    )
                  )
                }
              });
              let submitTimeReportOutput = new SubmitTimeReportOutput();
              submitTimeReportOutput.submitTimeReportStatus=response.submitTimeReportStatus;
              submitTimeReportOutput.timeReportStatus = response.timeReportStatus;
              return submitTimeReportOutput;
            } else {
              response.forEach(error => {
                if (error && error.Message) {
                  let msgHtml = {
                    msg: error.Message, showToast: true, title: '', log: '', isAlways: true
                  };
                  this.logService.logErrorWithMsgInfo(msgHtml);
                }
              });
              let submitTimeReportOutput = new SubmitTimeReportOutput();
              submitTimeReportOutput.submitTimeReportStatus=response.submitTimeReportStatus;
              submitTimeReportOutput.timeReportStatus = response.timeReportStatus;
              return submitTimeReportOutput;
            }
          }
        })
      )
      .pipe(
        catchError(err => {
          this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
          this.logService.logError(
            err,
            true,
            'Error on Submitting Time Report'
          );
          throw err;
        })
      );

    return submitTimeReportRequest;
    }

  }

  public getTimeReportHistoryObservable(): Observable<TimeReportHistory> {
    return this.timeApi.getTimeReportHistory(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode)
      .pipe(map((timesheetLogOutput: Array<TimesheetLogOutput>) => {
        return TimeReportService.mapAPITimeReportHistory(timesheetLogOutput);
      }))
      .pipe(catchError((error: any) => {
        this.logService.logError(error, true);
        return of(undefined);
      }));
  }

  public addTimeReportHistoryCommentsObservable(timeReportLogInput: TimeReportLogInput): Observable<TimeReportHistory> {
    if (!timeReportLogInput || (timeReportLogInput && !timeReportLogInput.timeReportId)) return of(undefined);
    timeReportLogInput.enterpriseId = this.userEid;
    this.globalEventsService.dispatchShowGlobalSpinnerEvent(true);
    return this.timeReportApi.addTimeReportHistoryComments(this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, TimeReportService.mapTimesheetLogInput(timeReportLogInput))
      .pipe(map((timesheetLogOutput: Array<TimesheetLogOutput>) => {
        if (timesheetLogOutput && timesheetLogOutput.length > 0)
          this.logService.showSuccessMessage('', 'Comments added to Time Report History.');
        return TimeReportService.mapAPITimeReportHistory(timesheetLogOutput);
      }))
      .pipe(catchError((error: any) => {
        this.logService.logError(`Can not save comment on timeReportHistory. eid: ${timeReportLogInput.enterpriseId} - comment: ${timeReportLogInput.commentTxt}`, true);
        return of(undefined);
      }))
      .pipe(finalize(() => {
        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
      }));
  }

  public static mapAPITimeReportHistory(apiTimesheetLogsOutput: Array<TimesheetLogOutput>): TimeReportHistory {
    const timeReportHistory: TimeReportHistory = new TimeReportHistory();
    if (apiTimesheetLogsOutput && Object.keys(apiTimesheetLogsOutput).length > 0) {
      timeReportHistory.timeReportLog = this.mapAPITimesheetLogOutput(apiTimesheetLogsOutput);
      timeReportHistory.timeReportHistoryLog = this.mapTimeReportHistoryLog(timeReportHistory.timeReportLog);
      timeReportHistory.isAddCommentsTextEnabled = true;
    }
    return timeReportHistory;
  }

  private static mapAPITimesheetLogOutput(apiTimesheetLogsOutput: Array<TimesheetLogOutput>): Array<TimeReportLog> {
    const timeReportLogs: Array<TimeReportLog> = new Array<TimeReportLog>();
    if (apiTimesheetLogsOutput && apiTimesheetLogsOutput.length > 0) {
      apiTimesheetLogsOutput.map((apiTimesheetLogOutput: TimesheetLogOutput) => {
        const timeReportLog: TimeReportLog = new TimeReportLog();
        if (apiTimesheetLogOutput && Object.keys(apiTimesheetLogOutput).length > 0) {
          Object.assign(timeReportLog, apiTimesheetLogOutput);
          timeReportLog.timeReportId = apiTimesheetLogOutput.timeReportId;
          timeReportLog.timeReportLogId = apiTimesheetLogOutput.id;
          timeReportLog.submitterEID = apiTimesheetLogOutput.status === TimeReportStatusModel.Processed || apiTimesheetLogOutput.status === TimeReportStatusModel.ProcessedAdjustment ? undefined : apiTimesheetLogOutput.submitterEnterpriseId;
          timeReportLog.submitDate = apiTimesheetLogOutput.approverSubmitDateTime ?? apiTimesheetLogOutput.submitDateTime;
          timeReportLog.status = apiTimesheetLogOutput.status;
          timeReportLog.comments = apiTimesheetLogOutput.comments;
          timeReportLog.actions = this.mapAPITimesheetLogActionOutput(apiTimesheetLogOutput.actions);
        }
        timeReportLogs.push(timeReportLog);
      });
    }
    return timeReportLogs;
  }

  private static mapAPITimesheetLogActionOutput(apiTimesheetLogActionsOutput: Array<TimesheetLogActionOutput>): Array<TimeReportLogAction> {
    const timeReportLogActions: Array<TimeReportLogAction> = new Array<TimeReportLogAction>();
    if (apiTimesheetLogActionsOutput && apiTimesheetLogActionsOutput.length > 0) {
      apiTimesheetLogActionsOutput.map((apiTimesheetLogActionOutput: TimesheetLogActionOutput) => {
        const timeReportLogAction: TimeReportLogAction = new TimeReportLogAction();
        if (apiTimesheetLogActionOutput && Object.keys(apiTimesheetLogActionOutput).length > 0) {
          Object.assign(timeReportLogAction, apiTimesheetLogActionOutput);
          timeReportLogAction.timeReportLogActionId = apiTimesheetLogActionOutput.id;
          timeReportLogAction.actionDec = apiTimesheetLogActionOutput.actionDescription;
          timeReportLogAction.comment = apiTimesheetLogActionOutput.sourceSystem ? `${apiTimesheetLogActionOutput.comment} - ${apiTimesheetLogActionOutput.sourceSystem}` : apiTimesheetLogActionOutput.comment;
          timeReportLogAction.addActionDate = apiTimesheetLogActionOutput.actionDate;
          timeReportLogAction.addActionEID = apiTimesheetLogActionOutput.enterpriseId;
          timeReportLogAction.sourceSystem = apiTimesheetLogActionOutput.sourceSystem;
        }
        timeReportLogActions.push(timeReportLogAction);
      });
    }
    return timeReportLogActions;
  }

  private static mapTimeReportHistoryLog(timeReportLogs: Array<TimeReportLog>): Array<TimeReportHistoryLog> {
    var timeReportHistoryLogs: Array<TimeReportHistoryLog> = new Array<TimeReportHistoryLog>();
    if (timeReportLogs && timeReportLogs.length > 0) {
      timeReportLogs.map((timeReportLog: TimeReportLog, index: number, currentTimeReportLog: Array<TimeReportLog>) => {
        const timeReportHistoryLog: TimeReportHistoryLog = new TimeReportHistoryLog();
        if (timeReportLog && Object.keys(timeReportLog).length > 0) {
          timeReportHistoryLog.status = timeReportLog.status;
          timeReportHistoryLog.action = timeReportLog.comments && timeReportLog.comments !== ' - ' && timeReportLog.status === TimeReportStatusModel.Submitted ? ActionDescription.SystemGenerated : '';
          timeReportHistoryLog.date = timeReportLog.submitDate;
          timeReportHistoryLog.eid = timeReportLog.submitterEID ?? '';
          timeReportHistoryLog.comments = timeReportLog.comments ?? '';
        }
        timeReportHistoryLogs.push(timeReportHistoryLog);
        if (timeReportLog.actions && timeReportLog.actions.length > 0) {
          timeReportLog.actions.forEach((action: TimeReportLogAction) => {
            if (moment(timeReportLog.submitDate).isSame(action.addActionDate, 'day') && (action.sourceSystem !== SourceSystem.MyTim && action.sourceSystem !== SourceSystem.JPBPO)) {
              timeReportHistoryLog.comments = action.comment ?? '';
              timeReportHistoryLog.action = action.actionDec;
              if (action.actionDec.toLowerCase().includes(ActionDescription.ExpenseReverse.toLowerCase())) {
                timeReportHistoryLog.eid = TimeReportHistoryLogEID.AuditTeam;
              } else if (action.actionDec.toLowerCase().includes(ActionDescription.AutomaticReverse.toLowerCase()) && timeReportLog.status.includes(TimeReportStatusModel.DraftAdjustment)) {
                if (index < currentTimeReportLog.length - 1 && currentTimeReportLog[index + 1].actions.length > 0 && currentTimeReportLog[index + 1].actions[0].actionDec.toLowerCase().includes(ActionDescription.SystemGenerated.toLowerCase())) {
                  timeReportHistoryLog.eid = TimeReportHistoryLogEID.SystemGenerated;
                  timeReportHistoryLog.action = ActionDescription.AutomaticReverse;
                } else {
                  timeReportHistoryLog.eid = action.addActionEID.toLowerCase();
                  timeReportHistoryLog.action = '';
                }
              } else {
                const addTimeSourceSystems: Array<string> = [WebServiceSourceSystem.Lamsystems.toLowerCase(), WebServiceSourceSystem.ServiceNow.toLowerCase(), WebServiceSourceSystem.PESH.toLowerCase(), WebServiceSourceSystem.PMTI.toLowerCase(), WebServiceSourceSystem.MSPS.toLowerCase()]
                timeReportHistoryLog.eid = (action.actionDec.toLowerCase().includes(ActionDescription.AutomaticReverse.toLowerCase())
                  || (action.actionDec.toLowerCase().includes(ActionDescription.SystemGenerated.toLowerCase()) && !action.sourceSystem)
                  || (action.actionDec.toLowerCase().includes(ActionDescription.SystemGenerated.toLowerCase()) && (!action.sourceSystem.includes(SourceSystem.Mobile) || !action.sourceSystem.includes(SourceSystem.MobileNext))
                  && !action.sourceSystem.toLowerCase().includes(WebServiceSourceSystem.PESH.toLowerCase())
                  && !action.sourceSystem.toLowerCase().includes(WebServiceSourceSystem.Lamsystems.toLowerCase())
                  && !action.sourceSystem.toLowerCase().includes(WebServiceSourceSystem.Workbook.toLowerCase())
                  && !addTimeSourceSystems.includes(action.sourceSystem.toLowerCase()))) ? TimeReportHistoryLogEID.SystemGenerated : action.addActionEID.toLowerCase();
              }
            } else {
              const timeReportHistoryLogWithActions: TimeReportHistoryLog = new TimeReportHistoryLog();
              timeReportHistoryLogWithActions.status = timeReportLog.status;
              timeReportHistoryLogWithActions.action = action.actionDec;
              timeReportHistoryLogWithActions.date = action.addActionDate;
              timeReportHistoryLogWithActions.eid = action.addActionEID.toLowerCase();
              timeReportHistoryLogWithActions.comments = action.comment ?? '';

              if (action.actionDec && action.actionDec.toLowerCase().includes(ActionDescription.AuditTeam.toLowerCase())) {
                timeReportHistoryLogWithActions.eid = TimeReportHistoryLogEID.AuditTeam;
              } else if (action.actionDec && action.comment && action.comment.toLowerCase().includes('admin generated')) {
                timeReportHistoryLogWithActions.eid = TimeReportHistoryLogEID.MyTEAdmin;
              } else if (action.actionDec && action.comment && action.comment.toLowerCase().includes('time loaded from ms project')) {
                timeReportHistoryLogWithActions.eid = TimeReportHistoryLogEID.PMTIUser;
              } else {
                const addTimeSourceSystems: Array<string> = [WebServiceSourceSystem.Lamsystems.toLowerCase(), WebServiceSourceSystem.ServiceNow.toLowerCase(), WebServiceSourceSystem.PESH.toLowerCase(), WebServiceSourceSystem.PMTI.toLowerCase(), WebServiceSourceSystem.MSPS.toLowerCase()]
                timeReportHistoryLogWithActions.eid = (action.actionDec.toLowerCase().includes(ActionDescription.AutomaticReverse.toLowerCase())
                  || (action.actionDec.toLowerCase().includes(ActionDescription.SystemGenerated.toLowerCase()) && !action.sourceSystem)
                  || (action.actionDec.toLowerCase().includes(ActionDescription.SystemGenerated.toLowerCase()) && (!action.sourceSystem.includes(SourceSystem.Mobile) || !action.sourceSystem.includes(SourceSystem.MobileNext))
                  && !action.sourceSystem.toLowerCase().includes(WebServiceSourceSystem.PESH.toLowerCase())
                  && !action.sourceSystem.toLowerCase().includes(WebServiceSourceSystem.Lamsystems.toLowerCase())
                  && !action.sourceSystem.toLowerCase().includes(WebServiceSourceSystem.Workbook.toLowerCase())
                  && !addTimeSourceSystems.includes(action.sourceSystem.toLowerCase()))) ? TimeReportHistoryLogEID.SystemGenerated : action.addActionEID.toLowerCase();
              }
              var isActionExist = timeReportHistoryLogs.find(x => x.date.toISOString().split('T')[0] === timeReportHistoryLogWithActions.date.toISOString().split('T')[0] 
                                                                  && x.eid === timeReportHistoryLogWithActions.eid 
                                                                  && x.comments === timeReportHistoryLogWithActions.comments
                                                                  && x.status === timeReportHistoryLogWithActions.status);

              if(isActionExist !== undefined)
              {
              timeReportHistoryLogs = timeReportHistoryLogs.filter(x => x !== isActionExist);
              }

              timeReportHistoryLogs.push(timeReportHistoryLogWithActions);    
            }
          })
        }
      });
    }
    timeReportHistoryLogs.sort((a: TimeReportHistoryLog, b: TimeReportHistoryLog) => a.date.getTime() - b.date.getTime());
    return timeReportHistoryLogs;
  }

  public static mapTimesheetLogInput(timeReportLogInput: TimeReportLogInput): TimesheetLogInput {
    const timesheetLogInput: TimesheetLogInput = new TimesheetLogInput();
    if (timeReportLogInput && Object.keys(timeReportLogInput).length > 0) {
      timesheetLogInput.actionKey = timeReportLogInput.actionKey;
      timesheetLogInput.commentTxt = timeReportLogInput.commentTxt;
      timesheetLogInput.timesheetLogId = timeReportLogInput.timesheetLogId;
      timesheetLogInput.enterpriseId = timeReportLogInput.enterpriseId;
      timesheetLogInput.timeReportId = timeReportLogInput.timeReportId;
    }
    return timesheetLogInput;
  }

  public getTimeReportHistory(periodEndDate: Date): Observable<TimeReportLog[]> {
    let userEid = this.userEid;
    let supervisorEid = this.supervisorEid;

    return this.timeApi
      .getTimeReportHistory(userEid, periodEndDate, supervisorEid, this.viewMode)
      .pipe(map(timesheetLogOutput => this.mapTimeSheetHistoryLogForTimesheetLogOutput(timesheetLogOutput)))
      .pipe(catchError(err => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }

  public addTimeSheetComments(timeSheetActions: SaveTimesheetLogAction): Observable<TimeReportLog[]> {
    let timesheetLogInput = new TimesheetLogInput();
    timesheetLogInput.timeReportId = timeSheetActions.timeReportId;
    timesheetLogInput.timesheetLogId = timeSheetActions.timesheetLogId;
    timesheetLogInput.enterpriseId = this.userEid;
    timesheetLogInput.actionKey = timeSheetActions.actionKey;
    timesheetLogInput.commentTxt = timeSheetActions.commentTxt;
    return this.timeReportApi
      .addTimeReportHistoryComments(this.periodEndService.getActivePeriod(),
        this.supervisorEid, this.viewMode, timesheetLogInput
      )
      .pipe(
        map(result => {
          if (result) {
            this.logService.showSuccessMessage('', 'Comments added to Time Report History.');
            return this.mapTimeSheetHistoryLogForTimesheetLogOutput(result);
          }
          return null;
        })
      )
      .pipe(
        catchError(err => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }

  public removeCurrentPendingPpa(periodEnd: Date, currentMode: any): Observable<string> {
    return this.timeReportApi
      .removeCurrentPendingPpa(periodEnd, this.userEid, this.supervisorEid, currentMode ? currentMode : SubordinatesMode.None)
      .pipe(
        map(response => {
          if (response) {
            if (response == 'ProcessedAdjustment' || response == 'Processed' || response == "SubmitPendingAdjustment") {
              let timeReport = TimeReportUtils.createTimeReport(periodEnd, response, this.viewMode);
              this.globalCacheService.handleResetTimeReport(periodEnd);
              this.globalCacheService.handleTimeReport(periodEnd, of(timeReport));
              this.globalCacheService.clearTimeReportSummary(periodEnd, this.userEid);
              this.globalCacheService.resetAnnualChargeabilitySummary();
              this.globalCacheService.cleartWorkLocationByDay(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode);
              if (this.globalCacheService.adjustmentStorage) this.globalCacheService.adjustmentStorage.refresh = true;
              this.logService.showSuccessMessage(
                'Success!', 'Remove Adjustment successfully.'
              );
              return response;
            } else {
              this.logService.logError(
                response, true
              );
              return 'false';
            }
          }
        })
      )
      .pipe(
        catchError(err => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }

  public removeAllPendingPpa(periodEnd): Observable<ReverseAllPpaResult> {
    return this.timeReportApi
      .removeAllPendingPpa(periodEnd, this.userEid, this.supervisorEid, this.viewMode)
      .pipe(
        map(response => {
          let reverseAllPpaResult = new ReverseAllPpaResult();
          reverseAllPpaResult.leaveManagementMessage = response?.leaveManagementMessage;
          reverseAllPpaResult.removedPeriods = response?.removedPeriods;
          reverseAllPpaResult.enterpriseId = this.userEid;
          if ( response.leaveManagementMessage == null ) {
                this.logService.showSuccessMessage(
                  'Success!', 'Remove Adjustment successfully.'
                );
                return reverseAllPpaResult;
              }
              else {

                this.logService.logError(
                  response.leaveManagementMessage , true
                );
                return reverseAllPpaResult;
              }
        })
      )
      .pipe(
        catchError(err => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }

  public findSpecificPriorPeriods(periodEnd: Date, status: string[]): Observable<Date[]>{
    return this.timeReportApi
      .findSpecificPriorPeriods(periodEnd, this.userEid, this.supervisorEid, this.viewMode, status)
      .pipe(
        map(dates => {
          return dates;
        }))
        .pipe(
          catchError(err => {
            this.logService.logError(err, true);
            return of(null);
          })
        );
  }

  public getPriorTimesheets(periodEnd: Date): Observable<boolean> {
    return this.timeReportApi.hasPendingAdjustments(periodEnd, this.userEid, this.supervisorEid, this.viewMode).pipe(
      catchError((err) => {
        this.logService.logError(err, true);
        return of(null);
      })
    );
  }

  public updateSubmittedToDraft(periodEnd: Date): Observable<boolean> {
    let updateSubmittedToDraftRequest;

    if (this.appConfigData.AdditionalConfig.enableUpdateTimeReportEndpoint) {
      updateSubmittedToDraftRequest = this.timeReportApi.updateTimeReport(this.userEid ,periodEnd, this.supervisorEid, this.viewMode)
        .pipe(
          map((response) => {
            if (response) {
              let periodEnd = this.globalCacheService.getPeriodEnd();
              let timeReport = TimeReportUtils.createTimeReport(periodEnd, "Draft", this.viewMode);
              this.globalCacheService.handleResetTimeReport(periodEnd);
              this.globalCacheService.handleTimeReport(periodEnd, of(timeReport));
              this.globalCacheService.clearTimeReportSummary(periodEnd, this.userEid);
              this.globalCacheService.resetAnnualChargeabilitySummary();
              return true;
            } else {
              this.logService.logError(response, true, "error");
              return false;
            }
          })
        )
        .pipe(
          catchError((err) => {
            this.logService.logError(err, true);
            return of(null);
          })
        );
    } else {
      updateSubmittedToDraftRequest = this.legacyTimeApi.revertSubmittedTimeReportNext(this.userEid, periodEnd, this.supervisorEid, this.viewMode == 'Approver')
        .pipe(
          map((response) => {
            if (response) {
              if (response == "Updated Successfully.") {
                return true;
              } else {
                this.logService.logError(response, true, "error");
                return false;
              }
            }
          })
        )
        .pipe(
          catchError((err) => {
            this.logService.logError(err, true);
            return of(null);
          })
        );
    }

    return this.globalCacheService.handleUpdateTimeReport(
      periodEnd,
      updateSubmittedToDraftRequest
    );
  }

  public getFirstTimeReportPeriodInDraft(enterpriseId: string, periodEnd: Date): Observable<Date> {
    return this.legacyTimeApi
      .findFirstTimeReportPeriodInDraft(enterpriseId, periodEnd)
      .pipe(map(newTRPeriodEnd => this.mapNewTRPeriodEnd(newTRPeriodEnd)))
      .pipe(
        catchError(err => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }

  public homeOfficeLocation2(countryKey: string, locationOneCode: string): Observable<any> {
    return this.locationsApi
    .getLocationTwo(this.userEid, this.periodEndService.getActivePeriod(), countryKey, locationOneCode, this.supervisorEid, this.viewMode)
      .pipe(map(x => Object.entries(x.locationsTwo).map(value => new DropdownItem(value[0], value[1]))))
      .pipe(
        catchError(err => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }

  public sendForApproval(periodEndDate: Date): Observable<SubmitTimeReportOutput> {
    if (this.appConfigData.AdditionalConfig.enableSubmission){
      return this.globalCacheService.handleSendForApproval(periodEndDate, this.timeReportApi.submitTimeReport(
        this.supervisorEid,
        this.viewMode,
        this.createSubmissionInput(periodEndDate,2,this.userEid, null)
      ));
      }
    else {
      return this.globalCacheService.handleSendForApproval(periodEndDate, this.legacyTimeApi.sendForApproval(this.userEid, periodEndDate, this.supervisorEid));
    }
  }

  public getTimeReportSettings(periodEnd: Date, countryKey: string, removeAdjustment?: boolean): Observable<TimeReportSettings> {
    let supervisorEid = this.supervisorEid;
    let viewMode = this.globalCacheService.getSubordinateMode();
    let userEid = viewMode == "ReviewEmail" ? this.globalCacheService.getRevieweeEid() : this.userEid;
    if(removeAdjustment){
      this.globalCacheService.getTimeReportSettings(periodEnd, countryKey).subscribe(trs => trs?.travelDiaryOptions?.travelDiaryRequiredBool ? this.globalCacheService.resetTimeReportSettingsBuffer(periodEnd, this.globalCacheService.getUserCountry()) : "");
    }
    let userSettingsRequest = new UserSettingsRequest();
    userSettingsRequest.enterpriseId = userEid;
    userSettingsRequest.periodEnd = periodEnd;
    let requestTimeReportSettings = this.timeReportApi.getUserSettingsPOST(
      supervisorEid, this.viewMode, userSettingsRequest)
        .pipe(map(userExpenseSettings => this.mapTimeReportSettings(userExpenseSettings, periodEnd)))
        .pipe(tap(timeReportSettings => {
          if(viewMode == "ReviewEmail"){
            this.globalCacheService.setRevieweeName(timeReportSettings.userFullName);
            this.globalCacheService.setSubordinateMode(SubordinatesMode.ReviewEmail);
          }

          this.globalCacheService.handleResetTimeReport(periodEnd);
          this.globalCacheService.handleTimeReport(periodEnd, of(timeReportSettings.timeReport));
        }))
        .pipe(catchError(err => {
            this.logService.logError(err, false);
            return of(null);
        }));
    return this.globalCacheService.handleTimeReportSettings(periodEnd, countryKey, requestTimeReportSettings);
  }

  public validateTimeReportGroup(subordinatesToSubmit: Subordinate[], viewMode: string, periodEnd: Date, isSubmitAll: boolean): Observable<ValidationGroupErrorsOutput> {
    let submitGroupInput = this.mapSubmitGroupInput(subordinatesToSubmit, this.supervisorEid ?? this.userEid, viewMode, periodEnd, isSubmitAll);
    return this.timeReportApi.validateTimeReportGroup(submitGroupInput)
      .pipe(map(data => {
        if (!data.shouldContinueSubmit) {
          this.logService.logInfo('No matched time report to be submitted', true, '');
        }
        if (data.submissionErrorMessages?.length > 0) {
          this.logService.logError('One or more time reports could not be submitted due to errors. Hover over names with a red circle to review the errors and correct them before re-submitting the group.', true, '');
        }
        return data;
      }))
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(null);
      }));
  }

  public validateTimeReportSelected(subordinatesToSubmit: Subordinate[]): boolean {
      if(subordinatesToSubmit.length > 0){
        const validFilter = subordinatesToSubmit.filter(s => s.statusCode === TimeReportStatusCode.SubmittedPendingApproval);
        if(validFilter.length > 0){
          return true;
        }else{
          this.logService.logInfo('No matched time report to be submitted', true, '');
          return false;
        }
    }else{
      return true;
    }
  }

  public submitTimeReportGroup(subordinatesToSubmit: Subordinate[], viewMode: string, periodEnd: Date) {
    let submitGroupInput = this.mapSubmitGroupInput(subordinatesToSubmit, this.supervisorEid ?? this.userEid, viewMode, periodEnd, false);
    return this.timeReportApi.submitTimeReportGroup(submitGroupInput)
      .pipe(
        map(res => {
          this.logService.showSuccessMessage('Success', 'Submitted Successfully.');
          return res;
        }))
      .pipe(
        catchError(err => {
          this.logService.logError(err, true);
          return of(null);
        }));
  }

  private mapSubmitGroupInput(subordinatesToSubmit: Subordinate[], loginUserEid: string, viewMode: string, periodEnd: Date, isSubmitAll: boolean): SubmissionGroupInput {
    let eidsToSubmit = [];
    subordinatesToSubmit.forEach(sts => {
      eidsToSubmit.push(sts.enterpriseId);
    });
    let submitGroupInput = new SubmissionGroupInput();
    submitGroupInput.loginEnterpriseId = loginUserEid;
    submitGroupInput.subordinateEids = eidsToSubmit;
    submitGroupInput.viewMode = viewMode;
    submitGroupInput.periodEnd = periodEnd;
    submitGroupInput.isSubmitAll = isSubmitAll;

    return submitGroupInput;
  }

  private mapTimeReportSettings(userSettings: UserSettings, periodEnd: Date): TimeReportSettings {
    let timeReportSettings = new TimeReportSettings();
    timeReportSettings.scheduleSettings = userSettings.scheduleSettings;
    timeReportSettings.canViewUploadReceipts = userSettings.canViewUploadReceipts;
    timeReportSettings.shouldShowAdditionalDocumentationLink = userSettings.shouldShowAdditionalDocumentationLink;
    timeReportSettings.showCompensatoryTab = userSettings.compensatoryTabIndicator;
    timeReportSettings.showCarPlan = userSettings.carPlanTab;
    timeReportSettings.showLocationQuestionnaireTab = userSettings.locationQuestionnaireTabIndicator;
    timeReportSettings.shouldShowLyftCheckBox = userSettings.canSetLyft;
    timeReportSettings.isApprovalRequested = userSettings.isApprovalRequested;
    timeReportSettings.hasApprovers = userSettings.hasApprover;
    timeReportSettings.expenseTypes = ExpenseServiceMapper.mapExpenseTypes(userSettings.expenseTypes);
    timeReportSettings.travelDiaryOptions = this.mapTravelDiarySettings(userSettings);
    timeReportSettings.ShowAdjustingOldTimeReportPopUp = userSettings.showAdjustingOldTimeReportPopUp;
    timeReportSettings.EthicMessages = this.mapEthicMessages(userSettings);
    timeReportSettings.isUserGMC = userSettings.isUserGMC;
    timeReportSettings.isUserSMD = userSettings.isUserSMD;
    timeReportSettings.salarySupplements = userSettings.salarySupplements;
    timeReportSettings.shouldUseTwoDecimal = userSettings.shouldUseTwoDecimal;
    timeReportSettings.userCountryKey = userSettings.userCountryKey;
    timeReportSettings.settingCountryKey = userSettings.settingCountryKey;
    timeReportSettings.hasRestHours = userSettings.hasRestHours;
    timeReportSettings.CarInformationBool = userSettings.carInformationBool;
    timeReportSettings.shouldEnableReasonAndType = userSettings.shouldEnableReasonAndType;
    timeReportSettings.userActiveDate = userSettings.userActiveDate;
    timeReportSettings.showDashboardPageBool = userSettings.showDashboardPageBool;
    timeReportSettings.timeReport = TimeReportUtils.createTimeReport(periodEnd, userSettings.timeReportStatus, this.viewMode);
    timeReportSettings.electronicReceiptValidExtension = userSettings.electronicReceiptValidExtension;
    timeReportSettings.canSetApprovers = userSettings.canSetApprovers;
    timeReportSettings.punchClockConfiguration = userSettings.punchClockConfiguration;
    timeReportSettings.showChargeabilityImpactConfig = userSettings.showChargeabilityImpactConfig;
    timeReportSettings.shouldShowDisclaimer = userSettings.shouldShowDisclaimer;

    timeReportSettings.shouldEnableAllowanceApprovalPageAsApprover = userSettings.shouldEnableAllowanceApprovalPageAsApprover;
    timeReportSettings.shouldEnableCashOutRequest = userSettings.shouldEnableCashOutRequest;
    timeReportSettings.shouldEnableCashOutRequestLimit = userSettings.shouldEnableCashOutRequestLimit;
    timeReportSettings.shouldShowSummaryCards = userSettings.shouldShowSummaryCards;
    timeReportSettings.shouldEnableAllowanceRequest = userSettings.shouldEnableAllowanceRequests;
    timeReportSettings.userFullName = userSettings.userFullName;
    timeReportSettings.submissionDeadline = userSettings.submissionDeadline;
    timeReportSettings.shouldEnableOvertimeRequestApprover = userSettings.shouldEnableOvertimeRequestApprover;
    timeReportSettings.shouldEnableOvertimeRequestEmployee = userSettings.shouldEnableOvertimeRequestEmployee;
    timeReportSettings.shouldShowReceiptType = userSettings.shouldShowReceiptType;
    timeReportSettings.toastWanrningDisplay = userSettings.toastWanrningDisplay;
    timeReportSettings.isCamUser = userSettings.isCamUser;
    timeReportSettings.timeReportStatus =  userSettings.timeReportStatus;
    timeReportSettings.shouldEnableActivityBool = userSettings.shouldEnableActivityBool;
    timeReportSettings.shouldEnableEncashmentPopup = userSettings.shouldEnableEncashmentPopup;
    timeReportSettings.ShouldEnableWorkShiftApprover = userSettings.shouldEnableWorkShiftApprover;
    timeReportSettings.ShouldEnableWorkShiftRequest = userSettings.shouldEnableWorkShiftRequest;
    timeReportSettings.shouldEnableOnCallAndSWTimeApprover = userSettings.shouldEnableOnCallAndSWTimeApprover;
    timeReportSettings.shouldEnableOnCallAndSWTimeRequest = userSettings.shouldEnableOnCallAndSWTimeRequest;
    timeReportSettings.settingConstraints = userSettings.settingConstraints;
    timeReportSettings.additionalTimeIndicator = userSettings?.punchClockConfiguration?.additionalTimeIndicator;
    timeReportSettings.corporationCd = userSettings?.punchClockConfiguration?.corporationCd;
    if (userSettings.settingCountryKey)
    this.globalCacheService.setUserCountry(userSettings.settingCountryKey);
    if (userSettings.companyCode)
    this.globalCacheService.setUserCompanyCode(userSettings.companyCode);
    return timeReportSettings;
  }

  private mapEthicMessages(userSettings: UserSettings): UserEthicMessage[] {
    return userSettings.ethicMessages.map(message => {
      let userEthicMessage = new UserEthicMessage();
      userEthicMessage.messageBody = [message.messageBody];
      userEthicMessage.messageBottom = message.messageBottom;
      userEthicMessage.messageTitle = message.messageTitle;
      return userEthicMessage;
    });
  }

  private mapTravelDiarySettings(userSettings: UserSettings): TravelDiarySettings {
    let travelDiarySettings = new TravelDiarySettings();
    travelDiarySettings.canViewTravelDiaryLink = userSettings.canViewTravelDiaryLink;
    travelDiarySettings.travelDiaryRequiredBool = userSettings.travelDiaryRequiredBool;
    travelDiarySettings.needsToRecordLocationByDayBool = userSettings.needsToRecordLocationByDayBool;
    return travelDiarySettings;
}
  private mapNewTRPeriodEnd(newPeriodEnd: Date) {
    return new Date(newPeriodEnd);
  }

  private mapTimeSheetHistoryLogForTimesheetLogOutput(timesheetLogOutputs: TimesheetLogOutput[]): TimeReportLog[] {
    return timesheetLogOutputs.map(timesheetLogOutput => {
      let timeSheetHistoryLog = new TimeReportLog();
      timeSheetHistoryLog.timeReportId = timesheetLogOutput.timeReportId;
      timeSheetHistoryLog.timeReportLogId = timesheetLogOutput.id;
      timeSheetHistoryLog.submitterEID = (timesheetLogOutput.status == 'Processed'
        || timesheetLogOutput.status == 'ProcessedAdjustment') ? null : timesheetLogOutput.submitterEnterpriseId;
      timeSheetHistoryLog.status = timesheetLogOutput.status;
      timeSheetHistoryLog.submitDate = timesheetLogOutput.approverSubmitDateTime ?? timesheetLogOutput.submitDateTime;
      timeSheetHistoryLog.actions = this.mapTimeReportLogActions(
        timesheetLogOutput.actions
      );
      timeSheetHistoryLog.comments = timesheetLogOutput.comments;

      return timeSheetHistoryLog;
    });
  }

  private mapTimeReportLogActions(timeReportLogActions): TimeReportLogAction[] {
    return timeReportLogActions.map(actions => {
      let action = new TimeReportLogAction();
      action.timeReportLogActionId = actions.id;
      action.actionDec = actions.actionDescription;
      action.comment = actions.comment;
      action.addActionEID = actions.enterpriseId;
      action.addActionDate = actions.actionDate;
      action.sourceSystem = actions.sourceSystem;
      if (action.sourceSystem != null && action.sourceSystem != '' && action.sourceSystem != undefined) {
        action.comment = action.comment + ' - ' + action.sourceSystem;
      }
      return action;
    });
  }

  private mapRevertSubmittedStatusRes(responseMess): any {
    return responseMess;
  }

  public validateMaxOvertimeWithDraftAdjustment(): Observable<any>{
    return this.timeReportApi.validateMaxOvertimeWithDraftAdjustment(
      this.globalCacheService.getPeriodEnd(),
      this.userEid,
      this.supervisorEid,
      this.viewMode
    ).pipe(
      map(timesheetValidationErrors => {
          if(timesheetValidationErrors.errors instanceof Array) {
            return timesheetValidationErrors.errors;
          }
        }
      )
    ).pipe(
      catchError(err => {
        this.logService.logError(
          err,
          true,
          'Error on Validating Time Report OT hours'
        );
        throw err;
      })
    );
  }

  public getAutomationTestData(enterpriseId: string, periodEnd: Date): Observable<Array<TimeReportSubmitQueue>> {
    return this.timeReportApi.getAutomationTestData(enterpriseId, periodEnd).pipe(
      map(t => {
        let r = new Array<TimeReportSubmitQueue>();
        if (t.length > 0) {
          t.forEach(e => {
            let tr = new TimeReportSubmitQueue();
            tr.xmlToSend = e.xmlToSend;
            tr.endPoint = e.endPoint;
            r.push(tr);
          })
        }
        return r;
      })
    )
  }

  public getChargeabilityImpactReviewParams(enterpriseId: string): Observable<ChargeabilityImpactReviewInfo>{
    let periodEnd = this.globalCacheService.getPeriodEnd();
    return this.timeReportApi.getChargeabilityImpactReviewParams(enterpriseId, periodEnd,this.supervisorEid).pipe(
      map(data => {
        return data;
      }))
      .pipe(
        catchError(err => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }

  
  
  public setLBDASpecialSWbsCache(errors: ValidationErrorOutPut[], enterpriseId: string, periodEnd: Date): void {
    this.globalCacheService.getTimeReport(periodEnd).subscribe(tr => {
      if(tr && tr.status != 'Draft'){
        let LBDASpecialSWbsVlidations= new Array<string>();
        errors.forEach(e => {
          if (e && e.errorType && e.errorType == 'LocationByDayNotAllowSpecialStaticWbs') {
            let date = new Date(e.errorSource);
            LBDASpecialSWbsVlidations.push(date.toLocaleDateString());
          }
        });
        if (LBDASpecialSWbsVlidations && LBDASpecialSWbsVlidations.length > 0) {
          this.globalCacheService.setLBDNASpecialWbsValidations(LBDASpecialSWbsVlidations, enterpriseId, periodEnd);
        }
      }
    }); 
  }

   // This method is to handle displaying of ESPunchClock Softwarning toast message
  // when ESTravelPunchClock is enabled. Bug:1198564
  public shouldShowESTravelPunchClockWarning(value: boolean = false, showMessage: boolean = true) {
    if (!this.globalCacheService.getESPunchClockSoftWarning(this.periodEndService.getActivePeriod(), this.userService.getUser().enterpriseId) && value && showMessage)
      this.logService.logInfo(ESTravelPunchClockInfo.msg, ESTravelPunchClockInfo.showToast);
    this.globalCacheService.setESPunchClockSoftWarningBuffer(this.periodEndService.getActivePeriod(), this.userService.getUser().enterpriseId, value);
  }
  
  public getPrivacyConfiguration(appNum: string, enterpriseId: string){
    return this.timeReportApi.getPrivacyConfiguration(appNum,enterpriseId)
  }
  public savePrivacyConfiguration(appNum: string, enterpriseId: string){
    return this.timeReportApi.savePrivacyConfiguration(appNum,enterpriseId)
  }
  private createSubmissionInput(periodEnd: Date, submissionMode: number, enterpriseId: string, reason: string): SubmissionInput {
    let submissionInput = new SubmissionInput();
    submissionInput.periodEnd = periodEnd;
    submissionInput.submissionMode = submissionMode;
    submissionInput.enterpriseId = enterpriseId;
    submissionInput.reason = reason;
    return submissionInput;
  }

  public getTimeReportSettingsStore(periodEnd: Date, countryKey: string): Observable<TimeReportSettings> {
    let viewMode = this.globalCacheService.getSubordinateMode();
    let userEid = viewMode == "ReviewEmail" ? this.globalCacheService.getRevieweeEid() : this.userEid;
    
    let userSettingsRequest = new UserSettingsRequest();
    userSettingsRequest.enterpriseId = userEid;
    userSettingsRequest.periodEnd = periodEnd;
    let requestTimeReportSettings = 
    this.timeReportApi.getUserSettingsPOST(
      this.supervisorEid, this.viewMode, userSettingsRequest)
      .pipe(map(userExpenseSettings => this.mapTimeReportSettings(userExpenseSettings, periodEnd)))
      .pipe(tap(timeReportSettings => {
        if (viewMode == "ReviewEmail") {
          this.globalCacheService.setRevieweeName(timeReportSettings.userFullName);
          this.globalCacheService.setSubordinateMode(SubordinatesMode.ReviewEmail);
        }
      }))
      .pipe(catchError(err => {
        this.logService.logError(err, false);
        return of(null);
      }));
    return this.globalCacheService.handleTimeReportSettings(periodEnd, countryKey, requestTimeReportSettings);
  }

  public saveEncashmentRequest(encashmentRequest: EncashmentRequest): Observable<EncashmentRequestOutput> {
    return this.timeReportApi
        .saveEncashmentRequest(this.userEid, this.globalCacheService.getPeriodEnd(), encashmentRequest)
        .pipe(
            catchError(err => {
                this.logService.logError(
                    `Unable to save your encashment request. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                    true
                );
                return of(null);
            })
        );
  }
  public getEncashmentRequest(): Observable<EncashmentRequestOutput> {
    return this.timeReportApi
        .getEncashmentRequest(this.userEid, this.globalCacheService.getPeriodEnd())
        .pipe(
            catchError(err => {
                this.logService.logError(
                    `Unable to get your encashment request. If the problem persists please escalate the issue at myTimeandExpenses Help.`,
                    true
                );
                return of(null);
            })
        );
  }


  public getTimeOffInLieuAccrual(enterpriseId: string | undefined): Observable<TimeOffInLieuHoursAccrualOutput> {
    return this.timeReportApi.getTimeOffInLieuAccrual(enterpriseId ?? this.userEid, this.globalCacheService.getPeriodEnd(), this.supervisorEid).pipe(
      map(data => {
        return data;
      }))
      .pipe(
        catchError(err => {
          this.logService.logError(err, true);
          return of(null);
        })
      );
  }

  public saveTimeOffInLieuAccrual(body: TimeOffInLieuHoursAccrualInput): Observable<boolean> {
    return this.timeReportApi
        .saveTimeOffInLieuAccrual(this.supervisorEid, body)
        .pipe(
            catchError(err => {
                this.logService.logError(
                    `Unable to save your request. If the problem persists, please escalate the issue at myTimeandExpenses Help.`,
                    true
                );
                return of(null);
            })
        );
}

}