import TimeCategoryCharge from './time-category-charge';

export enum TimeCategoryType {
    RegularTimeCategory,
    OvertimeCategory,
    WorkScheduleCategory,
    PunchClockCategory,
    ShiftScheduleCategory,
    RestHoursCategory,
    AhgoraPunchClockCategory
}

export enum TimeCategoryUIType {
    TextBox,
    CheckBox,
    None
}

export class TimeCategoryTask {
    public charges: TimeCategoryCharge[] = [];
    public name: string;
    public tooltip: string;
    public isEditable: boolean;
    public type: TimeCategoryType;
    public UiType: TimeCategoryUIType;
    public totalHours: string;
    public hasError: boolean;
    public errorType: string;    
    private _code: string;
    public isHyperlink: boolean;
    public typeWS: string;

    public get code(): string {
        return this._code;
    }

    public set code(value: string) {
        switch (value[0].toUpperCase()) {
            case "O":
                this.type = TimeCategoryType.OvertimeCategory;
                break;
            case "R":
                this.type = TimeCategoryType.RegularTimeCategory;
                break;
            case "W":
                this.type = TimeCategoryType.WorkScheduleCategory;
                break;
            case "P":
                this.type = TimeCategoryType.PunchClockCategory;
                break;
            case "A":
                this.type = TimeCategoryType.AhgoraPunchClockCategory;
                break;
            case "S":
                this.type = TimeCategoryType.ShiftScheduleCategory;
                break;  
                
            case "H":
                this.type = TimeCategoryType.RestHoursCategory;
                break;
            default:
                this.type = TimeCategoryType.RegularTimeCategory;
                console.warn(`Unable to identify the Time Category Type ${value}`);
                break;
        }

        this._code = value;
    }
}
