import { Injectable } from '@angular/core';
import { AuditCommunicationsLogOutput } from '@shared/clients/expense-api-client';
import { ColDef, GridApi, GridOptions, RowNode } from 'ag-grid-community';
@Injectable({
    providedIn: 'root'
  })
export class AuditLogGridService{
    constructor(){    
    }
    public getDefaultGridOptions(): GridOptions<any> {
        return {
          rowHeight: 40,
          headerHeight: 50,
          suppressMenuHide: true,
          pagination: false,
          suppressRowClickSelection: true,
          suppressMovableColumns: true,
          stopEditingWhenCellsLoseFocus: true,
          enableBrowserTooltips: true,
          suppressAnimationFrame: true,
          domLayout: 'autoHeight',
          popupParent: document.querySelector('body'),
          defaultColDef: this.getDefaultColumnDefinitions(),
          columnDefs: this.getColumnDefinitions(),
          getRowClass:null
        };
      }
      public updateDataSource(gridApi: GridApi<AuditCommunicationsLogOutput>, commentsList: any): void {
        gridApi.setGridOption('rowData', commentsList);
        gridApi.setGridOption('columnDefs', this.getColumnDefinitions());
        if (!commentsList.length) {
          gridApi.showNoRowsOverlay();
        }
        gridApi.sizeColumnsToFit();
        gridApi.refreshHeader();
        gridApi.forEachNode((node: RowNode<any>) => {
          if (node) {
            node.setSelected(false);
          }
        });
      }
    
      private getDefaultColumnDefinitions(): ColDef<AuditCommunicationsLogOutput> {
        return {
          sortable: false,
          resizable: true,
          suppressAutoSize: true,
          wrapHeaderText: true,
          filter:false
        };
      }
      public getColumnDefinitions(): ColDef<AuditCommunicationsLogOutput>[] {
        const columnDefs: ColDef<AuditCommunicationsLogOutput>[] = [];
        columnDefs.push(
        {
          headerName: 'Date/Time',
          field: 'actionDateTime',
          editable: false,
          cellClass: 'text-left',
          cellStyle:{'font-weight': '800'},
          width: 150,
          minWidth: 100,
          sortable: true
        },
        {
          headerName: 'Enterprise ID',
          field: 'activeEnterpriseId',
          editable: false,
          width:150,
          minWidth: 100,
          cellClass: 'text-left',
          cellStyle:{'font-weight': '800'},
        },
        {
          headerName: 'Comments',
          field: 'comment',
          editable: false,
          cellClass: 'text-left',
          width:250,
          minWidth: 250,
          cellStyle:{'font-weight': '800'},
          wrapText: true,
          autoHeight: true
        }
       );
        return columnDefs;
      }
}