import { Injectable, OnDestroy } from "@angular/core";
import { environmentsUrls } from "../../../../assets/data/app-config/environmentsUrls.constants";
import { UserService } from "../user/user.service";
import { GlobalCacheService } from '../../../shared/services/cache/global-cache.service';
import { PageGuardEvaluationService } from "@shared/services/page-guard-evaluation/page-guard-evaluation.service";
import { UniversalHeader } from "@shared/constant";
import { of, Subscription } from "rxjs";
import ActionMenu from '../../../shared/models/action-menu';
import { AppConfigService } from "@authServices/app-config.service";
import { UserRole } from "@shared/models/user/user-roles";

@Injectable({
  providedIn: 'root'
})
export class OverwriteUniversalHeaderHyperlinksService implements OnDestroy{
  private targetElement: HTMLElement;
  private currentHost: string;
  private currentEnvironmentUrls: any;
  private isEvaluating: boolean = false;
  private userActionMenuSubscription: Subscription;
  private appConfigData: any;

  constructor(private userService: UserService,
    private evaluationService: PageGuardEvaluationService,
    private globalCacheService: GlobalCacheService,
    private appConfigService: AppConfigService) {
    this.currentHost = window.location.host;
    this.currentEnvironmentUrls = environmentsUrls.stages.find(stage => stage.nextUri == this.currentHost);
    this.appConfigData = this.appConfigService.getAppConfig;
  }

  public loadScript(url: string, isStage: boolean, id?: string) {
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      if (id != null && id != undefined) script.id = id;
      script.src = url;
      script.async = false;
      script.defer = true;
      script.onload = () => {
        resolve({ script: name, loaded: true, status: 'Loaded' });
        var universalHeader = document.getElementById('acn-universal-header');
        if (universalHeader != null) {
          universalHeader.addEventListener('acn_header_loaded', (event) => {
            if (isStage) {
              this.replaceAHrefsForElement(UniversalHeader.Selectors.HEADER);
            }
            this.hideActionButtonsForElement();
          });
        }
      };
      script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
      document.getElementsByTagName('script')[0].appendChild(script);
    });
  }

  private replaceAHrefsForElement(targetElementSelector: string) {
    setTimeout(() => {
      this.replaceAHrefs(targetElementSelector);
    }, 4000);
  }

  public hideActionButtonsForElement() {
    setTimeout(() => {
      this.hideActionButtons(UniversalHeader.Selectors.ACTION_BUTTONS);
    }, 4000);
  }

  private replaceAHrefs(targetElementSelector: string) {
    this.targetElement = document.querySelector(targetElementSelector);
    if (this.targetElement) {
      if (this.currentEnvironmentUrls) {
        const index = environmentsUrls.stages.indexOf(this.currentEnvironmentUrls);
        environmentsUrls.stages.splice(index, 1);
        if (this.targetElement == null) return;
        let anchors = this.targetElement.querySelectorAll('a');
        anchors.forEach(anchor => {
          environmentsUrls.stages.forEach((stage) => {
            if (anchor.host === stage.nextUri) {
              anchor.host = this.currentEnvironmentUrls.nextUri;
            } else if (anchor.host === stage.legacyUri) {
              anchor.host = this.currentEnvironmentUrls.legacyUri;
            }
          });
        });
      }
    }
  }

  private hideActionButtons(targetElementSelector: string) {
    if (!this.isEvaluating) {
      this.targetElement = document.querySelector(targetElementSelector);
      if (this.targetElement) {
        this.isEvaluating = true;
        let actionMenuCache = null;
        this.userActionMenuSubscription = this.globalCacheService
          .getActionMenu()
          .subscribe((res) => {
            actionMenuCache = res;
            if (!actionMenuCache) {
              this.userService.getUserActionMenu().subscribe({
                next: (response) => {
                  this.getActionMenu(response);
                  this.globalCacheService.setActionMenu(of(response));
                },
                error: () => {
                  this.isEvaluating = false;
                }
              });
              }
              else {
                this.getActionMenu(actionMenuCache);
              }
          });
      }
    }
  }

  private getActionMenu(actionMenuCache : any){
    let anchors = this.targetElement.querySelectorAll('a');
    anchors.forEach(anchor => {
      for (let key in UniversalHeader.GridButtons) {
        if (anchor.href.includes(UniversalHeader.GridButtons[key])) {
          if (this.shouldHideUHLinks(UniversalHeader.GridButtons[key], actionMenuCache)) {
            anchor.style.setProperty('display', 'none', 'important')
          } else {
            anchor.style.setProperty('display', '')
          }
        }
      }
    });
    this.isEvaluating = false;
  }

  /**
   * show only global approvers link in the UH menu
   * @param keyButton 
   * @param userActionMenu 
   * @returns 
   */
  shouldHideUHLinks(keyButton: string, userActionMenu: any) {
    switch (keyButton) {
      case UniversalHeader.GridButtons.REQUESTOVERTIME:
        return !userActionMenu?.request && !userActionMenu?.isHolidayHoursRequestor
      case UniversalHeader.GridButtons.GLOBALAPPROVAL:
        return !userActionMenu?.isGlobalApprover
      case UniversalHeader.GridButtons.APPROVALALLOWANCEANDOVERTIME:
      case UniversalHeader.GridButtons.APPROVALOVERTIMEANDALLOWANCE:
      case UniversalHeader.GridButtons.TIMECATEGORYREQUESTAPPROVAL:
        return true
    }
  }

  ngOnDestroy(): void {
    if(this.userActionMenuSubscription){
      this.userActionMenuSubscription.unsubscribe();
    } 
  }

  public overwriteUH() {
    let personElements = document.getElementsByClassName('acn-header-profile');

    if (this.isElementExist(personElements)) {
      this.setProfileEvent(personElements);
    }
    let actionMenuCache = null;
    this.userActionMenuSubscription = this.globalCacheService
      .getActionMenu()
      .subscribe((res) => {
        actionMenuCache = res;
        if (!actionMenuCache) {
          this.userService.getUserActionMenu()
          .subscribe(res => {
            if (typeof res == 'undefined') return;

            this.overwriteActionMenu(res);
            this.globalCacheService.setActionMenu(of(res));
            this.globalCacheService.setUserRole(UserRole.getValueByString(res.userRole));
          });
          }
          else {
            this.overwriteActionMenu(actionMenuCache);
            this.globalCacheService.setUserRole(UserRole.getValueByString(actionMenuCache.userRole));
          }
      });
  }

  private isElementExist(ele) {
    return typeof ele !== 'undefined' &&
      ele &&
      ele.length > 0;
  } 

  private setProfileEvent(personElements) {
    (personElements[0] as any).removeAllListeners();
    personElements[0].addEventListener('click', function() {
      var url = (this).getAttribute("data-url");
      window.open(url);
    });
  }
  
  private overwriteActionMenu(res: ActionMenu): void {
    try {
      let menuElements = document.getElementsByClassName('acn-header-settings-dd')[0].getElementsByTagName('A');
      let arrayElements: [] = Array.prototype.slice.call(menuElements).filter(x => !x.innerHTML.includes('Expense Api Version'));

      if (
        typeof arrayElements !== 'undefined' &&
        arrayElements &&
        arrayElements.length > 0
      ) {
        arrayElements.forEach(element => {
          const actionDom = element as HTMLElement;

          if (res[actionDom.innerHTML.toLowerCase()] === false) {
            actionDom.setAttribute('hidden', 'hidden');
          }

          if(this.appConfigData.AdditionalConfig.enableSubordinateModes && (
            actionDom.innerHTML === "Review"
            || actionDom.innerHTML === "Approve"
            || actionDom.innerHTML === "Represent"
          )) {
            actionDom.setAttribute('hidden', 'hidden');
          }

          if (actionDom.innerHTML === 'Audit') {
            if (res.userRole === 'AuditLead') {
              let auditUrl = actionDom.getAttribute('href');
              actionDom.setAttribute('href', auditUrl.replace('PostPayAudit', 'MyEscalatedExpenses'));
            };
          }

          switch(actionDom.innerHTML)
          {
            case 'Record': actionDom.setAttribute('title', 'Record your Time and Expenses');break;
            case 'Review': actionDom.setAttribute('title', 'Review time and expenses reports');break;
            case 'Approve': actionDom.setAttribute('title', 'Approve time and expenses reports');break;
            case 'Report': actionDom.setAttribute('title', 'Access Reports');break;
            case 'Represent': actionDom.setAttribute('title', 'Record time and expenses for another person');break;
            case 'Audit': actionDom.setAttribute('title', 'Audit Time and Expense Reports');break;
            case 'Authorize': actionDom.setAttribute('title', 'Authorize employees to use a charge code');break;
            case 'Admin': actionDom.setAttribute('title', 'Perform system administration');break;
            case 'Log Out': actionDom.setAttribute('title', 'Log Out');break;
          }
        });
      }
    } catch (error) {
      console.error("Unable to overwrite Action Menu.");
      console.error(error);
    }
  }
}