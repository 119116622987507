import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { ButtonsId } from '@sharedUtils/expensesElementsId';
import { GridApi, GridOptions } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class FocusService {
  private lastSelectedCell: Cell = null;
  private savedElementId = null;
  private allHiddenButtonsId = [ ButtonsId.SplitButton, ButtonsId.CreateTripButton ];
  public searchSubject = new BehaviorSubject<any>("");

  constructor() { }
  
  public focusLastSelectedCell() {
    this.lastSelectedCell?.focus();
  }

  public focusSavedElementId() {
    if(!this.savedElementId) return;

    if(this.allHiddenButtonsId?.includes(this.savedElementId))
      document.getElementById('cell' + (this.lastSelectedCell.rowIndex + 1))?.focus();
    else
      document.getElementById(this.savedElementId)?.focus();

    this.savedElementId = null;
  }

  public setSavedElementId(id) {
    this.savedElementId = id;
  }

  public getSavedElementId() {
    return this.savedElementId;
  }

  public updateLastSelectedCell(gridOptions, columnIndex = 0, rowIndex = null) {
    let node = gridOptions?.api?.getSelectedNodes()[0];
    this.lastSelectedCell = new Cell(rowIndex !== null ? rowIndex : node?.rowIndex, columnIndex, gridOptions);
  }

  public getLastSelectedCell = () => this.lastSelectedCell;

  public clearLastSelectedCell = () => this.lastSelectedCell = null;

  public static getColumnIndexByColId = (gridAPI: GridApi, colId: string) => gridAPI?.getColumns()?.findIndex(col => col.getColId() === colId);

  public static getRowIndexByExpenseId = (expenseGridApi: GridApi, expenseId: string) => {
    for(let i = 0; i < expenseGridApi?.getDisplayedRowCount(); i++) {
      if (expenseGridApi.getDisplayedRowAtIndex(i)?.data?.id === expenseId) {
        return i;
      }
    }
  }

  public focusCell(rowIndex: number, columnIndex: number, gridApi: GridApi): void {
    //The grid starts on 0:0 on the top left corner
    gridApi?.clearFocusedCell();
    let column = gridApi?.getAllDisplayedColumns()[columnIndex];
    gridApi?.ensureIndexVisible(rowIndex ?? 0);
    gridApi?.setFocusedCell(rowIndex, column);
  }

  public focusCellByCell(cell: Cell): void {
    if (!cell) return;
    cell?.focus();
  }

  public focusOnNavigationEndById(router, elementId) {
    router.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {
        FocusService.focusOnNgCheck(elementId);
      }
    });
  }

  public static focusOnNgCheck(elementId) {
    let elementBefore = document.getElementById(elementId);
    elementBefore?.blur();

    setTimeout(() => {
      let elementAfter = document.getElementById(elementId);
      elementAfter?.focus();
    }, 0);
  }

  public static redirectFocus(from, to) {
    from?.addEventListener(('focus'), () => {
      if (typeof(to) === typeof(''))
        document.getElementById(to)?.focus();
      else
        to?.focus();
    });
  }
}

class Cell {
  rowIndex: 0;
  colIndex: 0;
  gridOptions: any;

  constructor(rowIndex, colIndex, gridOptions) {
    this.rowIndex = rowIndex;
    this.colIndex = colIndex;
    this.gridOptions = gridOptions;
  }

  public focus() {
    this?.gridOptions?.api?.clearFocusedCell();
    this.gridOptions?.api?.ensureIndexVisible(this.rowIndex);
    let col = this.gridOptions?.api?.columnController?.getAllDisplayedColumns()[this.colIndex];
    this.gridOptions?.api?.setFocusedCell(this.rowIndex, col);
  }
}

