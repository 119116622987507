<div class="taxiDateRender">
  <myte-date-picker
    #fromDate
    [id]="'fromDate_' + params.node.rowIndex"
    class="controls-width"
    (onDateChange)="updateValue($event)"
    [value]="inputData.dateFormat.hiddenValue"
    [isDisplayTooltip]="false"
    [isValid]="inputData.dateFormat.isValid"
    [validationExpressions]="validationExpressions"
    [ariaLabel]="'Insert From Date'">
  </myte-date-picker>
</div>