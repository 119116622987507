import { SubmitTimeReportOutput } from "@sharedClients/timereport-api-client";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Receipt } from "@sharedModels/expense/receipt/expense-receipt";
import {
    BaseCacheService,
    CacheItem,
    LocalStorageCacheItem,
} from "./base-cache.service";
import Expense from "@sharedModels/expense/expense";
import TimeReport from "@sharedModels/time-report/time-report";
import ExpenseView from "@sharedModels/expense/expense-view";
import ExpenseTripName from "@sharedModels/expense/trip/expense-trip-name";
import * as moment from "moment";
import {
    Country,
    LocationEntry,
    LocationOnes,
    LocationTwo,
    LocationsByDay,
} from "@sharedModels/locations";
import UserPreference from "@shared/models/userPreference";
import { ChargeCodeRelated } from "@sharedModels/charge-code/charge-code-related";
import TimeSheet from "@sharedModels/time-sheet/time-sheet";
import AuditInquiry from "@sharedModels/expense/audit/expense-audit-inquiry";
import TimeReportSettings from "@sharedModels/time-report/time-report-settings";
import { TaxReimbursementRateDto } from "@sharedClients/timereport-api-client";
import { ShiftScheduleOption } from "@sharedModels/time-sheet/shift-schedule/shift-schedule-option";
import { SubordinatesMode } from "@sharedModels/subordinates/subordinate-mode";
import { UserRole } from "@sharedModels/user/user-roles";
import Subordinate from "@sharedModels/subordinates/subordinate";
import { TimePeriodPipe } from "@sharedPipes/time-period.pipe";
import { TimeReportSummary } from "@shared/models/summary/time-report-summary/time-report-summary.model";
import { PreferencesPopupList } from "@sharedModels/preferences-list";
import { map } from "rxjs/operators";
import { AnnualChargeability } from "@shared/models/summary/annual-chargeability/annual-chargeability.model";
import { TimeReportStatusCode } from "@sharedModels/time-report/time-report-status-code";
import { WorkLocationDayInfo } from "@sharedClients/locations-api-client";
import ActionMenu from "@sharedModels/action-menu";
import { ActivityOutPut } from "@shared/models/activity/activity-output/activity-output";
import { ActivityOutputItem } from "@shared/models/activity/activity-output/activity-output-item";
import { ChargeCodes } from "@shared/models/charge-code/charge-codes.model";
import { DataStore } from "../../../app-shell/data-store";
import { Adjustments } from "@shared/models/adjustments/adjustments";
import { CarInformations } from "@shared/models/car-information/car-informations.model";
import { ExpensesResponse } from "../store/expense-store.service";
import { TaxReimbursementRates } from "@shared/models/compensatory/tax-reimbursement-rates.model";
import { LocationQuestionnaires } from "@shared/models/location-questionnaire/location-questionnaires.model";
import { MyteTabControl } from "@shared/models/myte-tab-control.model";
import DropdownItem from "@shared/models/controls/dropdown/dropdown-item";

const DEFAULT_EXPIRATION_LOCAL_STORAGE = 60;
const USER_CONFIG_KEY_NAME = "ZxTT3emTLC";
const CURRENT_PERIOD_END_KEY = "Pzss68FGk";
const AMEX_EXPENSES_KEY = "xTT3eNmhhA";
const SHOW_EXPENSE_ISSUES = "Ry4KKKYpAU";
const TRIP_GROUP_KEY = "weeII990";
const LOCATIONS_COUNTRIES = "ieM2lbLmYk";
const SUBORDINATE_MODE_KEY = "yV4vxbbS9Y";
const SUBORDINATE_MODE_SDL_JEY = "tKgen65IG";
const SUBORDINATE_KEY = "bS9zYss62l";
const USER_ROLE_KEY = "z28mt37gr1";
const ANNUALCHARGEABILITY_KEY = "z48mt33gr2";
const USER_COUNTRY_KEY = "D49Rt17gR8";
const COMPANY_CODE_KEY = "h4D19f8tk1";
const REVIEWEE_KEY = "cBeY9l4hV9";
const EMAIL_DATE_KEY = "cBeY7l4hV6";
const REVIEWEE_NAME_KEY = "cBeY6l6hV6";
const MYTE_THEME_KEY = "p5L13j1mN9";
const SEND_FOR_APPROVAL_KEY = "cBeY8l3hV8";
const GET_USER_ACTION_MUEN_KEY = "Asd789fghJ";
const SUBORDINATED_CATEGORY_STATUS_FILTER_SELECTED_KEY = "x3L14s8wa1";
const FRANCE_COMPANY_CODES = ["3500", "3501", "3507", "3541", "3503", "3543"];
const COMPANY_CODE_LIST_FOR_MANDATORY_LOCATION_BY_DAY_EXCEPTION = ["8100"];
const COUNTRY_KEY_LIST_FOR_MANDATORY_LOCATION_BY_DAY_EXCEPTION = ["IN"];
const PDL_ERROR_KEY = '11111';
const IS_AVANADE_USER = 'us123acc';
const PRIVACY_STATEMENT_KEY = 'aMfO268sP9'
const ACTIVITY_ROWMMODEL_KEY = 'h0NA23s7O'
const AUDITEEJOININGDATE_KEY = 'uioehde';


@Injectable({
    providedIn: "root",
})
export class GlobalCacheService extends BaseCacheService {
    private expenseListBuffer: CacheItem<Date, ExpensesResponse>[] = [];
    private timeReportSettingsBuffer: CacheItem<Date, TimeReportSettings>[] = [];
    private timeReportsBuffer: CacheItem<Date, TimeReport>[] = [];
    private amexExpenseList: CacheItem<string, any>[] = [];
    private tripNameListBuffer: CacheItem<string, ExpenseTripName[]>[] = [];
    private chargeCodeListBuffer: CacheItem<Date, ChargeCodes>[] = [];
    private chargeCodeListReadyForSave: CacheItem<Date, any>[] = [];
    private locationsCountriesBuffer: CacheItem<string, Country[]>[] = [];
    private locationOnesBuffer: CacheItem<string, LocationOnes>[] = [];
    private locationTwoBuffer: CacheItem<string, LocationTwo>[] = [];
    private userPreferencesBuffer: CacheItem<string, UserPreference>[] = [];
    private saveUserPreferencesInputBuffer: CacheItem<
        string,
        PreferencesPopupList
    >[] = [];
    private clientListBuffer: CacheItem<Date, any>[] = [];
    private projectDescendantBuffer: CacheItem<string, ChargeCodeRelated[]>[] =
        [];
    private timeSheetBuffer: CacheItem<Date, TimeSheet>[] = [];
    private expensesIssuesBuffer: CacheItem<string, AuditInquiry[]>[] = [];
    private carInformationBuffer: CacheItem<string, CarInformations>[] = [];
    private taxReimbursementRateBuffer: CacheItem<
        string,
        TaxReimbursementRates
    >[] = [];
    private locationQuestionnaireBuffer: CacheItem<
        string,
        LocationQuestionnaires
    >[] = [];
    private locationsByDayBuffer: CacheItem<Date, LocationsByDay>[] = [];
    private shiftOptionsBuffer: CacheItem<number, ShiftScheduleOption[]>[] = [];
    private dictionaryTabsBuffer: CacheItem<Date, any>[] = [];
    private subordinatedList: CacheItem<SubordinatesMode, Subordinate[]>[] = [];
    private workLocationList: CacheItem<string, WorkLocationDayInfo[]>[] = [];
    private timeReportSummaryBuffer: CacheItem<string, TimeReportSummary>[] = [];
    private annualChargeabilityBuffer: CacheItem<string, AnnualChargeability>[] =
        [];
    private softWarningAfterSubmissionBuffer: CacheItem<Date, string[]>[] = [];
    private expenseTotalErrorBuffer: CacheItem<string, string>[] = [];
    private selectedCellFromExpensesGrid: CacheItem<string, number>[] = [];
    private locationEntryBuffer: CacheItem<Date, LocationEntry>[] = [];
    private actionLocationControl: CacheItem<Date, string>[] = [];
    private actionMenuBuffer: CacheItem<Date, ActionMenu>[] = [];
    private LBDNASpecialWbsValidationsBuffer: CacheItem<string, any>[] = [];
    private activitiesBuffer: CacheItem<Date, any>[] = [];
    private activitiesRowBuffer: CacheItem<Date, ActivityOutputItem>[] = [];
    private myteTabControlBuffer: CacheItem<string, MyteTabControl>[] = [];
    public isESPunchClock: boolean = false;

    public chargeCodesStorage: DataStore<ChargeCodes>;
    public adjustmentStorage: DataStore<Adjustments>;
    public carInformationStorage: DataStore<CarInformations>;
    public taxReimbursementRateStorage: DataStore<TaxReimbursementRates>;
    public locationQuestionnaireStorage: DataStore<LocationQuestionnaires>;
    public timeReportSummaryStorage: DataStore<TimeReportSummary>;
    public hasOpenIssues = new BehaviorSubject(false);
    public ExpenseId: string;
    public selectedItem: DropdownItem;

  private subordinatesMode: BehaviorSubject<SubordinatesMode> =
    new BehaviorSubject(SubordinatesMode.None);
  public readonly subordinatesMode$: Observable<SubordinatesMode> =
    this.subordinatesMode.asObservable();
  public auditInfoKey = "Jl45uKcAn"; //Key for the row selected by auditor
  public auditeeInfoKey = "HilihUuS"; //key for Auditee details in side bar
  public auditLeadSession = "Si4FJ9ed2h"; //key to determine Audit Lead session
  private refreshAuditStatusBeSubject = new BehaviorSubject(false);
  private auditStatusChangeBeSubject = new BehaviorSubject<boolean>(false);
  private CompleteAuditStatusChangeBeSubject = new BehaviorSubject<boolean>(false);
  public auditLogInfoKey = "Lrfoh2Kq"; // Key to determine audit log session
  public currentPeriodEnd = "gyet2IKa"; // Key to store current period end date
  public timeCategoryCode;
  public expenseEditableForAudit = "rep34kfdl"; // key to determine if expense is editable
  public selectedGlobalApproverCountryKey = "RhbZtPlCc"; // key to set global approver selected country

    public resetDataStorage(): void {
        // Data Storage cache handles Observable and Data Shell data for current active period
        if (this.chargeCodesStorage) this.chargeCodesStorage.refresh = true;
        if (this.adjustmentStorage) this.adjustmentStorage.refresh = true;
        if (this.carInformationStorage) this.carInformationStorage.refresh = true;
        if (this.taxReimbursementRateStorage)
            this.taxReimbursementRateStorage.refresh = true;
        if (this.locationQuestionnaireStorage)
            this.locationQuestionnaireStorage.refresh = true;
        if (this.timeReportSummaryStorage)
            this.timeReportSummaryStorage.refresh = true;
    }

    public handleChargeCodes(getChargeCodesObservable: Observable<ChargeCodes>, enterpriseId: string, periodEnd: Date): Observable<ChargeCodes> {
        const bufferCache: CacheItem<Date, ChargeCodes> = this.isInBuffer(this.chargeCodeListBuffer, periodEnd);
        if (!bufferCache)
            return this.saveInBuffer(this.chargeCodeListBuffer, periodEnd, getChargeCodesObservable).data;
        return bufferCache.data;
    }
    public getIssueStatus(): Observable<boolean> {
        return this.hasOpenIssues.asObservable();
    }
    public setIssueStatus(value: boolean) {
        return this.hasOpenIssues.next(value);
    }

  public resetChargeCodes(): void {
    this.chargeCodeListBuffer = [];
  }

  public setTimeCategoryCode(value: string): void {
    this.timeCategoryCode = value;
  }
  public getTimeCategoryCode(): string {
    return this.timeCategoryCode;
  }

    public handleUserPreferences(enterpriseId: string, periodEnd: Date, userPreference: Observable<UserPreference>): Observable<UserPreference> {
        const bufferCache: CacheItem<string, UserPreference> = this.isInBuffer(this.userPreferencesBuffer, periodEnd.toLocaleDateString() + enterpriseId);
        if (!bufferCache)
            return this.saveInBuffer(
                this.userPreferencesBuffer,
                periodEnd.toLocaleDateString() + enterpriseId,
                userPreference
            ).data;
        return bufferCache.data;
    }

    public resetUserPreferences(): void {
        this.userPreferencesBuffer = [];
    }

    public handleCarInformation(getCarInformationObservable: Observable<CarInformations>, periodEnd: Date, enterpriseId: string): Observable<CarInformations> {
        const bufferCache: CacheItem<string, CarInformations> = this.isInBuffer(
            this.carInformationBuffer,
            periodEnd.toLocaleDateString() + enterpriseId
        );
        if (!bufferCache)
            return this.saveInBuffer(
                this.carInformationBuffer,
                periodEnd.toLocaleDateString() + enterpriseId,
                getCarInformationObservable
            ).data;
        return bufferCache.data;
    }

    public resetCarInformation(): void {
        this.carInformationBuffer = [];
    }

    public handleTaxReimbursementRate(getTaxReimbursementRateObservable: Observable<TaxReimbursementRates>, enterpriseId: string): Observable<TaxReimbursementRates> {
        const bufferCache: CacheItem<string, TaxReimbursementRates> =
            this.isInBuffer(this.taxReimbursementRateBuffer, enterpriseId);
        if (!bufferCache)
            return this.saveInBuffer(
                this.taxReimbursementRateBuffer,
                enterpriseId,
                getTaxReimbursementRateObservable
            ).data;
        return bufferCache.data;
    }

    public resetTaxReimbursementRate(): void {
        this.taxReimbursementRateBuffer = [];
    }

    public handleLocationOnes(getLocationOneObservable: Observable<LocationOnes>, periodEnd: Date, countryCode: string): Observable<LocationOnes> {
        const bufferCache: CacheItem<string, LocationOnes> = this.isInBuffer(
            this.locationOnesBuffer,
            periodEnd.toLocaleDateString() + countryCode
        );
        if (!bufferCache)
            return this.saveInBuffer(
                this.locationOnesBuffer,
                periodEnd.toLocaleDateString() + countryCode,
                getLocationOneObservable
            ).data;
        return bufferCache.data;
    }

    public resetLocationOnes(): void {
        this.locationOnesBuffer = [];
    }

    public handleLocationTwo(getLocationTwoObservable: Observable<LocationTwo>, periodEnd: Date, countryCode: string, locationOneCode: string): Observable<LocationTwo> {
        const bufferCache: CacheItem<string, LocationTwo> = this.isInBuffer(
            this.locationTwoBuffer,
            periodEnd.toLocaleDateString() + countryCode + locationOneCode
        );
        if (!bufferCache)
            return this.saveInBuffer(
                this.locationTwoBuffer,
                periodEnd.toLocaleDateString() + countryCode + locationOneCode,
                getLocationTwoObservable
            ).data;
        return bufferCache.data;
    }

    public resetLocationTwo(): void {
        this.locationTwoBuffer = [];
    }

    public handleLocationQuestionnaire(getLocationQuestionnaireObservable: Observable<LocationQuestionnaires>, periodEnd: Date, enterpriseId: string ): Observable<LocationQuestionnaires> {
        const bufferCache: CacheItem<string, LocationQuestionnaires> =
            this.isInBuffer(
                this.locationQuestionnaireBuffer,
                periodEnd.toLocaleDateString() + enterpriseId
            );
        if (!bufferCache)
            return this.saveInBuffer(
                this.locationQuestionnaireBuffer,
                periodEnd.toLocaleDateString() + enterpriseId,
                getLocationQuestionnaireObservable
            ).data;
        return bufferCache.data;
    }

    public resetLocationQuestionnaire(): void {
        this.locationQuestionnaireBuffer = [];
    }

    public handleTimeReportSummary(getTimeReportSummaryObservable: Observable<TimeReportSummary>, periodEnd: Date, enterpriseId: string): Observable<TimeReportSummary> {
        const bufferCache: CacheItem<string, TimeReportSummary> = this.isInBuffer(this.timeReportSummaryBuffer, periodEnd.toLocaleDateString() + enterpriseId);
        if (!bufferCache)
            return this.saveInBuffer(
                this.timeReportSummaryBuffer,
                periodEnd.toLocaleDateString() + enterpriseId,
                getTimeReportSummaryObservable
            ).data;
        return bufferCache.data;
    }

    public clearTimeReportSummary(periodEnd: Date, enterpriseId: string): void {
        const bufferCache: CacheItem<string, TimeReportSummary> = this.isInBuffer(
            this.timeReportSummaryBuffer,
            periodEnd.toLocaleDateString() + enterpriseId
        );
        if (bufferCache) {
            this.resetBufferByKey(
                this.timeReportSummaryBuffer,
                periodEnd.toLocaleDateString() + enterpriseId
            );
            if (this.timeReportSummaryStorage)
                this.timeReportSummaryStorage.refresh = true;
        }
    }

    public resetTimeReportSummary(): void {
        this.timeReportSummaryBuffer = [];
    }

    public handleAnnualChargeabilitySummary(getAnnualChargeabilitySummaryObservable: Observable<AnnualChargeability>, periodEnd: Date, enterpriseId: string): Observable<AnnualChargeability> {
        const bufferCache: CacheItem<string, AnnualChargeability> = this.isInBuffer(this.annualChargeabilityBuffer, periodEnd.toLocaleDateString() + enterpriseId);
        if (!bufferCache)
            return this.saveInBuffer(this.annualChargeabilityBuffer, periodEnd.toLocaleDateString() + enterpriseId, getAnnualChargeabilitySummaryObservable).data;
        return bufferCache.data;
    }

    public resetAnnualChargeabilitySummary(): void {
        this.annualChargeabilityBuffer = [];
    }

    public handleMyteTabControl(getSubordinateTabPermissionsObservable: Observable<MyteTabControl>, enterpriseId: string): Observable<MyteTabControl> {
        const bufferCache: CacheItem<string, MyteTabControl> = this.isInBuffer(this.myteTabControlBuffer, enterpriseId);
        if (!bufferCache)
            return this.saveInBuffer(this.myteTabControlBuffer, enterpriseId, getSubordinateTabPermissionsObservable).data;
        return bufferCache.data;
    }

    public resetMyteTabControl(): void {
        this.myteTabControlBuffer = [];
    }

    public getPeriodEnd(): Date {
        const periodEndCache = this.isInSessionStorage<Date>(
            CURRENT_PERIOD_END_KEY
        );
        return periodEndCache ? moment(periodEndCache, "M-D-YYYY").toDate() : null;
    }

    public setPeriodEnd(periodEnd: Date): void {
        const periodEndCache = this.isInSessionStorage<Date>(
            CURRENT_PERIOD_END_KEY
        );
        if (periodEndCache) {
            if (Object.is(periodEndCache, periodEnd)) {
                return;
            } else {
                this.resetSessionStorageByKey(CURRENT_PERIOD_END_KEY);
            }
        }

        this.saveInSessionStorage(
            CURRENT_PERIOD_END_KEY,
            new TimePeriodPipe().transform(periodEnd)
        );
    }

    public getUserCountry(): string {
        const varCache = this.isInSessionStorage<string>(USER_COUNTRY_KEY);
        return varCache ? varCache : null;
    }

    public setUserCountry(countrykey: string): void {
        const countrykeyCache = this.isInSessionStorage<string>(USER_COUNTRY_KEY);
        if (countrykeyCache) {
            if (Object.is(countrykeyCache, countrykey)) {
                return;
            } else {
                this.resetSessionStorageByKey(USER_COUNTRY_KEY);
            }
        }

        this.saveInSessionStorage(USER_COUNTRY_KEY, countrykey);
    }

    public getUserCompanyCode(): string {
        const varCache = this.isInSessionStorage<string>(COMPANY_CODE_KEY);
        return varCache ? varCache : null;
    }
    public getUserFranceValidator(): boolean {
        return (
            this.getUserCountry() == "FR" &&
            FRANCE_COMPANY_CODES.indexOf(this.getUserCompanyCode()) > -1
        );
    }
    public setUserCompanyCode(companycdkey: string): void {
        const companyCdCache = this.isInSessionStorage<string>(COMPANY_CODE_KEY);
        if (companyCdCache) {
            if (Object.is(companyCdCache, companycdkey)) {
                return;
            } else {
                this.resetSessionStorageByKey(COMPANY_CODE_KEY);
            }
        }

        this.saveInSessionStorage(COMPANY_CODE_KEY, companycdkey);
    }

    public handleExpenseList(periodEnd: Date, countryKey: string, requestExpenses: Observable<ExpensesResponse>): Observable<ExpensesResponse> {
        let cacheInPeriod;
        if (countryKey == null) {
            cacheInPeriod = this.isInBuffer(this.expenseListBuffer, periodEnd);
        } else {
            cacheInPeriod = this.isInBuffer(this.expenseListBuffer, periodEnd.toLocaleDateString() + countryKey);
        }
        if (!cacheInPeriod) {
            if (countryKey == null) {
                return this.saveInBuffer(this.expenseListBuffer, periodEnd, requestExpenses).data;
            } else {
                return this.saveInBuffer(this.expenseListBuffer, periodEnd.toLocaleDateString() + countryKey, requestExpenses).data;
            }
        } else {
            return cacheInPeriod.data;
        }
    }

    public setRevieweeEid(reviewee: string): void {
        this.saveInLocalStorage(REVIEWEE_KEY, {
            data: reviewee,
            expirationDate: new Date(
                new Date().getTime() + DEFAULT_EXPIRATION_LOCAL_STORAGE * 60000
            ),
        });
    }

    public getRevieweeEid(): string {
        let buffer = this.isInLocalStorage<string>(REVIEWEE_KEY);
        return buffer != null ? buffer.data : "";
    }

    public setRevieweeName(reviewee: string): void {
        this.saveInLocalStorage(REVIEWEE_NAME_KEY, {
            data: reviewee,
            expirationDate: new Date(
                new Date().getTime() + DEFAULT_EXPIRATION_LOCAL_STORAGE * 60000
            ),
        });
    }

    public getRevieweeName(): string {
        let buffer = this.isInLocalStorage<string>(REVIEWEE_NAME_KEY);
        return buffer != null ? buffer.data : "";
    }

    public setEmailDate(reviewee: string): void {
        this.saveInLocalStorage(EMAIL_DATE_KEY, {
            data: reviewee,
            expirationDate: new Date(
                new Date().getTime() + DEFAULT_EXPIRATION_LOCAL_STORAGE * 60000
            ),
        });
    }

    public getEmailDate(): string {
        let buffer = this.isInLocalStorage<string>(EMAIL_DATE_KEY);
        return buffer != null ? buffer.data : "";
    }

    public clearBufferCacheForEmail(): void {
        localStorage.removeItem(EMAIL_DATE_KEY);
        localStorage.removeItem(REVIEWEE_KEY);
        localStorage.removeItem(REVIEWEE_NAME_KEY);
    }

    public clearExpenseList(periodEnd: Date, countryKey: string): void {
        if (countryKey == null) {
            return this.resetBufferByKey(this.expenseListBuffer, periodEnd);
        } else {
            return this.resetBufferByKey(
                this.expenseListBuffer,
                periodEnd.toLocaleDateString() + countryKey
            );
        }
    }

    public handleTripList(enterpriseId: string, countryKey: string, requestTripNameList: Observable<ExpenseTripName[]>): Observable<ExpenseTripName[]> {
        let hasCountryKey = countryKey ? enterpriseId + countryKey : enterpriseId;
        let cacheInPeriod = this.isInBuffer(this.tripNameListBuffer, hasCountryKey);
        if (!cacheInPeriod) {
            return this.saveInBuffer(
                this.tripNameListBuffer,
                hasCountryKey,
                requestTripNameList
            ).data;
        }
    }

    public handleSubordinatesList(mode: SubordinatesMode, isReview: boolean, periodEnd: Date, request: Observable<Subordinate[]>): Observable<Subordinate[]> {
        const key = `${mode}+${isReview}+${periodEnd.toISOStringMyTE()}`;
        let buffer = this.isInBuffer(this.subordinatedList, key);
        if (!buffer) {
            return this.saveInBuffer(this.subordinatedList, key, request).data;
        } else {
            return buffer.data;
        }
    }

    public resetSubordinatesList(): void {
        this.subordinatedList = [];
    }

    public getTripListBuffer(enterpriseId: string, countryKey: string): Observable<ExpenseTripName[]> {
        let hasCountryKey = countryKey ? enterpriseId + countryKey : enterpriseId;
        let cacheInPeriod = this.isInBuffer(this.tripNameListBuffer, hasCountryKey);
        return cacheInPeriod ? cacheInPeriod.data : null;
    }

    public resetTripBuffer(enterpriseId: string, countryKey: string): void {
        let hasCountryKey = countryKey ? enterpriseId + countryKey : enterpriseId;
        this.resetBufferByKey(this.tripNameListBuffer, hasCountryKey);
    }

    public handleTimeReportSettings(periodEnd: Date, countryKey: string, requestTimeReportSettings: Observable<TimeReportSettings>): Observable<TimeReportSettings> {
        let cacheInPeriod =
            countryKey == null
                ? this.isInBuffer(this.timeReportSettingsBuffer, periodEnd)
                : this.isInBuffer(this.timeReportSettingsBuffer, periodEnd.toLocaleDateString() + countryKey);

        if (!cacheInPeriod) {
            if (countryKey == null)
                return this.saveInBuffer(this.timeReportSettingsBuffer, periodEnd, requestTimeReportSettings).data;
            return this.saveInBuffer(this.timeReportSettingsBuffer, periodEnd.toLocaleDateString() + countryKey, requestTimeReportSettings).data;
        }
        return cacheInPeriod.data;
    }

    public resetTimeReportSettingsBuffer(periodEnd: Date, countryKey: string): void {
        this.resetBufferByKey(this.timeReportSettingsBuffer, periodEnd.toLocaleDateString() + countryKey);
    }

    public clearTimeReportSettingsBuffer(): void {
        this.timeReportSettingsBuffer = [];
    }

    public getTimeReport(periodEnd: Date): Observable<TimeReport> {
        let cacheInPeriod = this.isInBuffer(this.timeReportsBuffer, periodEnd);
        return cacheInPeriod?.data ? cacheInPeriod.data : of(null);
    }

    public getTimeReportSettings(periodEnd: Date, countryKey: string): Observable<TimeReportSettings> {
        let cacheInPeriod = countryKey == null
                                ? this.isInBuffer(this.timeReportSettingsBuffer, periodEnd)
                                : this.isInBuffer(this.timeReportSettingsBuffer, periodEnd.toLocaleDateString() + countryKey);
        return cacheInPeriod?.data ? cacheInPeriod.data : of(null);
    }

    public handleTimeReport(periodEnd: Date, requestReport: Observable<TimeReport> ): Observable<TimeReport> {
        let cacheInPeriod = this.isInBuffer(this.timeReportsBuffer, periodEnd);
        if (!cacheInPeriod) {
            return this.saveInBuffer(this.timeReportsBuffer, periodEnd, requestReport).data;
        } else {
            return cacheInPeriod.data;
        }
    }

    public getTimeReportStatus(periodEnd: Date): Observable<string> {
        let cacheInPeriod = this.isInBuffer(this.timeReportsBuffer, periodEnd);
        if (!cacheInPeriod) {
            return of(null);
        } else {
            return cacheInPeriod.data.pipe(
                map((timeReport) => {
                    return timeReport.status;
                })
            );
        }
    }

    public handleAmexExpenses(findAmexExpenseForAnyRequest: Observable<any>): Observable<any> {
        let cacheInPeriod = this.isInBuffer(this.amexExpenseList, AMEX_EXPENSES_KEY);
        if (!cacheInPeriod) {
            return this.saveInBuffer(this.amexExpenseList, AMEX_EXPENSES_KEY, findAmexExpenseForAnyRequest).data;
        } else {
            return cacheInPeriod.data;
        }
    }

    //public handleUserConfiguration(requestMetadata: Observable<UserConfiguration>, enterpriseId: string): Observable<UserConfiguration> {
    //let cacheData = this.isInLocalStorage<UserConfiguration>(USER_CONFIG_KEY_NAME+enterpriseId);
    //if (cacheData) {
    //return of(cacheData.data);
    //} else {
    //return requestMetadata.pipe(map(metadata => {
    //let cache: LocalStorageCacheItem<UserConfiguration> = {
    //data: metadata,
    //expirationDate: new Date(new Date().getTime() + DEFAULT_EXPIRATION_LOCAL_STORAGE * 60000)
    //};
    //this.saveInLocalStorage(USER_CONFIG_KEY_NAME+enterpriseId, cache);
    //return metadata;
    //}));
    //}
    //}

    public handleResetTimeReport(periodEnd: Date): void {
        this.resetBufferByKey(this.timeReportsBuffer, periodEnd);
    }

    public handleResetExpenses(periodEnd: Date, countryKey: string): void {
        countryKey == undefined
            ? this.resetBufferByKey(this.expenseListBuffer, periodEnd)
            : this.resetBufferByKey(
                this.expenseListBuffer,
                periodEnd.toLocaleDateString() + countryKey
            );
        this.resetBufferByKey(this.timeReportSummaryBuffer, periodEnd);
    }

    public handleReverseExpenses(periodEnd: Date, reverseExpenseRequest: Observable<any>, countryKey: string): Observable<any> {
        countryKey == undefined
            ? this.resetBufferByKey(this.expenseListBuffer, periodEnd.toLocaleDateString())
            : this.resetBufferByKey(this.expenseListBuffer, periodEnd.toLocaleDateString() + countryKey);
        return reverseExpenseRequest;
    }

    public handleDeleteAmexExpense(deleteAmexExpenseRequest: Observable<boolean>): Observable<boolean> {
        this.resetBufferByKey(this.amexExpenseList, AMEX_EXPENSES_KEY);
        return deleteAmexExpenseRequest;
    }

    public handleSaveExpense(periodEnd: Date, enterpriseId: string, saveExpenseRequest: Observable<ExpenseView>, countryKey: string, expenseTypeCd: string = null): Observable<ExpenseView> {
        countryKey == undefined
            ? this.resetBufferByKey(this.expenseListBuffer, periodEnd.toLocaleDateString())
            : this.resetBufferByKey(this.expenseListBuffer, periodEnd.toLocaleDateString() + countryKey);
        this.resetBufferByKey(this.amexExpenseList, AMEX_EXPENSES_KEY);
        this.clearTimeReportSummary(periodEnd, enterpriseId);
        this.expensesIssuesBuffer = [];
        if (expenseTypeCd == "EX05") {
            this.resetUserPreferences();
        }
        if (this.adjustmentStorage) this.adjustmentStorage.refresh = true;
        return saveExpenseRequest;
    }

    public handleCopyExpense(periodEnd: Date, handleCopyExpenseRequest: Observable<any>,countryKey: string): Observable<any> {
        countryKey == undefined
            ? this.resetBufferByKey(this.expenseListBuffer, periodEnd.toLocaleDateString())
            : this.resetBufferByKey(this.expenseListBuffer, periodEnd.toLocaleDateString() + countryKey);
        this.resetBufferByKey(this.amexExpenseList, AMEX_EXPENSES_KEY);
        return handleCopyExpenseRequest;
    }

    public handleSendForApproval(periodEnd: Date, request: Observable<any>): Observable<SubmitTimeReportOutput> {
        this.resetBufferByKey(this.timeReportsBuffer, periodEnd);
        return request;
    }

    public handleUpdateTimeReport(periodEnd: Date, updateTimeReportRequest: Observable<any>): Observable<any> {
        this.clearCurrentIsSendForApproval();
        return updateTimeReportRequest;
    }

    public handleUploadReceipt(periodEnd: Date, uploadReceiptRequest: Observable<Receipt>): Observable<Receipt> {
        this.resetBufferByKey(this.expenseListBuffer, periodEnd);
        return uploadReceiptRequest;
    }

    public handleSaveMissingReceipt(periodEnd: Date, saveMissingReceiptRequest: Observable<void>): Observable<void> {
        this.resetBufferByKey(this.expenseListBuffer, periodEnd);
        return saveMissingReceiptRequest;
    }

    public handleAdditionalDocumentation(periodEnd: Date, uploadReceiptRequest: Observable<boolean>): Observable<boolean> {
        this.resetBufferByKey(this.expenseListBuffer, periodEnd);
        return uploadReceiptRequest;
    }

    public handleDeleteReceipt(periodEnd: Date, deleteReceiptRequest: Observable<boolean>): Observable<boolean> {
        this.resetBufferByKey(this.expenseListBuffer, periodEnd);
        return deleteReceiptRequest;
    }

    public handleAuditInquiry(periodEnd: Date, auditInquiryRequest: Observable<boolean>): Observable<boolean> {
        this.resetBufferByKey(this.expenseListBuffer, periodEnd);
        this.expensesIssuesBuffer = [];
        return auditInquiryRequest;
    }

    public showExpensesIssues(): boolean {
        return this.isInLocalStorage<boolean>(SHOW_EXPENSE_ISSUES) == null;
    }

    public markExpensesIssuesAsSeen(): void {
        let cache: LocalStorageCacheItem<boolean> = {
            data: true,
            expirationDate: new Date(new Date().getTime() + 30 * 60000),
        };

        this.saveInLocalStorage(SHOW_EXPENSE_ISSUES, cache);
    }

    public showExpenseGroupByTrip() {
        return this.isInLocalStorage<any>(TRIP_GROUP_KEY);
    }

    public saveExpenseListShowStatus(value): void {
        let cache: LocalStorageCacheItem<any> = {
            data: value,
            expirationDate: new Date(new Date().getTime() + 30 * 60000),
        };
        this.saveInLocalStorage(TRIP_GROUP_KEY, cache);
    }

    public handleChargeCodeList(requestProjects: Observable<any>, enterpriseId: string,periodEnd: Date): Observable<any> {
        let cacheInPeriod;
        cacheInPeriod = this.isInBuffer(this.chargeCodeListBuffer, periodEnd);
        if (!cacheInPeriod) {
            return this.saveInBuffer(this.chargeCodeListBuffer, periodEnd, requestProjects).data;
        } else {
            return cacheInPeriod.data;
        }
    }

    public updateChargeCodeCacheForDelete(externalNumbers) {
        if (this.chargeCodeListBuffer != null) {
            externalNumbers.forEach((external) => {
                this.chargeCodeListBuffer.forEach((element) => {
                    element.data.subscribe((projects) => {
                        projects.projectInfo.forEach((pi) => {
                            if (pi.externalNumber == external) {
                                const index = projects.projectInfo.indexOf(pi, 0);
                                if (index > -1) {
                                    projects.projectInfo.splice(index, 1);
                                }
                            }
                        });
                    });
                    this.chargeCodeListCacheForSave(element.data, element.key);
                });
            });
            this.updateChargeCodeListBuffer();
        }
    }

    public updateChargeCodeForCacheForDisplay(externalNumber: string, isDisplay: any) {
        if (this.chargeCodeListBuffer != null) {
            this.chargeCodeListBuffer.forEach((element) => {
                element.data.subscribe((project) => {
                    project.projectInfo.forEach((p) => {
                        if (p.externalNumber == externalNumber) {
                            p.isDisplay = isDisplay;
                        }
                    });
                });
                this.chargeCodeListCacheForSave(element.data, element.key);
            });
            this.updateChargeCodeListBuffer();
        }
    }

    public chargeCodeListCacheForSave(requestProjects: Observable<any>, periodEnd: Date) {
        if (this.isInBuffer(this.chargeCodeListReadyForSave, periodEnd)) {
            this.resetBufferByKey(this.chargeCodeListReadyForSave, periodEnd);
        }
        this.saveInBuffer(this.chargeCodeListReadyForSave, periodEnd, requestProjects);
    }

    public resetChargeCodeList() {
        this.chargeCodeListBuffer = [];
    }

    public updateChargeCodeListBuffer() {
        this.chargeCodeListBuffer = [];
        this.chargeCodeListBuffer = Object.assign([], this.chargeCodeListReadyForSave);
    }

    public handleClientList(requestClientList: Observable<any>, prefixText: string): Observable<any> {
        let cacheInPeriod;
        cacheInPeriod = this.isInBuffer(this.clientListBuffer, prefixText);
        if (!cacheInPeriod) {
            return this.saveInBuffer(this.clientListBuffer, prefixText, requestClientList).data;
        } else {
            return cacheInPeriod.data;
        }
    }

    public resetClientList(refixText: string) {
        this.resetBufferByKey(this.clientListBuffer, refixText);
    }

    public handleLocationsCountries(request: Observable<Country[]>): Observable<Country[]> {
        let cache = this.isInBuffer(this.locationsCountriesBuffer, LOCATIONS_COUNTRIES);
        if (cache) return cache.data;
        return this.saveInBuffer(this.locationsCountriesBuffer, LOCATIONS_COUNTRIES, request).data;
    }

    public setPreferencesPopupList(userPreference: Observable<PreferencesPopupList>, enterpriseId: string
    ): Observable<PreferencesPopupList> {
        let cache = this.isInBuffer(this.saveUserPreferencesInputBuffer, enterpriseId);
        if (cache) {
            this.resetBufferByKey(this.saveUserPreferencesInputBuffer, enterpriseId);
        }
        return this.saveInBuffer(this.saveUserPreferencesInputBuffer, enterpriseId, userPreference).data;
    }

    public getPreferencesPopupList(enterpriseId: string): Observable<PreferencesPopupList> {
        let cache = this.isInBuffer(
            this.saveUserPreferencesInputBuffer,
            enterpriseId
        );
        if (cache) {
            return cache.data;
        } else {
            return of(null);
        }
    }

    public clearPreferencesPopupListFromBuffer(enterpriseId: string) {
        this.userPreferencesBuffer = [];
        let cache = this.isInBuffer(
            this.saveUserPreferencesInputBuffer,
            enterpriseId
        );
        if (cache)
            this.resetBufferByKey(this.saveUserPreferencesInputBuffer, enterpriseId);
    }

    //This function returns UserPreference data to fetch homeOfficeLocation
    public getUserPreferences(enterpriseId: string, periodEnd: Date): Observable<UserPreference> {
        let cache = this.isInBuffer(
            this.userPreferencesBuffer,
            enterpriseId + periodEnd.toLocaleString()
        );
        if (cache) {
            return cache.data;
        } else {
            return of(null);
        }
    }

    public handleSaveUserPreferences(
        request: Observable<string>
    ): Observable<string> {
        this.userPreferencesBuffer = [];
        return request;
    }

    public setTheme(theme: string): void {
        this.saveInLocalStorage(MYTE_THEME_KEY, {
            data: theme,
            expirationDate: new Date(
                new Date().getTime() + DEFAULT_EXPIRATION_LOCAL_STORAGE * 60000
            ),
        });
    }

    public getTheme(): string {
        let buffer = this.isInLocalStorage<string>(MYTE_THEME_KEY);
        return buffer != null ? buffer.data : "theme-default";
    }

    public handleExpenseIssues(eid: string, request: Observable<AuditInquiry[]>): Observable<AuditInquiry[]> {
        let cache = this.isInBuffer(this.expensesIssuesBuffer, eid);
        if (!cache) {
            return this.saveInBuffer(this.expensesIssuesBuffer, eid, request).data;
        } else {
            return cache.data;
        }
    }

    public handleProjectDescendants(chargeCode: string, requestDescendant: Observable<ChargeCodeRelated[]>, locationCountryKey: string = "", isTimeEntygridCell: boolean = false): Observable<ChargeCodeRelated[]> {
        let cacheInCurrentProjectDescendant;
        cacheInCurrentProjectDescendant = this.isInBuffer(this.projectDescendantBuffer, chargeCode + isTimeEntygridCell + locationCountryKey);
        if (!cacheInCurrentProjectDescendant) {
            return this.saveInBuffer(this.projectDescendantBuffer, chargeCode + isTimeEntygridCell + locationCountryKey, requestDescendant).data;
        } else {
            return cacheInCurrentProjectDescendant.data;
        }
    }

    public clearProjectDescendantBuffer() {
        this.projectDescendantBuffer = [];
    }

    public setTimeSheet(timeSheet: Observable<TimeSheet>, periodEnd: Date,hasExpirationDate: boolean = true): Observable<TimeSheet> {
        let cacheInPeriod = this.isInBuffer(this.timeSheetBuffer, periodEnd);
        if (cacheInPeriod) {
            this.resetBufferByKey(this.timeSheetBuffer, periodEnd);
        }
        return this.saveInBuffer(this.timeSheetBuffer, periodEnd, timeSheet, hasExpirationDate).data;
    }

    public clearTimeSheetFromBuffer(periodEnd: Date) {
        let cacheInPeriod = this.isInBuffer(this.timeSheetBuffer, periodEnd);
        if (cacheInPeriod) this.resetBufferByKey(this.timeSheetBuffer, periodEnd);
    }

    public getTimeSheet(periodEnd: Date): Observable<TimeSheet> {
        let cacheInPeriod = this.isInBuffer(this.timeSheetBuffer, periodEnd);
        if (cacheInPeriod) {
            return cacheInPeriod.data;
        } else {
            return of(null);
        }
    }

    public resetTimeSheet() {
        this.timeSheetBuffer = [];
    }

    //Method to clear the work locations in buffer cache.
    public resetWorkLocationList() {
        this.workLocationList = [];
    }

    public resetTimeSheetForTimeReportsNew() {
        this.timeReportsBuffer.forEach((element) => {
            element.data.subscribe((timeReports) => {
                if (timeReports.status == "New") {
                    this.resetBufferByKey(this.timeSheetBuffer, timeReports.periodEnd);
                }
            });
        });
    }

    public resetExpensList() {
        this.expenseListBuffer = [];
    }

    public handleTimeSheet(periodEnd: Date, timeSheetRequest: Observable<TimeSheet>): Observable<TimeSheet> {
        let cacheInPeriod = this.isInBuffer(this.timeSheetBuffer, periodEnd);
        if (cacheInPeriod) {
            return cacheInPeriod.data;
        } else {
            return this.saveInBuffer(this.timeSheetBuffer, periodEnd, timeSheetRequest).data;
        }
    }

    public setLocationsByDay(locationsByDay: Observable<LocationsByDay>, periodEnd: Date ): Observable<LocationsByDay> {
        let cacheInPeriod = this.isInBuffer(this.locationsByDayBuffer, periodEnd);
        if (cacheInPeriod) {
            this.resetBufferByKey(this.locationsByDayBuffer, periodEnd);
        }
        return this.saveInBuffer(this.locationsByDayBuffer, periodEnd, locationsByDay).data;
    }

    public clearLocationsByDayFromBuffer(periodEnd: Date) {
        let cacheInPeriod = this.isInBuffer(this.locationsByDayBuffer, periodEnd);
        if (cacheInPeriod)
            this.resetBufferByKey(this.locationsByDayBuffer, periodEnd);
    }

    // Storing ESTravelPunchClockEnabled status in localStorage
    public setESPunchClockSoftWarningBuffer(key: Date, Eid: string, value: boolean = false): void {
        let buffer = this.isInLocalStorage<boolean>(key.toString() + Eid);
        this.isESPunchClock = value;
        if (!(buffer?.data && value)) {
            if (value)
                this.saveInLocalStorage(key.toString() + Eid, {
                    data: value,
                    expirationDate: new Date(
                        new Date().getTime() + DEFAULT_EXPIRATION_LOCAL_STORAGE * 60000
                    ),
                });
            else this.resetLocalStorageByKey(key.toString() + Eid);
        }
    }

    public getESPunchClockSoftWarning(key: Date, Eid: string): boolean {
        let buffer = this.isInLocalStorage<boolean>(key.toString() + Eid);
        return buffer != null ? buffer.data : false;
    }
    public getLocationsByDay(periodEnd: Date): Observable<LocationsByDay> {
        let cacheInPeriod = this.isInBuffer(this.locationsByDayBuffer, periodEnd);
        if (cacheInPeriod) {
            return cacheInPeriod.data;
        } else {
            return of(null);
        }
    }

    public setTab(tabs: any, periodEnd: Date): any {
        let cacheInPeriod = this.isInBuffer(this.dictionaryTabsBuffer, periodEnd);
        if (cacheInPeriod) {
            this.resetBufferByKey(this.dictionaryTabsBuffer, periodEnd);
        }
        return this.saveInBuffer(this.dictionaryTabsBuffer, periodEnd, tabs).data;
    }

    public getTabs(periodEnd: Date): any {
        let cacheInPeriod = this.isInBuffer(this.dictionaryTabsBuffer, periodEnd);
        if (cacheInPeriod) {
            return cacheInPeriod.data;
        } else {
            return of(null);
        }
    }

    public clearTabsByDayFromBuffer(periodEnd: Date) {
        let cacheInPeriod = this.isInBuffer(this.dictionaryTabsBuffer, periodEnd);
        if (cacheInPeriod)
            this.resetBufferByKey(this.dictionaryTabsBuffer, periodEnd);
    }

    public clearShiftOptionsFromBuffer(periodEnd: Date) {
        let cacheInPeriod = this.isInBuffer(this.shiftOptionsBuffer, periodEnd);
        if (cacheInPeriod)
            this.resetBufferByKey(this.shiftOptionsBuffer, periodEnd);
    }
    public setShiftOptions(options: ShiftScheduleOption[], periodEnd: Date): void {
        this.clearShiftOptionsFromBuffer(periodEnd);
        this.saveInBuffer(this.shiftOptionsBuffer, periodEnd, null, true, options);
    }

    public getShiftOptions(periodEnd: Date): ShiftScheduleOption[] {
        const cacheInPeriod = this.isInBuffer(this.shiftOptionsBuffer, periodEnd);
        if (cacheInPeriod)
            return cacheInPeriod.rawData.map((o) => {
                let option = new ShiftScheduleOption();
                option.description = o.description;
                option.startTime = o.startTime;
                option.endTime = o.endTime;
                option.breakLength = o.breakLength;
                option.total = o.total;
                return option;
            });
        return null;
    }

    public setFocusElement(key: string, value: boolean): void {
        this.saveInLocalStorage(key, {
            data: value,
            expirationDate: new Date(
                new Date().getTime() + DEFAULT_EXPIRATION_LOCAL_STORAGE * 60000
            ),
        });
    }

    public getFocusElement(key: string): boolean {
        let buffer = this.isInLocalStorage<boolean>(key);
        return buffer != null ? buffer.data : false;
    }

    public clearFocusElement(key: string): void {
        localStorage.removeItem(key);
    }

    public setSubordinateMode(mode: SubordinatesMode): void {
        this.saveInSessionStorage(SUBORDINATE_MODE_KEY, {
            data: mode,
            expirationDate: new Date(
                new Date().getTime() + DEFAULT_EXPIRATION_LOCAL_STORAGE * 60000
            ),
        });
        this.subordinatesMode.next(mode);
    }

    public setSubordinateModeForSDL(mode: SubordinatesMode): void {
        this.saveInLocalStorage(SUBORDINATE_MODE_SDL_JEY, {
            data: mode,
            expirationDate: new Date(
                new Date().getTime() + DEFAULT_EXPIRATION_LOCAL_STORAGE * 60000
            ),
        });
    }

    public getSubordinateMode(): SubordinatesMode {
        let buffer = this.isInSessionStorage<{data: SubordinatesMode}>(SUBORDINATE_MODE_KEY);
        return buffer != null ? buffer.data : SubordinatesMode.None;
    }

    public getSubordinateModeForSDL(): SubordinatesMode {
        let buffer = this.isInLocalStorage<SubordinatesMode>(
            SUBORDINATE_MODE_SDL_JEY
        );
        return buffer != null ? buffer.data : SubordinatesMode.None;
    }

    public isSubordinateModeSupervisor(): boolean {
        return this.getSubordinateMode() === SubordinatesMode.Supervisor;
    }

    public setUserRole(role: UserRole): void {
        this.saveInLocalStorage(USER_ROLE_KEY, {
            data: role,
            expirationDate: new Date(
                new Date().getTime() + DEFAULT_EXPIRATION_LOCAL_STORAGE * 60000
            ),
        });
    }

    public getUserRole(): UserRole {
        let buffer = this.isInLocalStorage<UserRole>(USER_ROLE_KEY);
        return buffer != null ? buffer.data : UserRole.None;
    }

    public setCurrentSubordinate(subordinate: Subordinate): void {
        this.saveInSessionStorage(SUBORDINATE_KEY, {
            data: subordinate,
            expirationDate: new Date(
                new Date().getTime() + DEFAULT_EXPIRATION_LOCAL_STORAGE * 60000
            ),
        });
    }

    public getCurrentSubordinate(): Subordinate {
        let buffer = this.isInSessionStorage<{data: Subordinate}>(SUBORDINATE_KEY);

        return buffer != null ? buffer.data : null;
    }

    public setCurrentFilterStatusSubmitGroup(
        statusCode: TimeReportStatusCode
    ): void {
        this.saveInLocalStorage(SUBORDINATED_CATEGORY_STATUS_FILTER_SELECTED_KEY, {
            data: statusCode,
            expirationDate: new Date(
                new Date().getTime() + DEFAULT_EXPIRATION_LOCAL_STORAGE * 60000
            ),
        });
    }

    public getCurrentFilterStatusSubmitGroup(): TimeReportStatusCode {
        let buffer = this.isInLocalStorage<TimeReportStatusCode>(
            SUBORDINATED_CATEGORY_STATUS_FILTER_SELECTED_KEY
        );
        return buffer != null ? buffer.data : null;
    }

    public setIsSendForApprove(isSendForApproval: Boolean): void {
        this.clearCurrentIsSendForApproval();
        this.saveInLocalStorage(SEND_FOR_APPROVAL_KEY, {
            data: isSendForApproval,
            expirationDate: new Date(
                new Date().getTime() + DEFAULT_EXPIRATION_LOCAL_STORAGE * 60000
            ),
        });
    }

    public getIsSendForApprove(): boolean {
        let buffer = this.isInLocalStorage<boolean>(SEND_FOR_APPROVAL_KEY);
        return buffer != null ? buffer.data : false;
    }

    public clearCurrentSubordinateMode(): void {
        sessionStorage.removeItem(SUBORDINATE_MODE_KEY);
        this.subordinatesMode.complete();
    }

    public clearCurrentFilterStatusSubmitGroup(): void {
        localStorage.removeItem(SUBORDINATED_CATEGORY_STATUS_FILTER_SELECTED_KEY);
    }

    public clearCurrentSubordinate(): void {
        sessionStorage.removeItem(SUBORDINATE_KEY);
    }

    public clearCurrentIsSendForApproval(): void {
        localStorage.removeItem(SEND_FOR_APPROVAL_KEY);
    }

    public clearLocalStorage(): void {
        localStorage.clear();
    }

    public clearSessionStorage(): void {
        sessionStorage.clear();
    }

    public clearBufferCache(): void {
        this.expenseListBuffer = [];
        this.timeReportSettingsBuffer = [];
        this.timeReportsBuffer = [];
        this.amexExpenseList = [];
        this.tripNameListBuffer = [];
        this.chargeCodeListBuffer = [];
        this.chargeCodeListReadyForSave = [];
        this.locationsCountriesBuffer = [];
        this.userPreferencesBuffer = [];
        this.clientListBuffer = [];
        this.projectDescendantBuffer = [];
        this.timeSheetBuffer = [];
        this.expensesIssuesBuffer = [];
        this.taxReimbursementRateBuffer = [];
        this.locationsByDayBuffer = [];
        this.shiftOptionsBuffer = [];
        this.dictionaryTabsBuffer = [];
        this.timeReportSummaryBuffer = [];
        this.annualChargeabilityBuffer = [];
        this.softWarningAfterSubmissionBuffer = [];
        this.activitiesBuffer = [];
    }

    public setSWAfterSubmission(softWarning: Observable<string[]>, periodEnd: Date, hasExpirationDate: boolean = true): Observable<string[]> {
        let cacheInPeriod = this.isInBuffer(this.softWarningAfterSubmissionBuffer, periodEnd);
        if (cacheInPeriod) {
            this.resetBufferByKey(this.softWarningAfterSubmissionBuffer, periodEnd);
        }
        return this.saveInBuffer(this.softWarningAfterSubmissionBuffer, periodEnd, softWarning, hasExpirationDate).data;
    }

    public clearSWAfterSubmissionFromBuffer(periodEnd: Date) {
        let cacheInPeriod = this.isInBuffer(this.softWarningAfterSubmissionBuffer, periodEnd);
        if (cacheInPeriod)
            this.resetBufferByKey(this.softWarningAfterSubmissionBuffer, periodEnd);
    }

    public getSWAfterSubmission(periodEnd: Date): Observable<string[]> {
        let cacheInPeriod = this.isInBuffer(this.softWarningAfterSubmissionBuffer, periodEnd);
        if (cacheInPeriod) {
            return cacheInPeriod.data;
        } else {
            return of(null);
        }
    }

    public setExpenseTotalError(expenseTotalError: string, periodEnd: Date, hasExpirationDate: boolean = true): void {
        let cacheInPeriod = this.isInBuffer(this.expenseTotalErrorBuffer, periodEnd);
        if (cacheInPeriod) {
            this.resetBufferByKey(this.expenseTotalErrorBuffer, periodEnd);
        }
        this.saveInBuffer(this.expenseTotalErrorBuffer, periodEnd, null, hasExpirationDate, expenseTotalError);
    }

    public clearExpenseTotalErrorFromBuffer(periodEnd: Date) {
        let cacheInPeriod = this.isInBuffer(
            this.expenseTotalErrorBuffer,
            periodEnd
        );
        if (cacheInPeriod)
            this.resetBufferByKey(this.expenseTotalErrorBuffer, periodEnd);
    }

    public getExpenseTotalError(periodEnd: Date): string {
        const cacheInPeriod = this.isInBuffer(this.expenseTotalErrorBuffer, periodEnd);
        if (cacheInPeriod)
            return cacheInPeriod.rawData;
        return null;
    }

    public cleartWorkLocationByDay(userEid: string, periodEnd: Date, supervisorEid: string, viewMode: string) {
        const key = `${userEid}+${periodEnd.toISOStringMyTE()}+${supervisorEid}+${viewMode}`;

        let buffer = this.isInBuffer(this.workLocationList, key);
        if (buffer)
            this.resetBufferByKey(this.workLocationList, key);
    }

    public handleGetWorkLocation(request: Observable<WorkLocationDayInfo[]>, userEid: string, periodEnd: Date, supervisorEid: string, viewMode: string): Observable<WorkLocationDayInfo[]> {
        const key = `${userEid}+${periodEnd.toISOStringMyTE()}+${supervisorEid}+${viewMode}`;
        let buffer = (COUNTRY_KEY_LIST_FOR_MANDATORY_LOCATION_BY_DAY_EXCEPTION.indexOf(this.getUserCountry()) > -1 &&
                     COMPANY_CODE_LIST_FOR_MANDATORY_LOCATION_BY_DAY_EXCEPTION.indexOf(this.getUserCompanyCode()) == -1) ? null : this.isInBuffer(this.workLocationList, key);
        if (!buffer) {
            return this.saveInBuffer(this.workLocationList, key, request).data;
        } else {
            return buffer.data;
        }
    }

    public setActionLocationControl(action: Observable<string>, periodEnd: Date): Observable<string> {
        let cacheInPeriod = this.isInBuffer(this.actionLocationControl, periodEnd);
        if (cacheInPeriod) {
            this.resetBufferByKey(this.actionLocationControl, periodEnd);
        }
        return this.saveInBuffer(this.actionLocationControl, periodEnd, action).data;
    }

    public clearActionLocationFromBuffer(periodEnd: Date) {
        let cacheInPeriod = this.isInBuffer(this.actionLocationControl, periodEnd);
        if (cacheInPeriod)
            this.resetBufferByKey(this.actionLocationControl, periodEnd);
    }

    public getActionLocation(periodEnd: Date): Observable<string> {
        let cacheInPeriod = this.isInBuffer(this.actionLocationControl, periodEnd);
        if (cacheInPeriod) {
            return cacheInPeriod.data;
        } else {
            return of(null);
        }
    }

    public setActionMenu(actionMenu: Observable<ActionMenu>): Observable<ActionMenu> {
        let cacheInUserActionMenu = this.isInBuffer(this.actionMenuBuffer, GET_USER_ACTION_MUEN_KEY);
        if (cacheInUserActionMenu) {
            this.resetBufferByKey(this.actionMenuBuffer, GET_USER_ACTION_MUEN_KEY);
        }
        return this.saveInBuffer(this.actionMenuBuffer, GET_USER_ACTION_MUEN_KEY, actionMenu).data;
    }

    public getActionMenu(): Observable<ActionMenu> {
        let cacheInUserActionMenu = this.isInBuffer(this.actionMenuBuffer, GET_USER_ACTION_MUEN_KEY);
        if (cacheInUserActionMenu) {
            return cacheInUserActionMenu.data;
        } else {
            return of(null);
        }
    }

    public clearActionMenuFromBuffer() {
        let cacheInUserActionMenu = this.isInBuffer(this.actionMenuBuffer, GET_USER_ACTION_MUEN_KEY);
        if (cacheInUserActionMenu)
            this.resetBufferByKey(this.actionMenuBuffer, GET_USER_ACTION_MUEN_KEY);
    }

    public setLBDNASpecialWbsValidations(LBDNASpecialWbsValidations: string[], enterpriseId: string, periodEnd: Date): void {
        let cacheItem = this.isInBuffer(this.LBDNASpecialWbsValidationsBuffer, enterpriseId.toLocaleLowerCase() + periodEnd.toLocaleDateString());
        if (cacheItem) {
            this.resetBufferByKey(this.LBDNASpecialWbsValidationsBuffer, enterpriseId.toLocaleLowerCase() + periodEnd.toLocaleDateString());
        }
        this.saveInBuffer(this.LBDNASpecialWbsValidationsBuffer, enterpriseId.toLocaleLowerCase() + periodEnd.toLocaleDateString(), null, false, LBDNASpecialWbsValidations);
    }

    public clearLBDNASpecialWbsValidationsBuffer(enterpriseId: string, periodEnd: Date) {
        let cacheItem = this.isInBuffer(this.LBDNASpecialWbsValidationsBuffer, enterpriseId.toLocaleLowerCase() + periodEnd.toLocaleDateString());
        if (cacheItem)
            this.resetBufferByKey(this.LBDNASpecialWbsValidationsBuffer, enterpriseId.toLocaleLowerCase() + periodEnd.toLocaleDateString());
    }

    public getLBDNASpecialWbsValidations(enterpriseId: string, periodEnd: Date): string[] {
        const cacheItem = this.isInBuffer(this.LBDNASpecialWbsValidationsBuffer, enterpriseId.toLocaleLowerCase() + periodEnd.toLocaleDateString());
        if (cacheItem) return cacheItem.rawData;
        return null;
    }

    //Method will return true if per diem local expense error occured otherwise will return false
    public getPDL(): boolean {
        const varCache = this.isInSessionStorage<boolean>(PDL_ERROR_KEY);
        return varCache ? varCache : false;
    }

    //Method to set session storage for per diem local expense error
    public setPDL(isPDL: boolean): void {
        const isPDLCache = this.isInSessionStorage<boolean>(PDL_ERROR_KEY);
        if (isPDLCache) {
            if (Object.is(isPDLCache, isPDL)) {
                return;
            } else {
                this.resetSessionStorageByKey(PDL_ERROR_KEY);
            }
        }

        this.saveInSessionStorage(PDL_ERROR_KEY, isPDL);
    }

    //Method will return true if user is avanade user otherwise will return false
    public getIsAvanadeUser(): boolean {
        return this.isInSessionStorage<boolean>(IS_AVANADE_USER);
    }

    //Method to set session storage to true if user is avanade user otherwise set as false
    public setIsAvanadeUser(userType: boolean): void {
        const userTypeCache = this.isInSessionStorage<boolean>(IS_AVANADE_USER);
        if (userTypeCache) {
            if (Object.is(userTypeCache, userType)) {
                return;
            } else {
                this.resetSessionStorageByKey(IS_AVANADE_USER);
            }
        }

        this.saveInSessionStorage(IS_AVANADE_USER, userType);
    }

    public setPrivacyStatement(isFirst: boolean): void {
        this.saveInSessionStorage(PRIVACY_STATEMENT_KEY, isFirst);
    }

    public getPrivacyStatement(): boolean | null {
        const varCache = this.isInSessionStorage<boolean>(PRIVACY_STATEMENT_KEY);
        return varCache != null ? varCache : null;
    }
    public clearActivityGridFromBuffer(periodEnd: Date, enterpriseId: string) {
        this.activitiesBuffer = [];
        let cache = this.isInBuffer(
            this.activitiesBuffer,
            periodEnd.toLocaleDateString() + enterpriseId
        );
        if (cache)
            this.resetBufferByKey(
                this.activitiesBuffer,
                periodEnd.toLocaleDateString() + enterpriseId
            );
    }

    public getActivityGrid(periodEnd: Date, enterpriseId: string): Observable<ActivityOutPut> {
        let cache = this.isInBuffer(this.activitiesBuffer, periodEnd.toLocaleDateString() + enterpriseId);
        if (cache) {
            return cache.data;
        } else {
            return of(null);
        }
    }

    public setActivityGrid(periodEnd: Date, requestActivtyOutput: Observable<ActivityOutPut>, enterpriseId: string): void {
        let cache = this.isInBuffer(this.activitiesBuffer, periodEnd.toLocaleDateString() + enterpriseId);
        if (cache) {
            this.resetBufferByKey(this.activitiesBuffer, periodEnd.toLocaleDateString() + enterpriseId);
        }
        this.saveInBuffer(this.activitiesBuffer, periodEnd.toLocaleDateString() + enterpriseId, requestActivtyOutput);
    }

    public setActivityRowModel(periodEnd: Date, enterpriseId: string, requestActivtyOutput: ActivityOutputItem): void {
        let cache = this.isInBuffer(this.activitiesRowBuffer, periodEnd.toLocaleDateString() + enterpriseId + ACTIVITY_ROWMMODEL_KEY);
        if (!cache) {
            this.saveInBuffer(this.activitiesRowBuffer, periodEnd.toLocaleDateString() + enterpriseId + ACTIVITY_ROWMMODEL_KEY, null, true, requestActivtyOutput);
        }
    }

    public getActivityRowModel(periodEnd: Date, enterpriseId: string): ActivityOutputItem {
        let cache = this.isInBuffer(this.activitiesRowBuffer, periodEnd.toLocaleDateString() + enterpriseId + ACTIVITY_ROWMMODEL_KEY);
        if (cache) {
            return cache.rawData;
        } else {
            return null;
        }
    }

    /**
     * Set data to session storage
     * @param key -data key
     * @param data - value to be stored
     */
    public setDataToSessionStorage(key: string, data: any) {
        this.saveInSessionStorage(key, data);
    }

    /**
     * get data from session storage using key
     * @param key 
     * @returns 
     */
    public getInfoFromSessionStorage(key: string) {
        return this.isInSessionStorage<any>(key);
    }

    /**
     * Clear data from session storage for specific key
     * @param key 
     */
    resetSessionStorage(key: string) {
        this.resetSessionStorageByKey(key);
    }
    public setAuditeeDate(date: Date) {
        this.saveInSessionStorage(AUDITEEJOININGDATE_KEY, date);
    }

    public getAuditeeDate(): Date | null {
        const auditeeCache = this.isInSessionStorage<Date>(AUDITEEJOININGDATE_KEY);
        return auditeeCache ? moment(auditeeCache, 'M-D-YYYY').toDate() : null;
    }

    public clearAuditeeJoiningDate() {
        sessionStorage.removeItem(AUDITEEJOININGDATE_KEY);
    }

    /**
     * Getter for refresh audit status behaviour subject
     * @returns Observable
     */
    public getRefreshAuditStatus(): Observable<boolean> {
        return this.refreshAuditStatusBeSubject.asObservable();
    }

    /**
     * Setter for refresh audit status behaviour subject
     * @param value boolean
     */
    public setRefreshAuditStatus(value: boolean) {
        this.refreshAuditStatusBeSubject.next(value);
    }

    /**
     * Getter for audit reversal status behavior subject
     * @returns Observable
     */
    public getAuditIssueStatusChange(): Observable<boolean> {
        return this.auditStatusChangeBeSubject.asObservable();
    }

    /**
     * Setter for audit reversal status behavior subject
     * @param value boolean
     */
    public setAuditIssueStatusChange(value: boolean) {
        this.auditStatusChangeBeSubject.next(value);
    }

    /**
     * Getter for Complete audit status behavior subject
     * @returns Observable
     */
    public getCompleteAuditStatusChange(): Observable<boolean> {
        return this.CompleteAuditStatusChangeBeSubject.asObservable();
    }

    /**
     * Setter for Complete audit status behavior subject
     * @param value boolean
     */
    public setCompleteAuditStatusChange(value: boolean) {
        this.CompleteAuditStatusChangeBeSubject.next(value);
    }
}
