import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ExpenseFieldWithValidationResult, Option } from '@shared/clients/expense-api-client';
import { ExpenseAmount } from '@shared/models/expense/expense-amount';
import { CellClassParams, CellStyle, CellStyleFunc, CheckboxSelectionCallbackParams, ColDef, ColSpanParams, EditableCallbackParams, GridOptions, ICellRendererParams, IRowNode, RowClassParams, SuppressHeaderKeyboardEventParams, SuppressKeyboardEventParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DateFilterComponent } from '../../../../myte-request-overtime/components/filter/dateFilter.component';
import ExpenseGridItem from '../../../../shared/models/expense/expense-grid-item';
import { GlobalCacheService } from '../../../../shared/services/cache/global-cache.service';
import { LogService } from '../../../../shared/services/log/log.service';
import { UserService } from '../../../../shared/services/user/user.service';
import { NumberFormatUtils } from '../../../../shared/utils/numberFormater.utils';
import { ExpenseGridAmountRendererComponent } from '../../../components/myte-expense-grid/expense-grid-amount-renderer/expense-grid-amount-renderer.component';
import { ExpenseGridAssignmentEditorComponent } from '../../../components/myte-expense-grid/expense-grid-assignment-editor/expense-grid-assignment-editor.component';
import { ExpenseGridAssignmentRendererComponent } from '../../../components/myte-expense-grid/expense-grid-assignment-renderer/expense-grid-assignment-renderer.component';
import { ExpenseGridDateRendererComponent } from '../../../components/myte-expense-grid/expense-grid-date-renderer/expense-grid-date-renderer.component';
import { ExpenseGridDefaultRendererComponent } from '../../../components/myte-expense-grid/expense-grid-default-renderer/expense-grid-default-renderer.component';
import { ExpenseGridExpenseTypeRendererComponent } from '../../../components/myte-expense-grid/expense-grid-expense-type-renderer/expense-grid-expense-type-renderer.component';
import { ExpenseGridFilterComponent } from '../../../components/myte-expense-grid/expense-grid-filter/expense-grid-filter.component';
import { ExpenseGridHeaderComponent } from '../../../components/myte-expense-grid/expense-grid-header/expense-grid-header.component';
import { ExpenseGridOverlayLoadingComponent } from '../../../components/myte-expense-grid/expense-grid-overlay-loading/expense-grid-overlay-loading.component';
import { ExpenseGridReceiptRendererComponent } from '../../../components/myte-expense-grid/expense-grid-receipt-renderer/expense-grid-receipt-renderer.component';
import { ExpenseGridStatusRendererComponent } from '../../../components/myte-expense-grid/expense-grid-status-renderer/expense-grid-status-renderer.component';
import { ExpenseGridTotalReimbursementRendererComponent } from '../../../components/myte-expense-grid/expense-grid-total-reimbursement-renderer/expense-grid-total-reimbursement-renderer.component';
import { ExpenseService } from '../expense/expense.service';
import { ExpenseTripService } from '../trip/expense-trip.service';
import { ExpenseGridCheckboxRendererComponent } from '../../../components/myte-expense-grid/expense-grid-checkbox-renderer/expense-grid-checkbox-renderer.component';
import {EscalationInProgress, RejectedByAuditor, AuditClosed} from '../../../../shared/utils/constants';
import { ExpenseGridReasonCodeRendererComponent } from '../../../components/myte-expense-grid/expense-grid-reason-code/expense-grid-reason-code.component';
export interface ExpenseGridOptions {
    mainOptions: GridOptions<ExpenseGridItem>,
    footerOptions: GridOptions<ExpenseGridItem>
}

@Injectable()
export class ExpenseGridService {
    public showGroupByTrip: boolean;
    public isShowSupplementText: boolean;
    public isSubmitted = false;
    private timeReportStatus: string;
    public isViewModeAudit: boolean = false;
    public isAuditLead: boolean= false;
    public currentPeriod: Date;

    constructor(private expenseService: ExpenseService,
        private tripService: ExpenseTripService,
        private logService: LogService,
        private cacheService: GlobalCacheService,
        private userService: UserService,
        private router: Router) {
        let periodEnd: Date = this.cacheService.getPeriodEnd();
        this.cacheService.getTimeReport(periodEnd).subscribe(tr => this.isSubmitted = tr.status == "Submitted");
        this.cacheService.getTimeReportStatus(periodEnd).subscribe(status => this.timeReportStatus = status);
    }

    public getGridsOptions(): ExpenseGridOptions {
        return {
            mainOptions: this.getBodyGridOptions(),
            footerOptions: this.getFooterGridOptions()
        }
    }

    public getGridsOptionsForEmail(): ExpenseGridOptions {
        return {
            mainOptions: this.getBodyGridOptionsForEmail(),
            footerOptions: this.getFooterGridOptionsForEmail()
        }
    }

    private getBodyGridOptions(): GridOptions<ExpenseGridItem> {
        return {
            rowHeight: 40,
            headerHeight: 45,
            rowBuffer: 9999,
            rowSelection: 'multiple',
            domLayout: 'autoHeight',
            ensureDomOrder: true,
            suppressMenuHide: true,
            suppressHorizontalScroll: true,
            suppressColumnVirtualisation: true,
            stopEditingWhenCellsLoseFocus: true,
            suppressMovableColumns: true,
            suppressAnimationFrame: true,
            popupParent: document.querySelector('body'),
            defaultColDef: this.getDefaultColumnsDefinitions(),
            columnDefs: this.getColumnDefinitions(),
            components: { agColumnHeader: ExpenseGridHeaderComponent },
            loadingOverlayComponent: ExpenseGridOverlayLoadingComponent,
            isRowSelectable: (rowNode: IRowNode<ExpenseGridItem>) => {
                if (!rowNode.data.id && !this.isAuditLead) {
                    return false
                } else if (this.isAuditLead && rowNode.data.status.value === "Complete") {
                    return false
                } else {
                    return true
                }
            },
            rowClassRules: {
                'custom-disabled-row': (params: RowClassParams<ExpenseGridItem>) => this.isAuditLead && params.data.status.value === "Complete",
                'ag-row-adjusted': (params: RowClassParams<ExpenseGridItem>) => !this.showGroupByTrip && params.data.isAdjusted,
                'ag-row-tripRow': (params: RowClassParams<ExpenseGridItem>) => !params.data.id,
                'error-cell': (params: CellClassParams<ExpenseGridItem, ExpenseFieldWithValidationResult>) => !this.showGroupByTrip && !params.data.additionalInformation.isValid
            }
        };
    }

    private getFooterGridOptions(): GridOptions<ExpenseGridItem> {
        return {
            rowHeight: 40,
            headerHeight: 0,
            domLayout: 'autoHeight',
            ensureDomOrder: true,
            stopEditingWhenCellsLoseFocus: true,
            defaultColDef: this.getDefaultColumnsDefinitions(),
            columnDefs: this.getFooterColumnDefinitions(),
            overlayLoadingTemplate: '<div></div>'
        };
    }

    private getBodyGridOptionsForEmail(): GridOptions<ExpenseGridItem> {
        return {
            rowHeight: 40,
            headerHeight: 45,
            domLayout: 'autoHeight',
            rowSelection: undefined,
            ensureDomOrder: true,
            alwaysShowVerticalScroll: true,
            suppressMenuHide: true,
            suppressHorizontalScroll: true,
            suppressMovableColumns: true,
            defaultColDef: this.getDefaultColumnsDefinitions(),
            columnDefs: this.getColumnDefinitionsForEmail(),
            components: { agColumnHeader: ExpenseGridHeaderComponent },
            loadingOverlayComponent: ExpenseGridOverlayLoadingComponent,
            isRowSelectable: (rowNode: IRowNode<ExpenseGridItem>) => !rowNode.data.id ? false : true,
            rowClassRules: {
                'ag-row-adjusted': (params: RowClassParams<ExpenseGridItem>) => params.data.isAdjusted,
                'ag-row-tripRow': (params: RowClassParams<ExpenseGridItem>) => !params.data.id,
                'invalid': (params: CellClassParams<ExpenseGridItem, ExpenseFieldWithValidationResult>) => !this.showGroupByTrip && !params.data.additionalInformation.isValid
            }
        };
    }

    private getFooterGridOptionsForEmail(): GridOptions<ExpenseGridItem> {
        return {
            rowHeight: 40,
            headerHeight: 0,
            domLayout: 'autoHeight',
            stopEditingWhenCellsLoseFocus: true,
            suppressHorizontalScroll: true,
            defaultColDef: this.getDefaultColumnsDefinitions(),
            columnDefs: this.getFooterColumnDefinitionsForEmail(),
            overlayLoadingTemplate: '<div></div>'
        };
    }

    private getDefaultColumnsDefinitions(): ColDef<ExpenseGridItem> {
        return {
            suppressHeaderKeyboardEvent: (event: SuppressHeaderKeyboardEventParams<ExpenseGridItem>) => true,
            suppressNavigable: true,
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            width: 80,
            minWidth: 50,
            cellRenderer: ExpenseGridDefaultRendererComponent
        };
    }

    private getColumnDefinitions(): ColDef<ExpenseGridItem>[] {
        const columnDefs: ColDef<ExpenseGridItem>[] = [
            {
                headerName: 'Reason Code',
                filter: false,
                cellClass: 'd-flex justify-content-center',
                minWidth: this.isViewModeAudit? 180 : 0,
                maxWidth: 180,
                colId: 'reasonCode',
                suppressNavigable: false,
                suppressHeaderKeyboardEvent: () => false,
                cellRenderer: ExpenseGridReasonCodeRendererComponent,
                cellRendererParams: (params: ICellRendererParams<ExpenseGridItem>) => {
                    return {
                        reasonCodes: params.data.analyticsAuditReasons
                    }
                },
                valueGetter: (params: ValueGetterParams<ExpenseGridItem>) => {
                    return  params.data.analyticsAuditReasons
                },
                hide: !this.isViewModeAudit
            },
            {
                headerName: '',
                filter: false,
                checkboxSelection: true,
                headerClass: ['hide-label'],
                cellClass: ['checkbox-center'],
                width: 55,
                minWidth: 55,
                maxWidth: 55,
                colId: 'select',
                suppressNavigable: false,
                suppressHeaderKeyboardEvent: () => false,
                hide: this.isViewModeAudit,
                cellClassRules: {
                    'invalid': (params: CellClassParams<ExpenseGridItem, ExpenseFieldWithValidationResult>) => !this.showGroupByTrip && !params.data.sequence.isValid,
                }
            },
            {
                headerName: 'No.',
                field: 'sequence',
                sortable: true,
                comparator: (valueA: ExpenseFieldWithValidationResult, valueB: ExpenseFieldWithValidationResult) => valueA.value > valueB.value ? 1 : -1,
                filter: this.isViewModeAudit === true ? false : ExpenseGridFilterComponent,
                width: 70,
                minWidth: 70,
                maxWidth: 70,
                cellStyle: {"padding": "0px"},
                colId: 'sequence',
                cellClass: 'd-flex justify-content-end',
                valueFormatter: (params: ValueFormatterParams<ExpenseGridItem, ExpenseFieldWithValidationResult>) => params.value?.value,
                cellClassRules: {
                    'invalid': (params: CellClassParams<ExpenseGridItem, ExpenseFieldWithValidationResult>) => !this.showGroupByTrip && !params.data.sequence.isValid,
                }
            },
            {
                headerName: 'Source',
                field: 'source',
                sortable: true,
                comparator: (valueA: ExpenseFieldWithValidationResult, valueB: ExpenseFieldWithValidationResult) => valueA.value > valueB.value ? 1 : -1,
                filter: this.isViewModeAudit === true ? false : ExpenseGridFilterComponent,
                width: 93,
                minWidth: 93,
                cellStyle: {"padding": "0px"},
                colId: 'source',
                valueFormatter: (params: ValueFormatterParams<ExpenseGridItem, ExpenseFieldWithValidationResult>) => params.value?.value,
                cellClassRules: {
                    'invalid': (params: CellClassParams<ExpenseGridItem, ExpenseFieldWithValidationResult>) => !this.showGroupByTrip && !params.data.source.isValid,
                }
            },
            {
                headerName: 'Status',
                field: 'status',
                sortable: false,
                suppressNavigable: false,
                filter: this.isViewModeAudit === true ? false : ExpenseGridFilterComponent,
                width: 150,
                minWidth: 130,
                cellStyle: {"padding": "0px"},
                cellRenderer: ExpenseGridStatusRendererComponent,
                colId: 'status',
                cellClassRules: {
                    'invalid': (params: CellClassParams<ExpenseGridItem, ExpenseFieldWithValidationResult>) => !this.showGroupByTrip && !params.data.status.isValid,
                }
            },
            {
                headerName: 'From',
                sortable: true,
                comparator: (valueA: Date | null, valueB: Date | null) => {
                    if (valueA == null) valueA = new Date('1900/01/01');
                    if (valueB == null) valueB = new Date('1900/01/01');
                    return valueA.getTime() > valueB.getTime() ? 1 : -1;
                },
                filter: this.isViewModeAudit === true ? false : DateFilterComponent,
                filterParams: { clear: true },
                width: 97,
                minWidth: 97,
                cellStyle: {"padding": "0px"},
                cellRenderer: ExpenseGridDateRendererComponent,
                valueGetter: (params: ValueGetterParams<ExpenseGridItem>) => params.data && params.data.from.value ? new Date(params.data.from.value) : null,
                colId: 'fromDate',
                cellClassRules: {
                    'invalid': (params: CellClassParams<ExpenseGridItem, ExpenseFieldWithValidationResult>) => !this.showGroupByTrip && !params.data.from.isValid,
                }
            },
            {
                headerName: 'To',
                sortable: true,
                comparator: (valueA: Date | null, valueB: Date | null) => {
                    if (valueA == null) valueA = new Date('1900/01/01');
                    if (valueB == null) valueB = new Date('1900/01/01');
                    return valueA.getTime() > valueB.getTime() ? 1 : -1;
                },
                filter: this.isViewModeAudit === true ? false : DateFilterComponent,
                filterParams: { clear: true },
                width: 97,
                minWidth: 97,
                cellStyle: {"padding": "0px"},
                cellRenderer: ExpenseGridDateRendererComponent,
                valueGetter: (params: ValueGetterParams<ExpenseGridItem>) => params.data && params.data.to.value ? new Date(params.data.to.value) : null,
                colId: 'toDate',
                cellClassRules: {
                    'invalid': (params: CellClassParams<ExpenseGridItem, ExpenseFieldWithValidationResult>) => !this.showGroupByTrip && !params.data.to.isValid,
                }
            },
            {
                headerValueGetter: () => this.showGroupByTrip ? 'Trip/Charge Code' : 'Charge Code',
                field: 'assignment',
                sortable: true,
                comparator: (valueA: Option, valueB: Option) => valueA.value > valueB.value ? 1 : -1,
                filter: this.isViewModeAudit === true ? false : ExpenseGridFilterComponent,
                minWidth: 300,
                width: 400,
                cellStyle: {"padding": "0px"},
                editable: (params: EditableCallbackParams<ExpenseGridItem>) => !params.data.assignment.key && params.data.frequentTrip.id ? true : false,
                singleClickEdit: true,
                cellRenderer: ExpenseGridAssignmentRendererComponent,
                cellEditor: ExpenseGridAssignmentEditorComponent,
                colId: 'chargeCode',
                cellClassRules: {
                    'msacode': (params: CellClassParams<ExpenseGridItem, Option>) => !this.showGroupByTrip && params.data.assignment.masterServicesAgreement == 'X' && params.value.key != '',
                    'netWork': (params: CellClassParams<ExpenseGridItem, Option>) => !this.showGroupByTrip && (params.value.hasDismissCheckboxChecked ? false : params.value.hasNetWorks) && params.value.key != '' && this.isSubmitted && !params.data.assignment.isLeaf && (this.timeReportStatus == 'New' || this.timeReportStatus == 'Draft'),
                    'newNetWork': (params: CellClassParams<ExpenseGridItem, Option>) => !this.showGroupByTrip && params.data.assignment.treeNetworkHierarchy == 1 && (params.value.hasDismissCheckboxChecked ? false : params.value.hasNetWorks) && params.value.key != '' && this.isSubmitted && !params.data.assignment.isLeaf,
                    'suspense': (params: CellClassParams<ExpenseGridItem, Option>) => !this.showGroupByTrip && params.value?.hasSuspenseExpenseProject && params.value.key != '',
                    'staticError': (params: CellClassParams<ExpenseGridItem, Option>) => !this.showGroupByTrip && params.value?.hasInvalidStaticProject || params.value?.hasCostCollectors && params.value.key != '',
                    'invalid': (params: CellClassParams<ExpenseGridItem, Option>) => !this.showGroupByTrip && !params.value?.isValid && !this.isValidTimeReportStatus() || !params.value?.isValidForUser && !this.isValidTimeReportStatus(),
                }
            },
            {
                headerValueGetter: () => this.isShowSupplementText ? 'Type' : 'Expense Type',
                field: 'type',
                sortable: true,
                comparator: (valueA: ExpenseFieldWithValidationResult, valueB: ExpenseFieldWithValidationResult) => valueA.value > valueB.value ? 1 : -1,
                filter: this.isViewModeAudit === true ? false : ExpenseGridFilterComponent,
                minWidth: 165,
                cellClass: ['ps-1','pe-1'],
                cellRenderer: ExpenseGridExpenseTypeRendererComponent,
                colId: 'expenseType',
                cellClassRules: {
                    'invalid': (params: CellClassParams<ExpenseGridItem, ExpenseFieldWithValidationResult>) => !this.showGroupByTrip && params.data.type && !params.data.type.isValid,
                }
            },
            {
                headerName: 'Amount',
                field: 'expenseAmount',
                sortable: true,
                comparator: (valueA: ExpenseAmount, valueB: ExpenseAmount) => valueA.getAmount() > valueB.getAmount() ? 1 : -1,
                filter: this.isViewModeAudit === true ? false : true,
                filterParams: {
                    valueGetter: (params: ValueGetterParams<ExpenseGridItem>) => {
                        let value = params.data.expenseAmount;
                        if (value.message != null) {
                            if (value.taxTrueUpValue) {
                                return value.taxTrueUpValue;
                            }
                            else {
                                return value.message;
                            }
                        } else if (value.taxTrueUpValue) {
                            return NumberFormatUtils.formatNumberToLocaleString((value.amount + Number(value.taxTrueUpValue)), params.data.decimalPlaces, params.data.decimalPlaces);
                        } else {
                            return NumberFormatUtils.formatNumberToLocaleString(value.amount, params.data.decimalPlaces, params.data.decimalPlaces);
                        }
                    }
                },
                minWidth: 130,
                cellStyle: {"padding": "0px"},
                cellClass: 'text-center',
                cellRenderer: ExpenseGridAmountRendererComponent,
                colId: 'amount',
                cellClassRules: {
                    'invalid': (params: CellClassParams<ExpenseGridItem, ExpenseAmount>) => !this.showGroupByTrip && !params.value.isValid,
                }
            },
            {
                headerName: 'Additional Info',
                field: 'additionalInformation',
                comparator: (valueA: ExpenseFieldWithValidationResult, valueB: ExpenseFieldWithValidationResult) => valueA.value > valueB.value ? 1 : -1,
                sortable: true,
                filter: this.isViewModeAudit === true ? false : ExpenseGridFilterComponent,
                minWidth: 150,
                cellStyle: {"padding": "0px"},
                valueFormatter: (params: ValueFormatterParams<ExpenseGridItem, ExpenseFieldWithValidationResult>) => this.showGroupByTrip && params.data.id ? '' : params.value?.value,
                colId: 'aditionalInfo',
                cellClassRules: {
                    'invalid': (params: CellClassParams<ExpenseGridItem, ExpenseFieldWithValidationResult>) => !this.showGroupByTrip && !params.data.additionalInformation.isValid,
                }
            },
            {
                headerName: 'Receipt',
                field: 'receipt',
                sortable: false,
                filter: false,
                suppressNavigable: false,
                minWidth: 95,
                cellStyle: {"padding": "0px"},
                cellRenderer: ExpenseGridReceiptRendererComponent,
                cellClass: 'cursor-pointer',
                suppressKeyboardEvent: (params: SuppressKeyboardEventParams<ExpenseGridItem>) => params.event.key === 'Tab',
                colId: 'receipt',
                cellClassRules: {
                    'invalid': (params: CellClassParams<ExpenseGridItem, ExpenseFieldWithValidationResult>) => !this.showGroupByTrip && !params.data.sequence.isValid,
                }
            },
            {
                headerName: 'Self Cert',
                field: 'selfCertification',
                filter: false,
                sortable: false,
                checkboxSelection: false,
                cellClass: ['checkbox-center'],
                minWidth: this.isViewModeAudit? 110 : 0,
                maxWidth: 110,
                colId: 'selfCert',
                suppressNavigable: false,
                cellRenderer: ExpenseGridCheckboxRendererComponent,
                valueGetter: (params: ValueGetterParams<ExpenseGridItem>) => params.data.selfCertification ,
                suppressHeaderKeyboardEvent: () => false,
                hide: !this.isViewModeAudit
            },
            {
                headerName: 'Escalated',
                field: 'escalated',
                filter: false,
                sortable: false,
                checkboxSelection: false,
                cellClass: ['checkbox-center'],
                minWidth: this.isViewModeAudit? 110 : 0,
                maxWidth: 110,
                colId: 'escalated',
                suppressNavigable: false,
                cellStyle: (params: CheckboxSelectionCallbackParams<ExpenseGridItem>) => {
                    if (params.data.postPayEscalatedInd === false || (!this.isAuditLead && params.data.postPayEscalatedInd) || (this.isAuditLead && !params.data.postPayEscalatedInd) || (this.isAuditLead && (params.data.hasissues == true && this.cacheService.getInfoFromSessionStorage(this.cacheService.auditeeInfoKey)?.auditStatus == EscalationInProgress)) ||
                        (this.isAuditLead && (params.data.status.value == RejectedByAuditor || params.data.status.value == AuditClosed)) || (this.currentPeriod.getDate() !== this.cacheService.getPeriodEnd().getDate())) {
                      return {"pointer-events": "none", "opacity": "0.4"};
                    }
                },
                cellRenderer: ExpenseGridCheckboxRendererComponent,
                valueGetter: (params: ValueGetterParams<ExpenseGridItem>) => params.data.escalated ,
                suppressHeaderKeyboardEvent: () => false,
                hide: !this.isViewModeAudit
            },
            {
                headerName: 'Analytics Audit',
                field: 'analyticsAudit',
                filter: false,
                sortable: false,
                minWidth: this.isViewModeAudit? 135 : 0,
                maxWidth: 135,
                colId: 'analyticsAudit',
                cellClass: 'd-flex justify-content-center',
                suppressNavigable: false,
                suppressHeaderKeyboardEvent: () => false,
                valueGetter: (params: ValueGetterParams<ExpenseGridItem>) =>  params.data.analyticsAudit,
                hide: !this.isViewModeAudit
            },
        ];
        return columnDefs;
    }

    public getFooterColumnDefinitions(): ColDef<ExpenseGridItem>[] {
        const noAccessiblesFields = ['receipt', 'selfCertification', 'escalated', 'analyticsAudit'];
        const footerColumnDefs: ColDef<ExpenseGridItem>[] = this.getColumnDefinitions()
            .map(({ headerName, field, minWidth, maxWidth, width }) => {
                const footerColumnDef: ColDef<ExpenseGridItem> =
                {
                    headerName,
                    field,
                    minWidth,
                    maxWidth,
                    width,
                    valueGetter: null,
                    valueFormatter: null,
                    cellClass: (params: CellClassParams<ExpenseGridItem>) => {
                        let classes: string[] = [];
                        switch (field) {
                            case 'type':
                                classes = ['fw-bold', 'd-flex', 'justify-content-end'];
                                break;
                            case 'additionalInformation':
                                classes = ['fw-bold'];
                                if (params.data.isAdjusted) {
                                    classes.push('hasAdjustment-cell')
                                }
                                break;
                            default:
                                classes = null;
                                break;
                        }
                        return classes;
                    },
                    cellStyle: null,
                    cellRenderer: ExpenseGridTotalReimbursementRendererComponent,
                    colSpan: (params: ColSpanParams<ExpenseGridItem>) => { 
                        switch (params.column.getColId()) {
                            case 'type':
                                return 2;
                            case 'additionalInformation':
                                return 1;
                            case '0': //Represent first cell
                                return 8;
                            default:
                                return 0;
                        }
                    },
                    cellEditor: null,
                    cellAriaRole: (noAccessiblesFields.includes(field)) ? 'presentation' : 'gridcell'
                }
                
                return footerColumnDef;
            });
        return footerColumnDefs;
    }

    private getColumnDefinitionsForEmail(): ColDef<ExpenseGridItem>[] {
        const columnDefs: ColDef<ExpenseGridItem>[] = [
            {
                headerName: 'No.',
                field: 'sequence',
                sortable: false,
                filter: false,
                minWidth: 20,
                maxWidth: 30,
                colId: 'sequence',
                cellClass: 'text-end',
                valueFormatter: (params: ValueFormatterParams<ExpenseGridItem, ExpenseFieldWithValidationResult>) => params.value.value
            },
            {
                headerName: 'Details of Claim',
                field: 'type',
                sortable: false,
                filter: false,
                minWidth: 80,
                maxWidth: 100,
                cellClass: '',
                cellRenderer: ExpenseGridExpenseTypeRendererComponent,
                colId: 'expenseType'
            },
            {
                headerName: 'Date',
                field: 'from',
                sortable: false,
                filter: false,
                minWidth: 50,
                maxWidth: 60,
                cellRenderer: 'expenseDateRenderer',
                valueGetter: (params: ValueGetterParams<ExpenseGridItem>) => params.data && params.data.from.value ? new Date(params.data.from.value) : null,
                colId: 'fromDate'
            },
            {
                headerName: 'Description',
                field: 'type',
                sortable: false,
                filter: false,
                minWidth: 100,
                maxWidth: 100,
                cellClass: '',
                cellRenderer: ExpenseGridExpenseTypeRendererComponent,
                colId: 'expenseType'
            },
            {
                headerName: 'No of Days(with kitchen)',
                field: 'selfContainedDays',
                sortable: false,
                filter: false,
                minWidth: 150,
                maxWidth: 170,
                colId: 'source',
                valueFormatter: (params: ValueFormatterParams<ExpenseGridItem, number>) => params?.value?.toString()
            },
            {
                headerName: 'No of Days(without kitchen)',
                field: 'hotelDays',
                sortable: false,
                filter: false,
                minWidth: 150,
                maxWidth: 170,
                colId: 'source',
                valueFormatter: (params: ValueFormatterParams<ExpenseGridItem, number>) => params?.value?.toString()
            },
            {
                headerName: 'Foreign Amount',
                field: 'foreignAmount',
                sortable: false,
                filter: false,
                minWidth: 80,
                maxWidth: 100,
                valueFormatter: (params: ValueFormatterParams<ExpenseGridItem, number>) => params?.value?.toString()
            },
            {
                headerName: 'Currency Code',
                field: 'currency',
                sortable: false,
                filter: false,
                width: 100,
                minWidth: 100,
                maxWidth: 100,
                colId: 'currency'
            },
            {
                headerName: 'Exchange Rate',
                field: 'exchangeRate',
                sortable: false,
                filter: false,
                minWidth: 90,
                maxWidth: 90,
                cellClass: 'text-center',
                colId: 'amount'
            },
            {
                headerName: 'Local Amount',
                field: 'amountWithoutTax',
                sortable: false,
                filter: false,
                minWidth: 90,
                maxWidth: 90,
                cellClass: 'text-center',
                colId: 'amount'
            },
            {
                headerName: 'GST Amount',
                field: 'gSTAmount',
                sortable: false,
                filter: false,
                minWidth: 80,
                maxWidth: 100,
                cellClass: 'text-center',
                colId: 'amount'
            },
            {
                headerName: 'Total (inc. GST)',
                field: 'expenseAmount',
                sortable: false,
                filter: false,
                valueFormatter: (params: ValueFormatterParams<ExpenseGridItem, ExpenseAmount>) => params.value.amount.toString(),
                minWidth: 100,
                maxWidth: 100,
                cellClass: 'text-center',
                colId: 'amount'
            },
            {
                headerName: 'Charge Number',
                field: 'assignment',
                sortable: false,
                filter: false,
                width: 50,
                colId: 'chargeCode',
                valueFormatter: (params: ValueFormatterParams<ExpenseGridItem, Option>) => params.value.key
            }
        ];
        return columnDefs;
    }

    private getFooterColumnDefinitionsForEmail(): ColDef<ExpenseGridItem>[] {
        const footerColumnDefs: ColDef<ExpenseGridItem>[] = this.getColumnDefinitionsForEmail()
            .map(({ headerName, field, minWidth, maxWidth, width }) => {
                const footerColumnDef: ColDef<ExpenseGridItem> = {
                    headerName,
                    field,
                    minWidth,
                    maxWidth,
                    width,
                    valueGetter: null,
                    valueFormatter: null,
                    cellClass: (params: CellClassParams<ExpenseGridItem>) => {
                        let classes: string[] = [];
                        switch (field) {
                            case 'expenseAmount':
                                classes = ['fw-bold', 'number'];
                                break;
                            case 'assignment':
                                classes = ['fw-bold'];
                                if (params.data.isAdjusted) {
                                    classes.push('hasAdjustment-cell')
                                }
                                break;
                            default:
                                classes = null;
                                break;
                        }
                        return classes;
                    },
                    cellStyle: null,
                    cellRenderer: ExpenseGridTotalReimbursementRendererComponent,
                    colSpan: () => { return field == 'sequence' || field == 'from' ? 2 : 1 },
                    cellEditor: null
                }
                return footerColumnDef;
            });
        return footerColumnDefs;
    }

    public shouldDisplayExpenseTotalError(dataSource: ExpenseGridItem[]): boolean {
        let shouldDisplayExpenseTotalError = false;
        if (dataSource === undefined) { return shouldDisplayExpenseTotalError; }
        else {
            dataSource.forEach(expGridItem => {
                if (expGridItem.hasExpenseTotalErrorInd === undefined)
                    shouldDisplayExpenseTotalError = false;
                else shouldDisplayExpenseTotalError = expGridItem.hasExpenseTotalErrorInd;
            });
            return shouldDisplayExpenseTotalError;
        }
    }

    public getExpenseTotalError(dataSource: ExpenseGridItem[]): string {
        let expenseTotalError = '';
        dataSource.forEach(expGridItem => {
            if (expGridItem.expenseTotalErrors === undefined)
                expenseTotalError = null;
            else expenseTotalError = expGridItem.expenseTotalErrors;
        });
        return expenseTotalError;
    }

    public saveTripName(tripId, name) {
        this.tripService.updateTripInfo(tripId, name).subscribe(
            res => {
                res
                    ? this.logService.showSuccessMessage('Success', 'Update Success')
                    : this.logService.showValidationMessage('Error Message', `${res}`);

                this.cacheService.clearExpenseList(this.cacheService.getPeriodEnd(), this.userService.getAnotherCountryKey());
                this.cacheService.resetTripBuffer(this.userService.getUser().enterpriseId, this.userService.getAnotherCountryKey());
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.navigate(['/expenses'], { queryParams: { countryKey: this.userService.getAnotherCountryKey(), viewMode: encodeURIComponent(btoa(this.cacheService.getSubordinateMode().toString())) } });
            }
        );
    }

    public getFooterData(dataSource: ExpenseGridItem[], isCarPlan: boolean): ExpenseGridItem[] {
        let expenseGridItem = new ExpenseGridItem();
        expenseGridItem.id = null;
        expenseGridItem.source = null;
        expenseGridItem.expenseTotalErrors = dataSource.length > 0 ? dataSource[0].expenseTotalErrors : null;
        expenseGridItem.code = null;
        expenseGridItem.amount = null;
        expenseGridItem.isAdjusted = this.isPPA(dataSource);
        expenseGridItem.isEditable = false;
        expenseGridItem.isReversed = false;
        expenseGridItem.frequentTrip = null;
        expenseGridItem.hasExpenseTotalErrorInd = false;
        expenseGridItem.currency = null;
        expenseGridItem.hasFederalWBSInd = false;
        expenseGridItem.sequence = null;
        expenseGridItem.status = null;
        expenseGridItem.from = null;
        expenseGridItem.to = null;
        expenseGridItem.assignment = null;
        expenseGridItem.type = `Total Reimbursement:` as any;
        expenseGridItem.expenseAmount = null;
        expenseGridItem.additionalInformation = `\xa0\xa0\xa0${this.amountFormat(dataSource, isCarPlan)}\xa0\xa0\xa0${this.currencyLabel(dataSource)}` as any;
        expenseGridItem.receipt = null;
        expenseGridItem.taxTrueUpValue = 0;
        dataSource.forEach(expense => {
            if (expense.expenseAmount.taxTrueUpValue) {
                expenseGridItem.taxTrueUpValue += Number(expense.expenseAmount.taxTrueUpValue);
            }
        });
        return [expenseGridItem];
    }
    public getFooterData1(dataSource: ExpenseGridItem[], isCarPlan: boolean): ExpenseGridItem[] {
        let expenseGridItem = new ExpenseGridItem();
        expenseGridItem.id = null;
        expenseGridItem.source = null;
        expenseGridItem.expenseTotalErrors = dataSource.length > 0 ? dataSource[0].expenseTotalErrors : null;
        expenseGridItem.code = null;
        expenseGridItem.amount = null;
        expenseGridItem.isAdjusted = this.isPPA(dataSource);
        expenseGridItem.isEditable = false;
        expenseGridItem.isReversed = false;
        expenseGridItem.frequentTrip = null;
        expenseGridItem.hasExpenseTotalErrorInd = false;
        expenseGridItem.currency = null;
        expenseGridItem.hasFederalWBSInd = false;
        expenseGridItem.sequence = `Total Reimbursement:` as any;
        expenseGridItem.status = null;
        expenseGridItem.from = `\xa0\xa0\xa0${this.amountFormat(dataSource, isCarPlan)}\xa0\xa0\xa0${this.currencyLabel(dataSource)}` as any;
        expenseGridItem.to = null;
        expenseGridItem.assignment = null;
        expenseGridItem.type = null
        expenseGridItem.expenseAmount = null;
        expenseGridItem.additionalInformation = `\xa0\xa0\xa0${this.amountFormat(dataSource, isCarPlan)}\xa0\xa0\xa0${this.currencyLabel(dataSource)}` as any;
        expenseGridItem.receipt = null;
        expenseGridItem.taxTrueUpValue = 0;
        dataSource.forEach(expense => {
            if (expense.expenseAmount.taxTrueUpValue) {
                expenseGridItem.taxTrueUpValue += Number(expense.expenseAmount.taxTrueUpValue);
            }
        });
        return [expenseGridItem];
    }

    public isPPA(dataSource: ExpenseGridItem[]) {
        for (const data of dataSource) {
            if (data.isAdjusted)
                return true;
        }
        return false;
    };

    public amountFormat(dataSource: ExpenseGridItem[], isCarPlan: boolean): any {
        let amount = dataSource.reduce((prev, curr) => prev + (curr.source.value === 'IQN' || (isCarPlan && curr.code == "EX05") ? 0 : curr.expenseAmount.getAmount()), 0);
        return this.formatValueToLocale(amount.toString(), this.decimalPlaces(dataSource));
    }

    public currencyLabel(dataSource: ExpenseGridItem[]): any {
        var currencylbl = "";
        dataSource.forEach(ExpenseGridItem => {
            if (ExpenseGridItem.currency === undefined)
                currencylbl = ""
            else currencylbl = ExpenseGridItem.currency;
        });
        return currencylbl;
    }

    public formatValueToLocale(value: string, decimalPlaces: number): string {
        if (isNaN(Number(value)) || value === null || value === undefined || value === '') { return value; }
        return NumberFormatUtils.formatNumberToLocaleString(parseFloat(value), decimalPlaces);
    }

    public decimalPlaces(dataSource: ExpenseGridItem[]): number {
        let decimalPlaces = 2;
        dataSource.forEach(exp => {
            if (exp.decimalPlaces != undefined)
                decimalPlaces = exp.decimalPlaces;
        });
        return decimalPlaces;
    }

    public deleteExpenses(expenseIds: string[], periodEnd: Date, countryKey: string): Observable<any> {
        return this.expenseService.deleteExpense(expenseIds, periodEnd, countryKey);
    }

    public reverseExpense(expenseIds: string[], periodEnd: Date): Observable<any> {
        return this.expenseService.reverseExpense(expenseIds, periodEnd);
    }

    private isValidTimeReportStatus(): boolean {
        return this.timeReportStatus == 'Processed' || this.timeReportStatus == 'Submitted' || this.timeReportStatus == 'ProcessedAdjustment' || this.timeReportStatus == 'SubmittedAdjustment' || this.timeReportStatus == 'SubmitPendingAdjustment';
    }

    public getFooterDataEmailStore(hasPPA: boolean, expenseTotalErrors: string, totalAmount: number = 0, totalTaxTrueUpValue: number = 0, currency: string = ''): ExpenseGridItem[] {
        let expenseGridItem = new ExpenseGridItem();
        expenseGridItem.id = null;
        expenseGridItem.source = null;
        expenseGridItem.expenseTotalErrors = expenseTotalErrors;
        expenseGridItem.code = null;
        expenseGridItem.amount = null;
        expenseGridItem.isAdjusted = hasPPA;
        expenseGridItem.isEditable = false;
        expenseGridItem.isReversed = false;
        expenseGridItem.frequentTrip = null;
        expenseGridItem.hasExpenseTotalErrorInd = false;
        expenseGridItem.currency = null;
        expenseGridItem.hasFederalWBSInd = false;
        expenseGridItem.sequence = `Total Reimbursement:` as any;
        expenseGridItem.status = null;
        expenseGridItem.from = `\xa0\xa0\xa0${totalAmount}\xa0\xa0\xa0${currency}`as any;
        expenseGridItem.to = null;
        expenseGridItem.assignment = null;
        expenseGridItem.type = null
        expenseGridItem.expenseAmount = null;
        expenseGridItem.additionalInformation = `\xa0\xa0\xa0${totalAmount}\xa0\xa0\xa0${currency}`as any;
        expenseGridItem.receipt = null;
        expenseGridItem.taxTrueUpValue = 0;
        expenseGridItem.taxTrueUpValue = totalTaxTrueUpValue;
        return  [ expenseGridItem ];
    }

    public getFooterDataStore(hasPPA: boolean, expenseTotalErrors: string, totalAmount: number = 0, totalTaxTrueUpValue: number = 0, currency: string = ''): ExpenseGridItem[] {
        let expenseGridItem = new ExpenseGridItem();
        expenseGridItem.id = null;
        expenseGridItem.source = null;
        expenseGridItem.expenseTotalErrors = expenseTotalErrors;
        expenseGridItem.code = null;
        expenseGridItem.amount = null;
        expenseGridItem.isAdjusted = hasPPA;
        expenseGridItem.isEditable = false;
        expenseGridItem.isReversed = false;
        expenseGridItem.frequentTrip = null;
        expenseGridItem.hasExpenseTotalErrorInd = false;
        expenseGridItem.currency = null;
        expenseGridItem.hasFederalWBSInd = false;
        expenseGridItem.sequence = null;
        expenseGridItem.status = null;
        expenseGridItem.from = null;
        expenseGridItem.to = null;
        expenseGridItem.assignment = null;
        expenseGridItem.type = `Total Reimbursement:` as any;
        expenseGridItem.expenseAmount = null;
        expenseGridItem.additionalInformation = `\xa0\xa0\xa0${totalAmount}\xa0\xa0\xa0${currency}`as any;
        expenseGridItem.receipt = null;
        expenseGridItem.taxTrueUpValue = 0;
        expenseGridItem.taxTrueUpValue = totalTaxTrueUpValue;
        return  [ expenseGridItem ];
    }
}
