import { AfterViewChecked, Component, ElementRef, ViewChild } from '@angular/core';
import { DateformatPipe } from '@shared/pipes/dateformat.pipe';
import { FormatDateType, INDIA_OVERTIME, MassPPATooltips } from '@shared/utils/constants';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { AgInputTextField, AgSelect, DateFilter, HeaderClass, IHeaderParams, NumberFilter, TextFilter } from 'ag-grid-community';
import { DropdownComponent } from '../../../../myte-controls/components/dropdown/dropdown.component';
import { DatePickerComponent } from '../../../../myte-controls/components/date-picker/date-picker.component';
import { DateFilterComponent } from '../../../../myte-request-overtime/components/filter/dateFilter.component';
import { GlobalCacheService } from '../../../../shared/services/cache/global-cache.service';
import { ExpenseTaxiGridService } from '../../../shared/services/grids/expense-taxi-grid.service';
import { ExpenseAmexFilterComponent } from '../../myte-expense-popups/expense-amex-filter/expense-amex.filter.component';
import { ExpenseGridFilterComponent } from '../expense-grid-filter/expense-grid-filter.component';

@Component({
  selector: 'myte-expense-grid-header',
  templateUrl: './expense-grid-header.component.html',
  styleUrls: ['./expense-grid-header.component.sass']
})
export class ExpenseGridHeaderComponent implements IHeaderAngularComp, AfterViewChecked {
  public params: IHeaderParams & { useMenuIcon: boolean, tooltip: string };
  public actualSort: string = 'desc';
  public ascSort: string;
  public descSort: string;
  public noSort: string;
  public dateToolTip: string;
  public amount: string;
  public isGroupByTrip = false;
  public triggeredBy: string = null;
  public headerClass: string[];
  public columnHeaderTooltipText = INDIA_OVERTIME.columnHeaderTooltipText;
  public massPpatooltip = MassPPATooltips;
  @ViewChild('displayName', { read: ElementRef }) public displayName: ElementRef<HTMLElement>;
  @ViewChild('menuButton', { read: ElementRef }) public menuButton: ElementRef<HTMLElement>;
  @ViewChild('arrowUpGrid', { read: ElementRef }) public arrowUpGrid: ElementRef<HTMLElement>;
  @ViewChild('arrowDownGrid', { read: ElementRef }) public arrowDownGrid: ElementRef<HTMLElement>;

  constructor(
    public expenseTaxiGridService: ExpenseTaxiGridService,
    private cacheService: GlobalCacheService) {
  }

  public ngAfterViewChecked(): void {
    if (this.triggeredBy && this.triggeredBy == "Arrows") {
      this.focusUsability();
      this.triggeredBy = null;
    }
  }

  public agInit(params: IHeaderParams & { useMenuIcon: boolean, tooltip: string }): void {
    this.params = params;
    const headerClasses: HeaderClass<any> = this.params.column.getColDef().headerClass;
    if (headerClasses && Array.isArray(headerClasses) && headerClasses.length > 0)
      this.headerClass = headerClasses.filter((headerClass: string) => headerClass.includes('justify'));
    params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
    this.onSortChanged();
    this.amount = 'Amount(' + this.expenseTaxiGridService.currency + ')';
  }

  public refresh(params: IHeaderParams): boolean {
    return false;
  }

  public async onMenuClicked(): Promise<void> {
    this.params.showColumnMenu(this.menuButton.nativeElement);
    const columnHeaderName: string = this.params.column.getColDef().headerName;
    const columnFilterInstance: TextFilter | DateFilter | NumberFilter | DateFilterComponent | ExpenseGridFilterComponent | ExpenseAmexFilterComponent = await this.params.api.getColumnFilterInstance(this.params.column);
    if ('eType1' in columnFilterInstance && 'eValueFrom1' in columnFilterInstance) {
      const columnTypeElement: AgSelect = columnFilterInstance['eType1'] as AgSelect;
      if (columnTypeElement)
        columnTypeElement.setAriaLabel(`${columnHeaderName} filter types.`);
      const columnInputElement: AgInputTextField = columnFilterInstance['eValueFrom1'] as AgInputTextField;
      if (columnInputElement) {
        columnInputElement.getInputElement().focus;
        columnInputElement.setInputAriaLabel(`${columnHeaderName} filter opened. Type to filter by ${columnHeaderName}.`);
      }
      if ('addGuiEventListener' in columnFilterInstance)
        columnFilterInstance.addGuiEventListener("keydown", (event: KeyboardEvent) => {
          this.addKeyboardEventListener(event);
        });
    } else if (columnFilterInstance instanceof DateFilterComponent) {
      const columnFilterElements: HTMLCollectionOf<Element> = document.getElementsByTagName('myte-date-picker');
      if (columnFilterElements && columnFilterElements.length > 0) {
        columnFilterElements.item(0).addEventListener("keydown", (event: KeyboardEvent) => {
          this.addKeyboardEventListener(event);
        });
      }
      if (columnFilterInstance.text instanceof DatePickerComponent) {
        columnFilterInstance.text.ngbInputDatepicker.nativeElement.focus();
        if (!columnFilterInstance.text.ariaLabel)
          columnFilterInstance.text.ariaLabel = `${columnHeaderName} filter opened. Type to filter by ${columnHeaderName}.`;
      }
      if (columnFilterInstance.filterOptions instanceof DropdownComponent) {
        if (!columnFilterInstance.filterOptions.label)
          columnFilterInstance.filterOptions.label = `${columnHeaderName} filter types.`;
      }
    } else if (columnFilterInstance instanceof ExpenseGridFilterComponent || columnFilterInstance instanceof ExpenseAmexFilterComponent) {
      columnFilterInstance.ngbInputTextBox.nativeElement.focus();
      columnFilterInstance.ariaLabel = `${columnHeaderName} filter opened. Type to filter by ${columnHeaderName}.`;
      columnFilterInstance.ngbInputTextBox.nativeElement.addEventListener("keydown", (event: KeyboardEvent) => {
        this.addKeyboardEventListener(event);
      });
    }
  }

  private addKeyboardEventListener(event: KeyboardEvent): void {
    if (event.key == 'Escape' || event.code == 'Escape') {
      this.params.api.hidePopupMenu();
      this.menuButton.nativeElement.focus();
      event.stopPropagation();
    }
  }

  public onSortChanged(): void {
    this.ascSort = this.descSort = this.noSort = 'inactive';
    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active';
      this.triggeredBy = "Arrows";
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active';
      this.triggeredBy = "Arrows";
    } else {
      this.noSort = 'active';
      if (this.displayName)
        this.displayName.nativeElement.focus();
    }
  }

  public focusUsability(): void {
    if (this.noSort == 'active')
      return;
    if (this.ascSort == 'active'){
      this.arrowUpGrid?.nativeElement?.focus();
    }
    else if (this.descSort == 'active'){
      this.arrowDownGrid?.nativeElement?.focus();
    }
  }

  public toggleOrder(): 'desc' | 'asc' | null {
    return this.actualSort = this.actualSort == 'desc' ? 'asc' : 'desc';
  }

  public onSortRequested(order: 'desc' | 'asc' | null, event: KeyboardEvent): void {
    if (!this.params.enableSorting) return;
    this.isGroupByTrip = this.cacheService.showExpenseGroupByTrip()?.data;
    if (this.isGroupByTrip) return
    this.params.setSort(order, event.shiftKey);
  }

  public setDateDisplayName(): string {
    const dateFormat = new DateformatPipe().transform(null, FormatDateType.GetBrowserDateFormat);
    this.dateToolTip = 'Dates must be entered in ' + dateFormat + ' format.';
    return 'Date(' + dateFormat + ')';
  }
}
