import { Injectable } from '@angular/core';
import { TimeReportApiClient } from '@shared/clients/timereport-api-client';
import { LogService } from './../../../shared/services/log/log.service';
import { catchError, Observable, of } from 'rxjs';
import { MyteBaseService } from '@shared/services/myte-base.service';
import { GlobalEventsService } from '@shared/services/events/global-events.service';
import { GlobalCacheService } from '@shared/services/cache/global-cache.service';
import { UserService } from '@shared/services/user/user.service';
import ActionMenu from '@shared/models/action-menu';

@Injectable({
  providedIn: 'root'
})
export class GlobalApproverService extends MyteBaseService {
  private selectedCountryCodeForGlobalApproval: string = '';
  private _userActionMenu = new ActionMenu;

  constructor(private api: TimeReportApiClient,
    logService: LogService,
    globalEventsService: GlobalEventsService,
    globalCacheService: GlobalCacheService,
    userService: UserService
  ) {
    super(userService, globalCacheService, globalEventsService, logService)
  }

  /**
   * get Country list for approver dropdown
   * @returns 
   */
  getCountryForGlobalApprover(): Observable<any> {
    return this.api.getEmployeeCountryDropdownForGlobalApproverPage()
      .pipe(catchError(err => {
        this.logService.logError(err, true);
        return of(null)
      }))
  }

  /**
   * global approver selected country setter
   */
  public set selectedGlobalApproverCountry(country: string) {
    this.selectedCountryCodeForGlobalApproval = country;
  }

  /**
   * global approver selected country getter
   */
  public get selectedGlobalApproverCountry() {
    return this.selectedCountryCodeForGlobalApproval;
  }

  /**
   * UserActionMenu setter
   */
  public set userActionMenu(result: ActionMenu) {
    this._userActionMenu = result;
  }

  /**
   * UserActionMenu getter
   */
  public get userActionMenu(): ActionMenu {
    return this._userActionMenu;
  }

  
}
