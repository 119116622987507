import { PageLogoutComponent } from './myte-root/components/page-logout/page-logout.component';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { PageNotFoundComponent } from './myte-root/components/page-not-found/page-not-found.component';
import { TermsOfUseComponent } from './myte-root/components/terms-of-use/terms-of-use.component';
import { TimeReportResolver } from './myte-root/shared/resolvers/time-report.resolver';
import { PageContentComponent } from './myte-root/components/page-content/page-content.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { ProdGuard } from './auth/guards/prod.guard';
import { SubordinateModeResolver } from './myte-subordinates/services/resolvers/subordinate-mode.resolver';
import { TabRelationsResolver } from './myte-root/shared/resolvers/tab-relations.resolver';
import { MyteAutomationTestDataComponent } from './myte-automation-test-data/myte-automation-test-data.component';
import { PageNotAuthorizedComponent } from './myte-root/components/page-not-authorized/page-not-authorized.component';
import { BrowserUtils } from '@azure/msal-browser';
import { environment } from '@environments/environment';
import { MsalGuard } from '@azure/msal-angular';
import { ExpenseMappingAdminGuard } from './myte-expense-mapping-admin/shared/guards/expense-mapping-admin.guard';
import { SupportAdminGuard } from './myte-support-admin/shared/guards/support-admin.guard';

const authGuard: typeof MsalGuard | typeof AuthGuard = environment.production ? MsalGuard : AuthGuard;
const appRoutes: Routes = [
  {// Needed for hash routing
    path: 'code',
    redirectTo: ''
  },
  {
    path: '',
    component: PageContentComponent,
    resolve: { timeReportData: TimeReportResolver, subordinateMode: SubordinateModeResolver, TabRelationData: TabRelationsResolver },
    runGuardsAndResolvers: 'always',
    canActivate: [authGuard],
    children: [
      {
        path: '',
        redirectTo: 'time',
        pathMatch: 'full'
      },
      {
        path: 'time',
        loadChildren: () => import('./myte-time/myte-time.module').then(m => m.TimeModule)
      },
      {
        path: 'expenses',
        loadChildren: () => import('./myte-expenses/myte-expenses.module').then(m => m.MyteExpensesModule)
      },
      {
        path: 'locations',
        loadChildren: () => import('./myte-locations/myte-locations.module').then(m => m.MyteLocationsModule)
      },
      {
        path: 'chargecodes',
        loadChildren: () => import('./myte-chargecodes/myte-chargecodes.module').then(m => m.MyteChargecodesModule)
      },
      {
        path: 'adjustments',
        loadChildren: () => import('./myte-adjustments/myte-adjustments.module').then(m => m.MyteAdjustmentsModule)
      },
      {
        path: 'compensatory',
        loadChildren: () => import('./myte-compensatory/myte-compensatory.module').then(m => m.MyteCompensatoryModule)
      },
      {
        path: 'preferences',
        loadChildren: () => import('./myte-preferences/myte-preferences.module').then(m => m.MytePreferencesModule)
      },
      {
        path: 'questionnaire',
        loadChildren: () => import('./myte-questionnaire/myte-questionnaire.module').then(m => m.MyteQuestionnaireModule)
      },
      {
        path: 'summary',
        loadChildren: () => import('./myte-summary/myte-summary.module').then(m => m.MyteSummaryModule)
      },
      {
        path: 'carplan',
        loadChildren: () => import('./myte-car-information/myte-car-information.module').then(m => m.MyteCarInformationModule)
      },
      {
        path: 'activity',
        loadChildren: () => import('./myte-activity/myte-activity.module').then(m => m.MyteAcivityModule)
      },
      {
        path: 'issues',
        loadChildren: () => import('./myte-audit-issues/myte-audit-issues.module').then(m => m.MyteAuditIssuesModule)
      },
      {
        path: 'comments',
        loadChildren: () => import('./myte-audit-comments/myte-audit-comments.module').then(m => m.MyteAuditCommentsModule)
      }

    ]
  },
  {
    path: 'automationtestdata',
    component: MyteAutomationTestDataComponent,
    canActivate: [authGuard, ProdGuard]
  },
  {
    path: 'requestovertime',
    canActivate: [authGuard],
    loadChildren: () => import('./myte-request-overtime/myte-request-overtime.module').then(m => m.MyteRequestOvertimeModule)
  },
  {
    path: 'approveovertime',
    canActivate: [authGuard],
    loadChildren: () => import('./myte-approve-hold-overtime/myte-approve-hold-overtime.module').then(m => m.MyteApproveAndHoldOvertimeModule)
  },
  {
    path: 'globalapproval',
    canActivate: [authGuard],
    title: 'Global Approval',
    loadChildren: () => import('./myte-global-approver/myte-global-approver.module').then(m => m.MyteGlobalApproverModule)
  },
  {
    path: 'approveovertimeandallowance',
    canActivate: [authGuard],
    loadChildren: () => import('./myte-approval-allowance-and-overtime/myte-approval-allowance-and-overtime.module').then(m => m.MyteApprovalAllowanceAndOvertimeModule)
  },
  {
    path: 'approvalallowanceandovertime',
    canActivate: [authGuard],
    loadChildren: () => import('./myte-approval-allowance/myte-approval-allowance.module').then(m => m.MyteApprovalAllowanceModule)
  },
  {
    path: 'approvalovertimeandallowance',
    canActivate: [authGuard],
    loadChildren: () => import('./myte-time-category-request-approval/myte-time-category-request-approval.module').then(m => m.MyTETimeCategoryRequestApprovalModule)
  },
  {
    path: 'timecategoryrequestapproval',
    canActivate: [authGuard],
    loadChildren: () => import('./myte-time-category-request-approval/myte-time-category-request-approval.module').then(m => m.MyTETimeCategoryRequestApprovalModule)
  },
  {
    path: 'amextool',
    canActivate: [authGuard],
    loadChildren: () => import('./amex-notification-tool/amex-notification-tool.module').then(m => m.AmexNotificationToolModule)
  },
  {
    path: 'postpayaudit',
    canActivate: [authGuard],
    loadChildren: () => import('./myte-audit/myte-audit.module').then(m => m.MyteAuditModule)
  },
  {
    path: 'authorization',
    canActivate: [authGuard],
    loadChildren: () => import('./myte-authorize/myte-authorize.module').then(m => m.MyteAuthorizeModule)
  },
  {
    path: 'massadjustment',
    canActivate: [authGuard],
    loadChildren: () => import('./myte-mass-ppa/myte-mass-ppa.module').then(m => m.MyteMassPpaModule)
  },
  {
    path: 'reports',
    canActivate: [authGuard],
    loadChildren: () => import('./myte-reports/myte-reports.module').then(m => m.MyteReportsModule)
  },
  {
    path: 'supportadmin',
    canActivate: [authGuard,SupportAdminGuard],
    title: 'Support Admin',
    loadComponent: () => import('./myte-support-admin/myte-support-admin.component').then(m => m.MyteSupportAdminComponent)
  },
  {
    path: 'expensemappingadmin',
    canActivate: [authGuard, ExpenseMappingAdminGuard],
    title: 'Expense Mapping Admin',
    loadComponent: () => import('./myte-expense-mapping-admin/myte-expense-mapping-admin.component').then(m => m.MyteExpenseMappingAdminComponent)
  },
  {
    path: 'terms-of-use',
    canActivate: [authGuard],
    component: TermsOfUseComponent,
    data: { title: 'Terms of Use' }
  },
  {
    path: 'logout',
    component: PageLogoutComponent
  },
  {
    path: 'JapanReviewerConsent',
    canActivate: [authGuard],
    loadChildren: () => import('./myte-consent-reviewer/myte-consent-reviewer.module').then(m => m.MyteConsentReviewerModule)
  },
  {
    path: 'not-authorized',
    component: PageNotAuthorizedComponent,
    data: { title: 'Page Not Authorized' }
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent,
    canActivate: [authGuard],
    data: { title: 'Page Not Found' }
  },
  {
    path: '**',
    redirectTo: 'time'
  }
];

export const AppRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, {
    onSameUrlNavigation: 'reload',
    canceledNavigationResolution: 'computed',
    useHash: true,
    enableTracing: false,
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'
})
