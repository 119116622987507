import { Injectable } from '@angular/core';
import AuditInquiry from '@shared/models/expense/audit/expense-audit-inquiry';
import TimeReport, { TimeReportStatusModel } from '@shared/models/time-report/time-report';
import TimeReportSettings from '@shared/models/time-report/time-report-settings';
import TimeReportStore from '@shared/models/time-report/time-report-store.model';
import { TimePeriodPipe } from '@shared/pipes/time-period.pipe';
import { GlobalCacheService } from '@shared/services/cache/global-cache.service';
import { TimeReportService } from '@shared/services/time-report/time-report.service';
import { BehaviorSubject, Observable, map, of, switchMap, tap } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { ExpenseAuditService } from '../../../myte-expenses/shared/services/audit/expense-audit.service';
import { SubmitTimeReportOutput, ValidationErrorOutPut } from '@shared/clients/timereport-api-client';
import { UserProfileService } from '../user/user-profile.service';
import UserPreference from '@shared/models/userPreference';
import { TimeReportValidationErrors } from '@shared/models/time-report/time-report-validation-errors';
import { PDL_VALIDATION_COUNTRIES } from '@shared/constant';
import { LogService } from '../log/log.service';
import { TimesheetSummaryService } from '../../../myte-time/shared/services/timesheet/timesheet.summary.service';
import { LBDChangeReason } from '@shared/models/locations/locations-by-day/lbdchangereason';
import { PPAReason } from '@shared/models/adjustments/ppareason';


@Injectable({
    providedIn: 'root'
})
export class TimeReportStoreService {
    private timeReport: BehaviorSubject<TimeReport> = new BehaviorSubject(new TimeReport(new Date(), 'New', new TimeReportSettings()));
    private auditInquiry: BehaviorSubject<AuditInquiry[]> = new BehaviorSubject(new Array<AuditInquiry>());
    private isLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private periodEnd: BehaviorSubject<Date> = new BehaviorSubject(new Date((new TimePeriodPipe).transform(new Date())));
    private userPreference: BehaviorSubject<UserPreference> = new BehaviorSubject(new UserPreference());

    public readonly timeReport$: Observable<TimeReport> = this.timeReport.asObservable();
    public readonly auditInquiry$: Observable<AuditInquiry[]> = this.auditInquiry.asObservable();
    public readonly isLoading$: Observable<boolean> = this.isLoading.asObservable();
    public readonly periodEnd$: Observable<Date> = this.periodEnd.asObservable();
    public readonly userPreference$: Observable<UserPreference> = this.userPreference.asObservable();

    constructor(public timeReportService: TimeReportService,
                public globalCacheService: GlobalCacheService,
                private auditService: ExpenseAuditService,
                private userProfileService: UserProfileService,
                private logService: LogService,
                private timeSheetSummaryService: TimesheetSummaryService) { }


    public loadTimeReport(periodEnd: Date, shouldInitialize?: boolean) {
        this.isLoading.next(true);
        if (shouldInitialize) {
            this.globalCacheService.clearTimeReportSettingsBuffer();
            this.globalCacheService.handleResetTimeReport(periodEnd);
        }
        /* return forkJoin([
            this.timeReportService.getTimeReportSettingsStore(periodEnd, this.globalCacheService.getUserCountry()),
            this.auditService.getIssuesWithAdjustments()
        ]) */
        return this.timeReportService.getTimeReportSettingsStore(periodEnd, this.globalCacheService.getUserCountry())
                // .pipe(tap(([timeReportSettings, auditInquiries]: [TimeReportSettings, AuditInquiry[]]) => {
                .pipe(tap(timeReportSettings => {
                    let timeReportTEMP = new TimeReport(periodEnd, timeReportSettings.timeReportStatus, timeReportSettings);
                    timeReportTEMP.availableAction = this.mapAvailableAction(timeReportTEMP.status, this.globalCacheService.getSubordinateMode());
                    this.timeReport.next(timeReportTEMP);
                    this.updateCacheTimeReport();
                    this.periodEnd.next(periodEnd);
                    this.isLoading.next(false);
                }));
    }

    public loadUserPreferences(): Observable<UserPreference> {
        return this.userProfileService.getProfileByPeriodEnd(this.timeReport.getValue().periodEnd)
                .pipe(tap(userPreferences => this.userPreference.next(userPreferences)));
    }

    public getCurrentTimeReportStatus(): string {
        return this.timeReport.getValue().status;
    }

    public loadAuditInquiry() {
        return this.auditService.getIssuesWithAdjustments()
                .pipe(tap(auditInquiries => {
                    this.auditInquiry.next(auditInquiries);
                }));
    }

    public submitTimeReport(ppaReason: string): Observable<TimeReportSettings> {
        const periodEnd = this.timeReport.getValue().periodEnd;
        return this.timeReportService.submitTimeReport(periodEnd, ppaReason)
                .pipe(switchMap(submitReponse => {
                    // this.updateTimeReportStatus(submitReponse.timeReportStatus);
                    return this.loadTimeReport(periodEnd, true)
                    //return of(submitReponse);
                }));
    }

    public sendForApproval(): Observable<[TimeReportSettings, Date]> {
        const periodEnd = this.timeReport.getValue().periodEnd;
        return this.timeReportService.sendForApproval(periodEnd)
                .pipe(switchMap(submitReponse => {
                    return forkJoin([
                                this.loadTimeReport(periodEnd, true),
                                of(periodEnd)
                            ]);
                }));
    }

    public updateTimeReportStatus(state: string, periodEnd?: Date): void {
        if (periodEnd && periodEnd != this.timeReport.getValue().periodEnd) return this.handlePriorTimeReport(periodEnd, state)
        this.isLoading.next(true);
        let timeReportTemp = this.timeReport.getValue();
        timeReportTemp.status = state;
        timeReportTemp.availableAction = this.mapAvailableAction(state, this.globalCacheService.getSubordinateMode());
        timeReportTemp.timeReportSettings.timeReportStatus = state;
        timeReportTemp.timeReportSettings.timeReport = timeReportTemp;
        this.timeReport.next(timeReportTemp);
        this.updateCacheTimeReport();
        this.isLoading.next(false);
    }

    public handlePriorTimeReport(periodEnd: Date, state: string) {
        const countryKey = this.globalCacheService.getUserCountry();
        this.globalCacheService.getTimeReportSettings(periodEnd, countryKey)
        .subscribe(timeReportSettings => {
              let timeReportTEMP = new TimeReport(periodEnd, timeReportSettings.timeReportStatus, timeReportSettings);
            timeReportSettings.timeReportStatus = state;
            timeReportTEMP.status = state;
            timeReportTEMP.availableAction = this.mapAvailableAction(timeReportTEMP.status, this.globalCacheService.getSubordinateMode());
            this.globalCacheService.handleTimeReport(periodEnd, of(timeReportTEMP));
            this.globalCacheService.handleTimeReportSettings(periodEnd, countryKey, of(timeReportSettings));
        })
    }

    public updateTimeReportToDraft(periodEnd: Date): Observable<Date[]> {
        this.isLoading.next(true);
        return this.timeReportService.updateSubmittedToDraft(periodEnd)
                .pipe(switchMap(updateResponse => updateResponse == true ? this.loadTimeReport(periodEnd, true) : of(updateResponse)))
                .pipe(switchMap(updateResponse => {
                        this.isLoading.next(false);
                        return !updateResponse ?  of(new Array<Date>()) :
                                                this.timeReportService.findSpecificPriorPeriods(periodEnd, [TimeReportStatusModel.SubmitPendingAdjustment]);
                    })
                );
        
    }

    public removeCurrentPPA(viewMode: any): Observable<TimeReportSettings> {
        return this.timeReportService.removeCurrentPendingPpa(this.timeReport.getValue().periodEnd, viewMode)
                .pipe(switchMap(removePPAresponse => {
                    return this.loadTimeReport(this.timeReport.getValue().periodEnd, true)
                }));
    }

    private updateCacheTimeReport(): void {
        this.globalCacheService.handleResetTimeReport(this.timeReport.getValue().periodEnd);
        this.globalCacheService.handleTimeReport(this.timeReport.getValue().periodEnd, of(this.timeReport.getValue()));
        this.globalCacheService.handleTimeReportSettings(this.timeReport.getValue().periodEnd, this.globalCacheService.getUserCountry(), of(this.timeReport.getValue().timeReportSettings));
    }

    public mapAvailableAction(status: string, viewMode: string): string {
        switch (status) {
            case 'Submitted':
                return 'Update Time Report';
            case 'SubmittedPendingApproval':
                return viewMode == 'Approver' ? 'Submit' : 'Update Time Report';
            case 'Processed':
                return null;
            default:
                return 'Submit';
        }
    }

    
    public validateTimeReport(periodEnd: Date, IsSendForApproval: boolean, isTimeTab: boolean = false, isSubmitOnActivity: boolean): Observable<TimeReportValidationErrors> {
        const userCountryKey = this.globalCacheService.getUserCountry();
        let submitValidationResponse = new TimeReportValidationErrors();
        submitValidationResponse.totalHoursValidationErrorOutPut = [];
        submitValidationResponse.locationByDayReason = new LBDChangeReason();
        submitValidationResponse.priorPeriodAdjustmentReason = new PPAReason();
        return this.timeReportService.submitTimeReportNext(periodEnd, IsSendForApproval, isTimeTab)
                .pipe(map(data => {
                    submitValidationResponse.totalHoursValidationErrorOutPut = data.errors;
                    submitValidationResponse.validWarningMessage = data.errors;
                    submitValidationResponse.expenseHasFederalWBSInd = data.expenseHasFederalWBSInd;
                    submitValidationResponse.federalWBSList = data.federalWBSList;
                    this.timeSheetSummaryService.setSummaryCardData(data.summaryCards);
                    if (submitValidationResponse.validWarningMessage.length > 0) {
                        submitValidationResponse.validWarningMessage.forEach(validWarningMsg => {
                            if ((validWarningMsg.errorType == "SuspenseProject" && submitValidationResponse.warningMessage.every(warn => warn.errorType != "SuspenseProject")) ||
                                (!["SoftWarningAfterSubmission", "SoftWarningPeriodAfterSubmission", "SuspenseProject"].includes(validWarningMsg.errorType) &&
                                (!submitValidationResponse.warningMessage.some(warning => warning.errorMsg === validWarningMsg.errorMsg)))) {
                                    let inva = new ValidationErrorOutPut();
                                    inva.errorMsg = validWarningMsg.errorMsg;
                                    inva.errorType = validWarningMsg.errorType;
                                    submitValidationResponse.warningMessage.push(inva);
                            }
                            if (validWarningMsg.errorType == 'FederalExpenseNotInTrip' && !submitValidationResponse.federalErrorSources.some(data => data == validWarningMsg.errorSource)) {
                                submitValidationResponse.federalErrorSources.push(validWarningMsg.errorMsg);
                            }
                            if (["SoftWarningAfterSubmission", "SoftWarningPeriodAfterSubmission"].includes(validWarningMsg.errorType)) {
                                submitValidationResponse.messagesAfterSubmission.push(validWarningMsg.errorMsg);
                            }
                            if (validWarningMsg.errorType.includes('LocationByDayPerDiemLocalTimeReportLevel') && PDL_VALIDATION_COUNTRIES.includes(userCountryKey)) {
                                this.globalCacheService.setPDL(true);
                                this.globalCacheService.resetWorkLocationList();
                            } else {
                                this.globalCacheService.setPDL(false);
                            }
                        });

                        if (isSubmitOnActivity) {
                            submitValidationResponse.activityErrors = data.activityErrors;
                        }

                        if (submitValidationResponse.messagesAfterSubmission.length > 0)
                            this.globalCacheService.setSWAfterSubmission(of(submitValidationResponse.messagesAfterSubmission), periodEnd, false);
                        else
                            this.globalCacheService.clearSWAfterSubmissionFromBuffer(periodEnd);
                        if (submitValidationResponse.warningMessage.length > 0) {
                            submitValidationResponse.ispassed = false;
                            let messageNum = submitValidationResponse.warningMessage.length;
                            let timeout = 0;
                            submitValidationResponse.warningMessage.forEach(inValid => {
                                timeout = 5000 * messageNum;
                                messageNum--;
                                if (['InvalidPopularChargeCodeErrorMessage', 'InvalidMismatchForGdnErrorMessage', 'MaximumExpenseReportTotalExceeded',
                                     'InvalidChargeCodeErrorMessageForHardStop', 'USFedValidatePPAForCurrentPeriod'].includes(inValid.errorType)) {
                                        this.logService.logErrorWithMsgInfo({ msg: inValid.errorMsg, showToast: true, title: '', timeout: timeout });
                                }
                                else if(['AhgoraPunchClockEntryMissingToastMessage','PreviousAhgoraPunchClockEntryMissing'].includes(inValid.errorType)){
                                    this.logService.logWarningYellow(inValid.errorMsg,true);
                                }
                                else {
                                    if (inValid.errorType != 'NucAubpoValidation') {
                                        let messsageErrorEdit = "";
                                        if (inValid.errorMsg && inValid.errorMsg.includes('the corner triangle')) {
                                            if (inValid.errorMsg.includes('Click on the corner triangle')) {
                                                messsageErrorEdit = inValid.errorMsg.replace('Click on the corner triangle', 'Open Related charge codes popup');
                                            } else if (inValid.errorMsg.includes('Click the corner triangle')) {
                                                messsageErrorEdit = inValid.errorMsg.replace('Click the corner triangle', 'Open Related charge codes popup');
                                            }
                                        } else {
                                            messsageErrorEdit = inValid.errorMsg;
                                        }

                                        this.logService.logError(inValid.errorMsg, true, '', '', false, timeout);
                                        let toastContainer = document.getElementById("toast-container").children[0] as HTMLElement;
                                        toastContainer.innerHTML = "<div aria-hidden='true'>" + inValid.errorMsg + "</div> ";
                                        toastContainer.setAttribute("aria-label", messsageErrorEdit);
                                        toastContainer.setAttribute('role', 'alert');
                                    }
                                }
                            });
                            return submitValidationResponse;
                        }
                    }

                    if (data.lbdReason != null && data.lbdReason.shouldShowLBDReasonPopup) {
                        submitValidationResponse.locationByDayReason = data.lbdReason;
                        submitValidationResponse.ispassed = false;
                        return submitValidationResponse;
                    }

                    if (data.ppaReason != null && data.ppaReason.shouldShowPPAReasonPopup) {
                        submitValidationResponse.priorPeriodAdjustmentReason = data.ppaReason;
                        submitValidationResponse.ispassed = false;
                        return submitValidationResponse;
                    }
                    submitValidationResponse.ispassed = true;
                    return submitValidationResponse;
                }));
    }
}
