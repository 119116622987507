import { Injectable } from '@angular/core';
import { AppConfigService } from '@authServices/app-config.service';
import { UniversalHeader } from '@shared/constant';
import { Countries_For_Anz_Approval, CountryKey } from "@shared/utils/constants";
import { ApprovalOvertimeAndAllowanceConstantService } from '../../../myte-approval-overtime-and-allowance/constants/myte-approval-overtime-and-allowance-constant.service';
import { UserRole } from '@shared/models/user/user-roles';
import ActionMenu from '@shared/models/action-menu';
import { GlobalApproverService } from './../../../myte-global-approver/shared/services/global-approver.service';


@Injectable({
    providedIn: 'root'
})
export class PageGuardEvaluationService {

    private enableAudit: boolean;

    constructor(
        private appConfigService: AppConfigService,
        private constantService: ApprovalOvertimeAndAllowanceConstantService,
        private globalAproverService: GlobalApproverService
    ) {
        this.enableAudit = this.appConfigService?.getAppConfig?.AdditionalConfig?.enableAuditNext ? true : false;
    }

    shouldDenyNavigation(keyButton: string, userActionMenu: any) {
        if(this.isGlobalApproverMode(userActionMenu)){
            return !userActionMenu.isGlobalApprover;
        }
        switch (keyButton) {
            case UniversalHeader.GridButtons.REQUESTOVERTIME:
                return  !userActionMenu?.request && !userActionMenu?.isHolidayHoursRequestor
            case UniversalHeader.GridButtons.APPROVEOVERTIMEANDALLOWANCE:
                return !userActionMenu?.sdl && (!userActionMenu?.isAllowanceApproverForIN) && !userActionMenu?.isHolidayHoursApprover
            case UniversalHeader.GridButtons.APPROVALALLOWANCEANDOVERTIME:
                return !Countries_For_Anz_Approval.includes(userActionMenu?.userCountry) || (!userActionMenu?.isAllowanceApprover && !userActionMenu?.isOvertimeApprover)
            case UniversalHeader.GridButtons.APPROVALOVERTIMEANDALLOWANCE:
                return this.constantService.countryKeyForCJ != userActionMenu?.userCountry || !userActionMenu?.isOvertimeApprover     
            case UniversalHeader.ActionButtons.POSTPAYAUDIT:
                return userActionMenu?.isAuditPilotUser && !userActionMenu?.audit && !this.enableAudit
            case UniversalHeader.ActionButtons.AUTHORIZE:
                return !userActionMenu?.authorize
            case UniversalHeader.ActionButtons.PERDIEMS:
                return !userActionMenu?.admin
            case UniversalHeader.GridButtons.TIMECATEGORYREQUESTAPPROVAL:
                return (
                    Countries_For_Anz_Approval.includes(userActionMenu?.userCountry) ||
                    this.constantService.countryKeyForCJ === userActionMenu?.userCountry ||
                    CountryKey.IN === userActionMenu?.userCountry ||
                    !(
                        userActionMenu?.isOvertimeApprover ||
                        userActionMenu?.isAllowanceApprover ||
                        userActionMenu?.isWorkshiftApprover ||
                        userActionMenu?.isOnCallAndSWTimeApprover
                    )
                );
        }
    }

    private isUserAdminOrSupport(userActionMenu: any) {
        return userActionMenu?.userRole === UserRole.Support || userActionMenu?.userRole === UserRole.Administrator;
    }

    /**
     * check if the user is navigating to global approver mode
     * @param userActionMenu 
     * @returns 
     */
    isGlobalApproverMode(userActionMenu: ActionMenu): boolean{
        if(userActionMenu?.isGlobalApprover && location.href.includes(UniversalHeader.GridButtons.GLOBALAPPROVAL)){
            return true;
        }
        return false;
    }

    /**
     * clear country selected for global approver from the service
     * @param userActionMenu 
     */
    clearGlobalApproverSelectedCountry(userActionMenu: ActionMenu) {
        if (this.isGlobalApproverMode(userActionMenu)) {
            this.globalAproverService.selectedGlobalApproverCountry = null;
        }
    }
}