import { Placement, PlacementArray } from "@ng-bootstrap/ng-bootstrap/util/positioning";


export const languageMasks = {
  "ar": "DD/MM/YY",
  "ar-SA": "DD/MM/YY",
  "ar-IQ": "DD/MM/YY",
  "ar-EG": "DD/MM/YY",
  "ar-LY": "DD/MM/YY",
  "ar-DZ": "DD/MM/YY",
  "ar-MA": "DD/MM/YY",
  "ar-TN": "DD/MM/YY",
  "ar-OM": "DD/MM/YY",
  "ar-YE": "DD/MM/YY",
  "ar-SY": "DD/MM/YY",
  "ar-JO": "DD/MM/YY",
  "ar-LB": "DD/MM/YY",
  "ar-KW": "DD/MM/YY",
  "ar-AE": "DD/MM/YY",
  "ar-BH": "DD/MM/YY",
  "ar-QA": "DD/MM/YY",
  "bg": "D.M.YYYY",
  "bn": "DD-MM-YY",
  "bn-IN": "DD-MM-YY",
  "zh-TW": "YYYY/M/D",
  "zh-CN": "YYYY/M/D",
  "zh-HK": "YYYY/M/D",
  "zh-SG": "YYYY/M/D",
  "da": "DD-MM-YYYY",
  "nl": "D-M-YYYY",
  "nl-BE": "D-M-YYYY",
  "en": "M/D/YYYY",
  "en-US": "M/D/YYYY",
  "en-EG": "M/D/YYYY",
  "en-AU": "M/D/YYYY",
  "en-GB": "DD/MM/YYYY",
  "en-CA": "YYYY-MM-DD",
  "en-NZ": "D/MM/YYYY",
  "en-IE": "M/D/YYYY",
  "en-ZA": "YYYY/MM/DD",
  "en-JM": "M/D/YYYY",
  "en-BZ": "M/D/YYYY",
  "en-TT": "M/D/YYYY",
  "fr": "DD/MM/YYYY",
  "fr-BE": "DD/MM/YYYY",
  "fr-CA": "YYYY-MM-DD",
  "fr-CH": "DD/MM/YYYY",
  "fr-LU": "DD/MM/YYYY",
  "de": "DD.MM.YYYY",
  "de-DE": "DD.MM.YYYY",
  "de-CH": "DD.MM.YYYY",
  "de-AT": "DD.MM.YYYY",
  "de-LU": "DD.MM.YYYY",
  "de-LI": "DD.MM.YYYY",
  "he": "DD/MM/YYYY",
  "hi": "DD-MM-YYYY",
  "it": "DD/MM/YYYY",
  "it-IT": "DD/MM/YYYY",
  "it-CH": "DD/MM/YYYY",
  "ja": "YYYY/MM/DD",
  "lv": "DD.MM.YYYY",
  "pl": "DD.MM.YYYY",
  "pt-BR": "DD/MM/YYYY",
  "pt": "DD/MM/YYYY",
  "pt-PT": "DD/MM/YYYY",
  "sk": "D.M.YYYY",
  "sl": "D.MM.YYYY",
  "es": "DD/MM/YYYY",
  "es-AR": "DD/MM/YYYY",
  "es-GT": "DD/MM/YYYY",
  "es-CR": "DD/MM/YYYY",
  "es-PA": "DD/MM/YYYY",
  "es-DO": "DD/MM/YYYY",
  "es-MX": "DD/MM/YYYY",
  "es-VE": "D/M/YYYY",
  "es-CO": "DD/MM/YYYY",
  "es-PE": "DD/MM/YYYY",
  "es-EC": "DD/MM/YYYY",
  "es-CL": "DD/MM/YYYY",
  "es-UY": "DD/MM/YYYY",
  "es-PY": "DD/MM/YYYY",
  "es-BO": "DD/MM/YYYY",
  "es-SV": "DD/MM/YYYY",
  "es-HN": "DD/MM/YYYY",
  "es-NI": "DD/MM/YYYY",
  "es-PR": "DD/MM/YYYY",
  "th": "D/M/YYYY",
};

export const formatExceptions = [
    "ar",
    "ar-SA",
    "ar-IQ",
    "ar-EG",
    "ar-LY",
    "ar-DZ",
    "ar-MA",
    "ar-TN",
    "ar-OM",
    "ar-YE",
    "ar-SY",
    "ar-JO",
    "ar-LB",
    "ar-KW",
    "ar-AE",
    "ar-BH",
    "ar-QA",
    "th"
];


export const daysNamesLanguagesExceptions = ['de', 'de-DE', 'de-CH', 'de-AT', 'de-LU', 'de-LI', 'da', 'nl'];
export const gridAmexLanguagesExceptions = ['zh-CN', 'zh-SG', 'zh-TW', 'zh'];
export const browserDateFormatExceptions = { slashExceptions: ['bn','ar','th'], dashExceptions: ['bn-In', 'mr'] };
export const shortDates = {
     'Sun': 'Sunday',
     'Mon': 'Monday',
     'Tue': 'Tuesday',
     'Wed': 'Wednesday',
     'Thur': 'Thursday',
     'Thu': 'Thursday',
     'Fri': 'Friday',
     'Sat': 'Saturday'
}

export const errorMonthMessage = "Invalid month. Can not send month number greater than 11 or less than 0 to a Date instance";

export const dateFilterExceptions = ["ar", "ar-SA", "ar-IQ","ar-EG","ar-LY","ar-DZ","ar-MA",
                                     "ar-TN","ar-OM", "ar-YE","ar-SY","ar-JO","ar-LB", "ar-KW",
                                     "ar-AE","ar-BH","ar-QA","th","bn","bn-IN","zh-TW","zh-CN",
                                     "zh-HK","zh-SG"];

/**
 * Enum for each case values.
 * @readonly
 * @enum {string}
 */
export enum FormatDateType {
  /** Returns complete date for show in each day of datepicker */
  GetDateForTooltip = 'GetDateForTooltip',
  /** Returns specific format by language */
  FormatDate = 'FormatDate',
  /** Returns specific mask (lowercase) by language to show in browser */
  GetDateMaskByLanguage = 'GetDateMaskByLanguage',
  /** Returns specific month in 'full' format by language */
  GetFullMonthByLanguage = 'GetFullMonthByLanguage',
  /** Returns date whith time concatenated  */
  FormatDateWithTime = 'FormatDateWithTime',
  /** Returns date whith time (only hours and minutes, not seconds) concatenated  */
  FormatDateWithTimeHoursMinutes = 'FormatDateWithTimeHoursMinutes',
  /** Returns formated date and full name of day concatenated */
  ExpenseGridService = 'ExpenseGridService',
  /** Returns standard formated date */
  GetStandardDateForFilter = 'GetStandardDateForFilter',
  /** Returns first day of week */
  FirstDayOfWeekByLanguage = 'FirstDayOfWeekByLanguage',
  /** Returns specific mask (uppercase) by language to validate travel taxi expense */
  GetBrowserDateFormat = 'GetBrowserDateFormat',
  /** Returns short day name by language */
  GetShortDayNameByLanguage = 'GetShortDayNameByLanguage',
  /** Returns number of day by date that recives */
  GetDateNumber = 'GetDateNumber',
  /** Returns full name of day by short name of day */
  GetFullDay = 'GetFullDay',
  /** Returns Date datatype */
  GetStringToDate = 'GetStringToDate',
  /** Returns short format of weekday name and day */
  GetDateForMultipleLocations = 'GetDateForMultipleLocations',
  /** Returns short format of month and day */
  GetDateForLocationQuestionnaire = 'GetDateForLocationQuestionnaire',
  /** Returns Boolean indicating if it's business day (from Monday to Friday) */
  isBusinessDay = 'isBusinessDay',
  /** Returns Boolean indicating if the date is in the first or second fortnight of the month */
  isFirstFortnight = 'isFirstFortnight',
  /** format a Date with format 2023-05-31T00:00:00 to string mm-dd-yyyy */
  FormatDateForAPI = 'Format a date for API call',
   /**returns date with twenty four hours time (hours, minutes and seconds) concatenated*/
  FormatDatewithTwentyFourHoursTime = 'FormatDatewithTwentyFourHoursTime'
};

// enum for allowance type code dropdown

export enum allowanceTypeCd {
    default,
    SelectAll,
    Allowance,
    Overtime
}

export const allowanceRequest = {
  chargeCodeErrorMsg: 'Complete the Charge Code field to save the request',
  approverErrorMsg: 'Complete the Approver field to save the request',
  reviewerErrorMsg: 'Complete the Reviewer field to save the request',
  declaredHolidayErrorMsg: 'Maximum 3 days can be selected as a holiday'
}

export enum CountryKey {
  GB = 'GB',
  IN = 'IN',
  TH = 'TH',
  MY = 'MY',
  AU = 'AU',
  NZ = 'NZ',
  PT = 'PT'
}

export const specialWorkingTime = "Special Working Time";
export const TimeRange_Validation_Message = {
  "TimeRangeError" : 'Time range defined by Start/End Times must fall between 8 PM and 6 AM.',
  "WorkingShiftTimeRangeError" : 'Time range defined by Start/End Times must fall between 6AM and 8PM.'
};
export const Overlapping_Validation_Message = {
  "OverlappingError" : 'Start and end times for Active and Passive On Call and Standby Duty cannot overlap. Please update the start and end times.'
};
export const TravelHours_ExcessWorkSchedule_Message = "Travel Hours in excess of Work Schedule in Time Types tab must be less or equal to/can't exceed Hours in the Time Types tab.";

export const INDIA_OVERTIME = {
  employeeId: 'Employee ID',
  enterpriseId: 'Enterprise ID',
  state: 'State',
  status: 'Status',
  requestor: 'Requestor',
  overtimeRequestor: 'Overtime Requestor',
  quarterlyOvertimeHours: 'Quarterly Overtime Hours',
  requestOverTimeContext: 'requestovertime',
  timeReportStatus : 'Time Report Status',
  trStatus : 'TR Status',
  trProcessedPeriod : 'TR Processed Period',
  timeReportPeriod : 'Time Report Period',
  myTEOTHours : 'myTE OT Hours',
  myTEHours : 'myTE Hours',
  requestedOTHours : 'Requested OT Hours',
  requestedHours : 'Requested Hours',
  approvalStatus : 'Approval Status',
  UserIdForAutoApprove : 'AutoApproved',
  columnHeaderTooltipText: {
    statusTooltip: "Pass indicates the request is successful. Fail indicates the request is unsuccessful, and you must correct it per the reason and resubmit the request",
    selfCertTooltip: "Select the expenses that were included in the Self-Certification form",
    escalateTooltip: "Select an expense to be sent to a team lead for escalation"
  },
  exportApproveOTValues: {
    exportSheetName: 'Export Data',
    pendingApproveStatus: 'Pending Approval',
    holdStatus: 'Hold',
    approvedStatus: 'Approved',
    NaA: 'N/A',
    NA: 'NA',
    OTDropdownOptions : ["Approved","Auto-Approved","Hold","Pending Approval"],
    autoApproveLabel : 'Auto-Approved:',
    approvalStatusSelected : [0, 1, 2, 3],
    autoApproveStatus: 'Auto-Approved',
    UserIdForAutoApprove : 'AutoApproved',
  },
  exportColumns: {
    otdate: 'Overtime Date',
    variance: 'Variance',
    dailyOTLimit: 'Daily OT Limit',
    weeklyOTLimit: 'Weekly OT Limit',
    quarterlyOTLimit: 'Quarterly OT Limit',
    yearlyOTLimit: 'Yearly OT Limit',
    overtimeWeeklyBalance: 'Overtime Weekly Balance',
    overtimeQuarterlyBalance: 'Overtime Quarterly Balance',
    overtimeYearlyBalance: 'Overtime Yearly Balance',
    dailyOTBreach: 'Daily OT Breach',
    weeklyOTBreach: 'Weekly OT Breach',
    quarterlyOTBreach: 'Quarterly OT Breach',
   YearlyOTBreach: 'Yearly OT Breach'
  },
  requestOTStatus : 'Fail',
  invalidSdlToolTip : 'The SDL is invalid'

};

export const INDIA_HOLIDAY = {
  employeeId: 'Employee ID',
  holidayDate: 'Holiday Date',
  state: 'State',
  timeReportStatus : 'Time Report Status',
  timeReportPeriod : 'Time Report Period',
  myTEHours : 'myTE Hours',
  holidayHoursRequested: 'Holiday Hours Requested',
  workSchedule: 'Work Schedule',
  otHours: 'Overtime Hours',
  statusCd: 'Status',
  requestor: 'Requestor',
  exportSheetName:'Export Data',
  NaA: 'N/A',
  NA: 'NA',
  Approve: 'Approve',
  holidayHoursRequestedKey:'holidayHoursRequested',
  approverKey: 'approver',
  pass:"Pass"
}
export const HOLIDAY_VALIDATION_MSG = {
  peoplepicker: 'The number of Enterprise IDs in this field must not exceed 20.',
  approveStatusMsg: 'Holiday hours requests have been approved',
  holdStatusMsg: 'The holiday hours was placed on hold. Placing requests on hold will delay holiday hours benefit to employees',
  MultipleRequestMsg: 'Request cannot be processed because the hours and date(s) overlap a request from another supervisor.',
  approveWarningMsg:'You’re approving a request for both overtime and holiday hours',
  approvedStatus: 'Approved',
  autoApprovedStatus: 'Auto-Approved',
  tooltip: {
    'Approved':'The status of the holiday hours request (Approved) cannot be changed after the payroll cut-off date',
    'Auto-Approved':'The status of the holiday hours request (Auto Approved) cannot be changed after the payroll cut-off date',
  },
}

export const OvertimeStatus = [" ","Pending Approval","Hold","Approved"];

export const ProcessedTRStatus = "Processed";

export const RequesteeEnterpriseIdField = "requesteeEnterpriseId";

export const EnterpriseId = "enterpriseId";

export const TwentyFourHoursTimeFormat = "HH:mm:ss";

export const total_issues = "Total Issues";

export const IssueStatus = "Open";

export const ActivityTypes = {
  Active: "active",
  Passive: "passive"
}

export const ActivityField_Options = {
  OnCallDutyPassive: "oncalldutypassive",
  OnCallDutyActive: "oncalldutyactive",
  StandByDutyActive: "standbydutyactive",
  StandByDutyPassive: "standbydutypassive",
  WorkingTimeShift: "workingtimeshift"
}

export const ActivityEntryFields =
{ Date: "Date",
  StartTime: "Start Time",
  EndTime: "End Time",
  Activity: "Activity",
  Hours: "Hours"
};

export const Activity_ErrorTypes = {
  OverlappingErrorType: "OverlappingError",
  TimeRangeErrorType: "timeRangeError"
}

export const Activity_SaveCommonErrorMessage = "The ACTIVITY is not ready to save.";

export const ButtonConstants= {
  EDIT_BTN: "Edit",
  SEND_ISSUE_BTN: "Send Issue",
  SEND_REMINDER_BTN: "Send Reminder",
  SEND_ESCALATION_BTN: "Send Escalation",
  SEND_REVERSE_BTN: "Send Reversal",
  CLOSE_MULTIPLE_ISSUES:"Close Multiple Issues"
} 

export const TooltipMessages = {
  auditorComments: "Enter any internal notes here for yourself or other auditors. Employees will not see what is entered here.",
  SEND_ISSUE_TOOLTIP: "Send an email to the employee summarizing all new issues",
  SEND_REMINDER_TOOLTIP: "Send a reminder email to the employee asking them to close out any open issues",
  SEND_ESCALATION_TOOLTIP: "Send a reminder email to the employee asking them to escalate close out any open issues",
  SEND_REVERSE_TOOLTIP: "This will send a reversal notification to the employee",
  CLOSE_ISSUES_TOOLTIP:"To close more than one issue in Open status at the same time, check the box next to the issues to be closed and click the Close Multiple Issues button"
} 

export const CloseMultipleIssues = {
  Close_Multiple_Issues:"Close Multiple Issues",
  Closure_Comments:"Closure Comments for Employee: (These comments will apply to all issues closed)",
  Close_Issues: "Close Issues",
  Cancel:"Cancel",
  Success:"Success",
  Failure:"Failure"
}

export const CountriesWithOldTimeCategoryRequestApproach: string[] = ["AU", "NZ", "PT", "IN"];
export const AllowanceRequestRTCForIN: string[] = ["R102", "R103", "R104", "R105", "R106"];
export const ShiftAllowanceRTCForIN: string[] = ["R102", "R103"];
export const OnCallAllowanceRTCForIN: string[] = ["R104", "R105", "R106"];
export const OnCallTooltipErrorMessage = 'Identify a charge code, reviewer and approver for ${name} allowance.'
export const CopyButtonlabel: string = "Copy to All";
export const EscalationInProgress = "EscalationInProgress";
export const RejectedByAuditor = "RejectedByAuditor";
export const AuditClosed = "AuditClosed";
export const Click = 'click';
export const PlacementPreferences: PlacementArray = ['bottom-left', 'bottom-right', 'top-left', 'top-right'];
export const PlacementTopLeft: Placement = 'top-left';
export const Reviewer = 'reviewer';
export const Items = 'items';
export const Keydown = 'keydown';
export const ArrowDown = 'ArrowDown';
export const ArrowUp = 'ArrowUp';
export const Escape = 'Escape';
export const ReviewerInput = 'reviewerInput';
export const People_Picker = '#people-picker';
export const Event = '$event';
export const PeopleSearch = 'People search';
export const ReviewerPeoplePickerInfo = [' readonly', ', select an reviewer for', ', press enter to search an reviewer for', ' selected for'];

export const toolTipMsg = 'Please enter valid From/To dates';
export const Tooltip = 'toolTip';

export const atciAllowanceTypeCd = {
  "Select All": 0,
  "Shift Allowance - Shift Type B": 1,
  "Shift Allowance - Shift Type C": 2,
  "On Call - Primary Support": 3,
  "On Call - Secondary Support": 4,
  "On Call - Support By Unassigned": 5
}

export enum PeoplePickerButtonValue {
  Hide = 'Hide All Employee(s)',
  Show = 'See All Employee(s)',
}

export const CountriesWithSubHeader:string[] = ["CH", "DE"];
export const SubHeaderForCHandDE = "Overtime taken:"
export const KEY_ENTER = 'Enter'

/*constants used for massppa module*/

export const MassPPATooltips = {
  availableHours: "Hours in any Time Reports the employee is currently adjusting are not available for Mass Time Adjustment"
}

/*constants used for massppa module*/
export const TimeCategoryRequestFieldTypes = {
  Numeric: 0,
  Text: 1,
  Dropdown: 2,
  CheckBox: 3,
  PeoplePicker: 4,
  ChargeCodePicker: 5,
  HoursAndMinutesDropdown: 6,
  Datepicker: 7
}

export const OvertimeRequestPopupHeader = 'Overtime Request: Daily Overtime';
export const Countries_For_Anz_Approval = ['NZ','AU','PT'];
export const Countries_For_ASG_Approval = ['AT','DE','CH'];
export const Countries_For_PunchClockMax10Rows = ['DE','AU','AT'];
export const WorkShiftApproverTitle = 'Work Shift Approver:';
export const WorkShiftApproverModel = 'WorkShiftApprover';
export const SelectedApproverTitle = 'Selected Approver:';
export const ThailandCountryKey = 'TH';
export const MalaysiaCountryKey = 'MY';
export const InvalidShiftApproverErrorMsg = 'You have entered an invalid Shift Approver.Your Shift Approver must be an active employee at level 7 and above.For further assistance, please contact your supervisor.';
export const MoreThanOneShiftApproverErrorMsg = 'You have entered more than one Shift Approver. Please select only one Shift Approver.';
export const workshiftPopupTitle = "Work Shift";
export const workshiftCode = "R88";
export const ExcludedCompanyCode_DE = "3265";
export const OnCallAndSWTimeApproverTitle = 'On Call & Special Working Time Approver:';
export const OnCallAndSWTimeApproverModel = 'OnCallAndSWTimeApprover';
export const InvalidOnCallAndSWTimeApproverErrorMsg = 'You have entered an invalid On Call & Special Working Time Approver. Your On Call & Special Working Time Approver must be an active employee at level 9 and above. For further assistance, please contact your supervisor.';
export const MoreThanOneOnCallAndSWTimeApproverErrorMsg = 'You have entered more than one On Call & Special Working Time Approver. Please select only one On Call & Special Working Time Approver.';
export const Datekey = 'Date';
export const WorkShiftHoursValues = ['S1 - Morning (06:30 – 14:30)', 'S2 - Afternoon (14:00 – 22:00)', 'S3 - Special (10:00 – 19:00)'];
export const WorkShiftSameDayErrorMsg = 'The selected work shifts are not valid. Select work shift S1, S2, or S3 in the first row and from S4 onwards in the second row.';
export const WorkShiftOriginalRowErrorMsg = 'The selected work shift is not valid. Select work shift S1, S2, or S3';
export const WorkShiftKey = 'WorkShift';
export const HolidayPayoutCode = "O152";
export const KeyForHolidayBenefit = "HolidayBenefit";
export const DefaultValueForHolidayBenefit = "Please Select";
export const PintNotAvailable = 'Printing Not Available';
export const OnCallWorkedCode = 'R115';
export const timeCategoryRequestWidthValue = {
  thousand: 1000
}
export const popUpWidth = {
  'Holiday Payout': 720,
  'Compensatory Off': 600
}

export const Country = {
  INDIA : 'India'
}

export const CompensatoryOff={
  CompensatoryOffCode : "R168",
  KeyForHolidayBenefitCompOff : "HolidayBenefitCompOff",
  KeyForGenericCompOff: "GenericCompOff"
}

export enum requestedAllowanceStatusTypeEnum{
  _0 = 0,
  _1 = 1,
  _2 = 2,
  _3 = 3,
}

export const noDataTemplate = {
  noPendingRecordsText: "No Pending Records"
}