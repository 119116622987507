export default class ActionMenu {
    public record: boolean;
    public review: boolean;
    public approve: boolean;
    public report: boolean;
    public represent: boolean;
    public audit: boolean;
    public authorize: boolean;
    public massPPA: boolean;
    public admin: boolean;
    public userRole: string;
    public request: boolean;
    public sdl: boolean;
    public consentReviewer: boolean;
    public isAllowanceApprover: boolean;
    public isOvertimeApprover: boolean;
    public isAmexSender: boolean;
    public isAuditPilotUser :boolean;
    public isAuthorizePilotUser: boolean;
    public isMassPPAPilotUser: boolean;
    public isOnCallAndSWTimeApprover: boolean;
    public isAllowanceApproverForIN: boolean;
    public userCountry:string;
    public isReportPilotUser: boolean;
    public isHolidayHoursRequestor: boolean;
    public isHolidayHoursApprover: boolean;
    public isHolidayHoursEmployee: boolean;
    public isGlobalApprover: boolean;
}