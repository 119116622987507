export class TimeReportSummaryOvertime {
  public otBalance: string;
  public otBalanceRequestedHours: string;
  public timeOffInLieuHours: string;
  public otBalancePeriodEndDttm: Date;
  public otCashoutTooltip: string;
  public isInvalidOTBalance: boolean;
  public isOTBalanceInputEnabled: boolean;
  public otTimeOffInLieuTooltip: string;
  public otBalanceCashOutLimit: string;

  constructor(otBalance?: string, otBalanceRequestedHours?: string, timeOffInLieuHours?: string, otBalancePeriodEndDttm?: Date, otCashoutTooltip?: string, isInvalidOTBalance?: boolean,
    isOTBalanceInputEnabled?: boolean, otBalanceCashOutLimit?: string) {

    this.otBalance = otBalance;
    this.otBalanceRequestedHours = otBalanceRequestedHours;
    this.otBalancePeriodEndDttm = otBalancePeriodEndDttm;
    this.otCashoutTooltip = otCashoutTooltip;
    this.timeOffInLieuHours = timeOffInLieuHours;
    this.isInvalidOTBalance = isInvalidOTBalance ?? false;
    this.isOTBalanceInputEnabled = isOTBalanceInputEnabled ?? false;
    this.otTimeOffInLieuTooltip = this.otTimeOffInLieuTooltip;
    this.otBalanceCashOutLimit = otBalanceCashOutLimit;
  }
}